/* eslint-disable import/no-anonymous-default-export */
import { SET_ADMIN_VIEW } from "../actions/Admin/AdminActions";

const initialState = {
    adminView: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ADMIN_VIEW:
            if (action.payload) {
                localStorage.setItem("view", "admin");
            } else {
                localStorage.removeItem("view");
            }

            return {
                ...state,
                adminView: action.payload,
            };

        default:
            return state;
    }
}
