import React, { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { checkValidityOfFromToken, checkValidityOfToToken, getCardRange } from "../../actions/LoadCardActions";
import {
    ADD_ONE_CARD,
    CLEAR_CARD_ERRORS,
    FROM_TOKEN_TEXT_COLOR,
    RECEIVED_FROM_TOKEN_VALIDITY,
    RECEIVED_TO_TOKEN_VALIDITY,
    REMOVE_CARD_FROM_LIST,
    SET_CARD_AMOUNT,
    SET_TOTAL_AMOUNT,
    TO_TOKEN_TEXT_COLOR,
} from "../../actions/LoadCardActions";
import ButtonLoading from "../../assets/svg/ButtonLoading";
import CloseIcon from "../../assets/svg/CloseIcon";
import { isNumeric } from "../../utils/Helper";
import FailAlert from "../Alert/FailAlert";
import { GF_DARK_BLUE } from "../../utils/Constants";

export default function AddCard() {
    const [fromToken, setFromToken] = useState("");
    const [toToken, setToToken] = useState("");
    const [tokenAmount, setTokenAmount] = useState("");
    const [validation, setValidation] = useState("");

    const { cards, fromTokenValidity, toTokenValidity, errorCardRange, loadingCardRange, isCardRangeDirty } =
        useSelector((state) => state.loadCard);
    const { selectedTag } = useSelector((state) => state.tag);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { fromTokenTextColor, toTokenTextColor } = useSelector((state) => state.loadCard);

    const fromTokenChangeHandler = (event) => {
        if (event.currentTarget.value !== "") {
            dispatch({ type: FROM_TOKEN_TEXT_COLOR, payload: "text-gfDarkBlue" });
            dispatch({ type: CLEAR_CARD_ERRORS });
            let newT = [...fromToken];
            newT = event.currentTarget.value;
            setFromToken(newT);
            if (isNumeric(event.currentTarget.value) && event.currentTarget.value.length === 9) {
                setFromToken(event.currentTarget.value);
                dispatch(checkValidityOfFromToken(event.currentTarget.value));
            }
        } else {
            setFromToken("");
            setValidation("");
            dispatch({ type: RECEIVED_FROM_TOKEN_VALIDITY, payload: false });
        }
    };

    const toTokenChangeHandler = (event) => {
        if (event.currentTarget.value !== "") {
            dispatch({ type: TO_TOKEN_TEXT_COLOR, payload: "text-gfDarkBlue" });
            dispatch({ type: CLEAR_CARD_ERRORS });
            let newT = [...toToken];
            newT = event.currentTarget.value;
            setToToken(newT);
            if (isNumeric(event.currentTarget.value) && event.currentTarget.value.length === 9) {
                setToToken(event.currentTarget.value);
                dispatch(checkValidityOfToToken(event.currentTarget.value));
            }
        } else {
            setToToken("");
            dispatch({ type: RECEIVED_TO_TOKEN_VALIDITY, payload: false });
        }
    };

    const amountChangeHandler = (event) => {
        setTokenAmount(event.target.value);
    };

    const checkTokenExist = (token) => {
        var length = cards.length;
        for (var i = 0; i < length; i++) {
            if (cards[i].token === token) return true;
        }
        return false;
    };

    const addCardToListHandler = () => {
        if (tokenAmount === "") {
            setValidation(t("Amount-cannot-be-empty"));
        } else if (tokenAmount < selectedTag.min_load || tokenAmount > selectedTag.max_load) {
            setValidation(
                t("Cards-are-loadable-between-Please-select-another-amount-and-try-again", {
                    min: selectedTag.min_load,
                    max: selectedTag.max_load,
                    currency: selectedTag.currency,
                })
            );
        } else if (checkTokenExist(fromToken)) {
            setValidation(t("Token-already-exist"));
        } else {
            setValidation("");

            if (fromTokenValidity && tokenAmount !== "" && toToken === "") {
                let card = { token: fromToken, valid: fromTokenValidity, amount: parseFloat(tokenAmount).toFixed(2) };
                dispatch({ type: ADD_ONE_CARD, payload: card });
                dispatch({ type: SET_TOTAL_AMOUNT });
                setTokenAmount("");
                setFromToken("");
            }

            if (fromTokenValidity && toTokenValidity && toToken !== "") {
                dispatch({ type: SET_CARD_AMOUNT, payload: tokenAmount });
                dispatch(getCardRange(fromToken, toToken));
                setTokenAmount("");
                setFromToken("");
                setToToken("");
            }
        }
    };

    const removeTokenFromList = (event) => {
        dispatch({ type: REMOVE_CARD_FROM_LIST, payload: event.currentTarget.id });
        dispatch({ type: SET_TOTAL_AMOUNT });
    };

    const invalidChars = ["-", "+", "e"];

    const preventNonNumeric = (event) => {
        if (event.which === 38 || event.which === 40) {
            event.preventDefault();
        }
        if (invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    };
    
    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                <input
                    autoFocus
                    type="text"
                    maxLength="9"
                    className={`
                    text-center
                    border
                    focus:outline-none
                    focus:border-gfPeriwinkle
                    h-10
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 
                    ${fromTokenTextColor} `}
                    value={fromToken}
                    onChange={fromTokenChangeHandler}
                    placeholder={t("TOKEN")}
                />
                <input
                    maxLength="9"
                    type="text"
                    className={`
                    text-center
                    border
                    focus:outline-none
                    focus:border-gfPeriwinkle
                    h-10
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 
                    ${toTokenTextColor} `}
                    value={toToken}
                    onChange={toTokenChangeHandler}
                    placeholder={t("Token-to")}
                />
                <input
                    type="number"
                    min="0"
                    max="9999"
                    className="border
                    text-center
                    focus:outline-none
                    h-10
                    focus:border-gfPeriwinkle
                    text-gfDarkBlue
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 "
                    placeholder={t("amount-currency", { currency: selectedTag.currency })}
                    value={tokenAmount}
                    onChange={amountChangeHandler}
                    onKeyDown={preventNonNumeric}
                    onWheel={(e) => e.target.blur()}
                    
                />
                <button
                    disabled={!fromTokenValidity && tokenAmount === ""}
                    type="button"
                    onClick={addCardToListHandler}
                    className={`bg-gfPeriwinkle font-MulishBold ${
                        !fromTokenValidity && tokenAmount === "" ? "bg-opacity-50" : "bg-opacity-100"
                    } text-gfDarkBlue rounded py-2 px-3 flex justify-center items-center focus:outline-none`}
                >
                    {loadingCardRange && <ButtonLoading color={GF_DARK_BLUE} />}
                    {t("Add-a-card")}
                </button>
            </div>
            {validation !== "" && (
                <div className="text-sm text-gfCoral flex justify-items-center py-2">{validation}</div>
            )}
            {cards && cards.length !== 0 && (
                <div className="mt-4">
                    <div className="flex flex-row w-full lg:w-2/4 xl:w-2/4 2xl:w-2/6 justify-between bg-gfPeriwinkle bg-opacity-75 font-MulishBlack rounded text-lg items-center md:px-4 py-1 px-1">
                        <div className="text-gfDarkBlue">
                            <span className="">{cards.length}</span>
                            {cards.length === 1 ? " Card" : " Cards"}
                        </div>
                        <div className=" flex flex-row text-lg text-gfDarkBlue space-x-4">
                            <span className="text-gfDarkBlue">Total :</span>
                            <div className="flex justify-start text-gfLightBlue font-semibold">
                                <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={cards.reduce((a, card) => (a += parseFloat(card.amount)), 0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                                <span className="ml-1">{`${selectedTag.currency}`}</span>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-1 mt-4 ">
                        {cards.map((card, index) => (
                            <div
                                key={index}
                                className="flex flex-row w-full lg:w-2/4 xl:w-2/4 2xl:w-2/6 justify-between bg-gfPeriwinkle bg-opacity-75 rounded text-lg items-center md:px-4 py-1 px-1"
                            >
                                <div
                                    className={`${
                                        !card.valid && isCardRangeDirty ? "text-gfCoral" : "text-gfDarkBlue"
                                    }`}
                                >
                                    {card.token}
                                </div>
                                {!card.valid && (
                                    <div
                                        className={`${
                                            !card.valid && isCardRangeDirty ? "text-gfCoral" : "text-gfDarkBlue"
                                        }`}
                                    >
                                        {t("Card-is-not-loadable")}
                                    </div>
                                )}
                                <div className="flex flex-row">
                                    <div className="flex justify-start text-gfLightBlue mx-4">
                                        <CurrencyFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={card.amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                        <span className="ml-1">{`${selectedTag.currency}`}</span>
                                    </div>
                                    <button id={card.token} type="button" onClick={removeTokenFromList}>
                                        <CloseIcon w={15} h={16} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {errorCardRange && <FailAlert message={errorCardRange.message} />}
        </div>
    );
}
