import { issuingAPI } from "../services/Axios";
import { exportCSVFile } from "../utils/Helper";
import { ERROR_CSV_REPORT, LOADING_CSV_REPORT, SUCCESS_CSV_REPORT } from "./CommonActions";

export const PREFIX = "@reportByChannel/";

export const LOADING_CHANNEL_LIST = `${PREFIX}LOADING_CHANNEL_LIST`;
export const RECEIVED_CHANNEL_LIST = `${PREFIX}RECEIVED_CHANNEL_LIST`;
export const ERROR_CHANNEL_LIST = `${PREFIX}ERROR_CHANNEL_LIST`;

export const LOADING_REPORT_BY_CHANNEL = `${PREFIX}LOADING_REPORT_BY_CHANNEL`;
export const RECEIVED_REPORT_BY_CHANNEL = `${PREFIX}RECEIVED_REPORT_BY_CHANNEL`;
export const ERROR_REPORT_BY_CHANNEL = `${PREFIX}ERROR_REPORT_BY_CHANNEL`;
export const POPULATED_LIST = `${PREFIX}POPULATED_LIST`;
export const RESET_REPORT_BY_CHANNELS = `${PREFIX}RESET_REPORT_BY_CHANNELS`;

export const LOADING_USER_LIST = `${PREFIX}LOADING_USER_LIST`;
export const RECEIVED_USER_LIST = `${PREFIX}RECEIVED_USER_LIST`;
export const ERROR_USER_LIST = `${PREFIX}ERROR_USER_LIST`;

export const getChannelList = () => (dispatch, getState) => {
    dispatch({ type: LOADING_CHANNEL_LIST });

    const { tagCardBrands } = getState().tag;

    return issuingAPI
        .get(`card-brand/${tagCardBrands[0]}/shop`)
        .then((success) => {
            dispatch({ type: RECEIVED_CHANNEL_LIST, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: ERROR_CHANNEL_LIST, payload: error });
            return error;
        });
};

export const getUserList = () => (dispatch, getState) => {
    dispatch({ type: LOADING_USER_LIST });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`tag/${tagID}/user`)
        .then((success) => {
            dispatch({ type: RECEIVED_USER_LIST, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER_LIST, payload: error });
            return error;
        });
};

export const getReportByChannels = (fromDate, toDate, selectedChannelID, userIDs) => (dispatch, getState) => {
    dispatch({ type: LOADING_REPORT_BY_CHANNEL });

    const { tagID } = getState().tag;

    const params = {
        from_date: fromDate,
        to_date: toDate,
    };

    if (selectedChannelID !== -1) {
        Object.assign(params, { channel_ids: [selectedChannelID] });
    }

    if (userIDs !== -1) {
        Object.assign(params, { user_ids: [userIDs] });
    }

    return issuingAPI
        .get(`tag/${tagID}/channel-loads`, { params })
        .then((success) => {
            dispatch({ type: RECEIVED_REPORT_BY_CHANNEL, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: ERROR_REPORT_BY_CHANNEL, payload: error });
            return error;
        });
};

export const exportCSVReportByChannels =
    (fromDate, toDate, selectedChannelID, userIDs, reportName) => (dispatch, getState) => {
        dispatch({ type: LOADING_CSV_REPORT });

        const { tagID } = getState().tag;
        const params = {
            from_date: fromDate,
            to_date: toDate,
        };

        if (selectedChannelID !== -1) {
            Object.assign(params, { channel_ids: [selectedChannelID] });
        }

        if (userIDs !== -1) {
            Object.assign(params, { user_ids: [userIDs] });
        }

        const headers = {
            Accept: "text/csv",
        };

        const data = null;

        const fileName = `${reportName}-${fromDate}-${toDate}`;

        return issuingAPI
            .get(`tag/${tagID}/channel-loads/stream`, { headers, params, data })
            .then((success) => {
                dispatch({ type: SUCCESS_CSV_REPORT });
                exportCSVFile(success.data, fileName);
            })
            .catch((error) => {
                dispatch({ type: ERROR_CSV_REPORT, payload: error });
                return error;
            });
    };
