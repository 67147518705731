import React from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import { checkLoggedInUserPermissions, randomString } from "../../../../utils/Helper";

ReactModal.setAppElement("#root");

export default function ActiveOrder ({ isOpen, data, closeHandler }) {
    const { t } = useTranslation();

    const resetScroll = () => {
        document.body.style.overflow = "unset";
    };

    return (
        <ReactModal
            isOpen={ isOpen }
            contentLabel="PrepareOrder"
            className="bg-gray-700 z-50"
            style={ {
                overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(23, 35, 82, 0.6)",
                    zIndex: 9999,
                    backdropFilter: "blur(8px)",
                },
                content: {
                    position: "absolute",
                    top: "50px",
                    left: "100px",
                    right: "100px",
                    bottom: "100px",

                    backgroundColor: "rgba(23, 35, 82, 0.0)",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                },
            } }
        >
            <div className="flex flex-col  mx-auto py-3 px-10 border rounded max-w-lg shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
                <div className="flex justify-between items-center ">
                    <div className="font-MulishBold">
                        { t("Order-information").toUpperCase() + " - " + data.reference }
                    </div>
                    <button
                        className="focus:outline-none"
                        onClick={ () => {
                            closeHandler(!isOpen);
                        } }
                    >
                        <CloseIcon />
                    </button>
                </div>

                <div className="flex flex-col my-3">
                    <div className="py-2 mb-3">
                        <div className="flex justify-start font-MulishBlack mb-1">{ `${t("Order-Recap")}` }</div>
                        <div className="flex flex-row">
                            <span className="w-40 text-left font-MulishBold">{ `${t("Amount")} :` }</span>
                            <div className="flex justify-center">
                                <CurrencyFormat
                                    decimalScale={ 2 }
                                    fixedDecimalScale={ true }
                                    value={ data.total_amount }
                                    displayType={ "text" }
                                    thousandSeparator={ true }
                                />
                                <span className="ml-2">{ ` ${data.currency}` }</span>
                            </div>
                        </div>

                        <div className="flex flex-row">
                            <span className="w-40 text-left font-MulishBold">{ `${t("Fee")} :` }</span>
                            <div className="flex justify-center">
                                <CurrencyFormat
                                    decimalScale={ 2 }
                                    fixedDecimalScale={ true }
                                    value={ data.fee_amount }
                                    displayType={ "text" }
                                    thousandSeparator={ true }
                                />
                                <span className="ml-2">{ ` ${data.currency}` }</span>
                            </div>
                        </div>

                        <div className="flex flex-row">
                            <span className="w-40 text-left font-MulishBold">{ `${t("Status")} :` }</span>
                            <span className="">{ String(data.status).toUpperCase() }</span>
                        </div>
                    </div>
                    <div className="py-2 mb-3">
                        <div className="flex justify-start font-MulishBlack mb-1">{ `${t(
                            "Purchaser-Information"
                        )}` }</div>
                        <div className="flex flex-row">
                            <span className="w-40 text-left font-MulishBold">{ `${t("Name")} :` }</span>
                            <span>{ `${data.first_name} ${data.last_name}` }</span>
                        </div>
                        <div className="flex flex-row">
                            <span className="w-40 text-left font-MulishBold">{ `${t("Email-address")} :` }</span>
                            <span>{ data.email }</span>
                        </div>
                        { data && data.phone_number !== "" && (
                            <div className="flex flex-row">
                                <span className="w-40 text-left font-MulishBold">{ `${t("Phone-number")} :` }</span>
                                <span>{ data.phone_number }</span>
                            </div>
                        ) }
                    </div>

                    { data && data.address !== "" && (
                        <div className="py-2 mb-3">
                            <div className="flex justify-start font-MulishBlack mb-1">{ `${t(
                                "Delivery-Information"
                            )}` }</div>

                            <div className="flex flex-row items-start justify-start">
                                <span className="w-40 text-left font-MulishBold">{ `${t("Address")} :` }</span>
                                <div className="flex flex-col text-left">
                                    <span>{ `${data.recipient_name}` }</span>
                                    { data.address && <span>{ data.address }</span> }
                                    <div className="inline-block">
                                        { data.city && <span className="mr-1">{ data.city }</span> }
                                        { data.zipcode && <span className="mr-1">{ data.zipcode }</span> }
                                        { data.country && <span className="mr-1">{ `(${data.country})` }</span> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) }

                    <div className="py-2 mb-3">
                        <div className=" flex justify-start items-center w-full font-MulishBlack mb-3">{ `${t(
                            "Cards"
                        )}` }</div>

                        <div className="border rounded">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th className="text-xs font-normal w-1/2 py-1 px-2 text-left">{ `${t(
                                            "Token"
                                        )}` }</th>
                                        <th className="text-xs font-normal w-1/2 py-1 px-2 text-right">{ `${t(
                                            "Amount"
                                        )}` }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.customers &&
                                        data.customers.map((token) => (
                                            <tr key={ randomString() }>
                                                {/* <td className="font-MulishBold text-left px-2">{token.token}</td> */ }
                                                <div className="flex justify-start items-center font-MulishBold px-2">
                                                    { checkLoggedInUserPermissions("card-information") ? (
                                                        <Link onClick={ resetScroll }
                                                            className="underline"
                                                            to={ `/gift-cards/card-information?token=${token.token}` }
                                                        >
                                                            { token.token }
                                                        </Link>
                                                    ) : (
                                                        token.token
                                                    ) }
                                                </div>
                                                <td className="text-right  px-2">
                                                    <div className="flex justify-end font-MulishBold ">
                                                        <CurrencyFormat
                                                            decimalScale={ 2 }
                                                            fixedDecimalScale={ true }
                                                            value={ token.amount_loaded }
                                                            displayType={ "text" }
                                                            thousandSeparator={ true }
                                                        />
                                                        <span className="ml-2">{ ` ${data.currency}` }</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="py-4 space-y-5">
                        <button
                            onClick={ () => closeHandler(!isOpen) }
                            className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
                        >
                            { t("Ok").toUpperCase() }
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}
