import { issuingAPI } from "../services/Axios";
import { randomString } from "../utils/Helper";
import { getNotificationList } from "./NotificationActions";

const PREFIX = "@mvc-to-mvcTransfer/";

export const LOADING_FROM_MVC = `${PREFIX}LOADING_FROM_MVC`;
export const RECEIVED_FROM_MVC = `${PREFIX}RECEIVED_FROM_MVC`;
export const ERROR_FROM_MVC = `${PREFIX}ERROR_FROM_MVC`;

export const LOADING_TO_MVC = `${PREFIX}LOADING_TO_MVC`;
export const RECEIVED_TO_MVC = `${PREFIX}RECEIVED_TO_MVC`;
export const ERROR_TO_MVC = `${PREFIX}ERROR_TO_MVC`;

export const TRANSFERRING_MVC = `${PREFIX}TRANSFERRING_MVC`;
export const SUCCESS_TRANSFER_MVC = `${PREFIX}SUCCESS_TRANSFER_MVC`;
export const ERROR_TRANSFER_MVC = `${PREFIX}ERROR_TRANSFER_MVC`;

export const RESET_MVC_MVC_TRANSFER = `${PREFIX}RESET_MVC_MVC_TRANSFER`;
export const RESET_ALL_MVC_MVC_TRANSFER = `${PREFIX}RESET_ALL_MVC_MVC_TRANSFER`;

export const LOADING_MVC_TRANSFER_HISTORY = `${PREFIX}LOADING_MVC_TRANSFER_HISTORY`;
export const RECEIVED_MVC_TRANSFER_HISTORY = `${PREFIX}RECEIVED_MVC_TRANSFER_HISTORY`;
export const ERROR_MVC_TRANSFER_HISTORY = `${PREFIX}ERROR_MVC_TRANSFER_HISTORY`;

export const MVC_TRANSFER_OPERATION_ID= `${PREFIX}MVC_TRANSFER_OPERATION_ID`;

export const getFromMVCInfo = (token) => (dispatch, getState) => {
    dispatch({ type: LOADING_FROM_MVC });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`tag/${tagID}/card/${token}`)
        .then((success) => {
            dispatch({ type: RECEIVED_FROM_MVC, payload: success.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_FROM_MVC, payload: error });
            return error;
        });
};

export const getToMVCInfo = (token) => (dispatch, getState) => {
    dispatch({ type: LOADING_TO_MVC });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`tag/${tagID}/card/${token}`)
        .then((success) => {
            dispatch({ type: RECEIVED_TO_MVC, payload: success.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_TO_MVC, payload: error });
            return error;
        });
};

export const MVCToMVCTransferAndTerminate = (fromToken, toToken, amount) => (dispatch, getState) => {
    dispatch({ type: TRANSFERRING_MVC });

    const { tagID, tagCurrency } = getState().tag;
    const { mvcTransferOperationID } = getState().mvc_mcvTransfer;

    const headers = {
        "operation-id":mvcTransferOperationID,
    };

    const data = { receiving_card_token: toToken, amount: amount, currency_code: tagCurrency, non_transhandler: true };

    return issuingAPI
        .post(`/tag/${tagID}/card/${fromToken}/transfer-balance`, { ...data }, { headers })
        .then((success) => {
            dispatch({ type: SUCCESS_TRANSFER_MVC, payload: success.data });
            dispatch(getToMVCInfo(toToken));
            dispatch(getFromMVCInfo(fromToken));
            dispatch(getNotificationList());
        })
        .catch((error) => {
            dispatch({ type: ERROR_TRANSFER_MVC, payload: error });
            return error;
        });
};

export const getMVCtoMVCTransferHistory = (fromDate, toDate, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_MVC_TRANSFER_HISTORY });

    const { tagID } = getState().tag;
    const params = {
        from_date: fromDate,
        to_date: toDate,
        page: page,
        per_page: process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    };

    return issuingAPI
        .get(`tag/${tagID}/mvc-transfer`, { params })
        .then((success) => {
            dispatch({ type: RECEIVED_MVC_TRANSFER_HISTORY, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: ERROR_MVC_TRANSFER_HISTORY, payload: error });
            return error;
        });
};

export const getMVCtoMVCTransferHistoryPaginate= (fromDate, toDate, page) => (dispatch, getState) => {
    //dispatch({ type: LOADING_MVC_TRANSFER_HISTORY });

    const { tagID } = getState().tag;
    const params = {
        from_date: fromDate,
        to_date: toDate,
        page: page,
        per_page: process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    };

    return issuingAPI
        .get(`tag/${tagID}/mvc-transfer`, { params })
        .then((success) => {
            dispatch({ type: RECEIVED_MVC_TRANSFER_HISTORY, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: ERROR_MVC_TRANSFER_HISTORY, payload: error });
            return error;
        });
};
