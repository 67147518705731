import React from "react";
import CloseIcon from "../../../../assets/svg/CloseIcon";

export default function TerminalsModal({ modalVisibility, modalData, modalCloseHandler }) {
    return (
        <div className={`modal ${modalVisibility ? " opacity-100" : " opacity-0 pointer-events-none"}  fixed w-full h-full top-0 left-0 flex items-center justify-center`}>
            <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg border border-kadozGray z-50 overflow-y-auto">
                <div className="modal-content py-4 text-left px-6 text-base text-gfDarkBlue">
                    {/* <!--Title--> */}
                    <div className="flex justify-between items-center ">
                        <div className="font-semibold">Terminals</div>
                        <button
                            onClick={() => {
                                modalCloseHandler(!modalVisibility);
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    {/* <!--Body--> */}

                    {modalData && modalData.map((t) => <span key={t}>{t}, </span>)}
                </div>
            </div>
        </div>
    );
}
