import { issuingAPI } from "../services/Axios";
import { randomString } from "../utils/Helper";

export const PREFIX = "@loadCard/";

export const LOAD_CARD_RESPONSE_ERROR = `${PREFIX}LOAD_CARD_RESPONSE_ERROR`;

export const INITIAL_LOAD_CARD = `${PREFIX}INITIAL_LOAD_CARD`;
export const RESET_LOAD_CARD = `${PREFIX}RESET_LOAD_CARD`;
export const CLEAR_CARD_ERRORS = `${PREFIX}CLEAR_CARD_ERRORS`;
export const ADD_ONE_CARD = `${PREFIX}ADD_ONE_CARD`;
export const SET_CARD_AMOUNT = `${PREFIX}SET_CARD_AMOUNT`;
export const REMOVE_CARD_FROM_LIST = `${PREFIX}REMOVE_CARD_FROM_LIST`;
export const SET_TOTAL_AMOUNT = `${PREFIX}SET_TOTAL_AMOUNT`;
export const CLEAR_CARDS = `${PREFIX}CLEAR_CARDS`;

export const LOADING_TICKET_NUMBER = `${PREFIX}LOADING_TICKET_NUMBER`;
export const RECEIVED_TICKET_NUMBER = `${PREFIX}RECEIVED_TICKET_NUMBER`;

export const REMOVE_PAYMENT_METHOD = `${PREFIX}REMOVE_PAYMENT_METHOD`;
export const ADD_PAYMENT_METHOD = `${PREFIX}ADD_PAYMENT_METHOD`;

export const GENERATE_OPERATION_ID = `${PREFIX}GENERATE_OPERATION_ID`;

export const getTicketNumber = () => (dispatch) => {
    dispatch({ type: LOADING_TICKET_NUMBER });

    return issuingAPI
        .get(`/ticket`)
        .then((response) => {
            dispatch({ type: RECEIVED_TICKET_NUMBER, payload: response.data.ticket });
            dispatch({type: GENERATE_OPERATION_ID })
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_ADDITIONAL_PRODUCTS = `${PREFIX}LOADING_ADDITIONAL_PRODUCTS`;
export const RECEIVED_ADDITIONAL_PRODUCTS = `${PREFIX}RECEIVED_ADDITIONAL_PRODUCTS`;

export const getAdditionalProducts = () => (dispatch, getState) => {
    dispatch({ type: LOADING_ADDITIONAL_PRODUCTS });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`/tag/${tagID}/additional-product`)
        .then((response) => {
            dispatch({ type: RECEIVED_ADDITIONAL_PRODUCTS, payload: response.data });
            dispatch(getPaymentMethods());
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_PAYMENT_METHODS = `${PREFIX}LOADING_PAYMENT_METHODS`;
export const RECEIVED_PAYMENT_METHODS = `${PREFIX}RECEIVED_PAYMENT_METHODS`;

export const getPaymentMethods = () => (dispatch, getState) => {
    dispatch({ type: LOADING_PAYMENT_METHODS });

    const { tagID } = getState().tag;
    const params = {
        user_profile: true,
    };

    return issuingAPI
        .get(`/tag/${tagID}/payment-method`, { params })
        .then((response) => {
            dispatch({ type: RECEIVED_PAYMENT_METHODS, payload: response.data });
            dispatch(getChannels());
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_CHANNELS = `${PREFIX}LOADING_CHANNELS`;
export const RECEIVED_CHANNELS = `${PREFIX}RECEIVED_CHANNELS`;

export const getChannels = () => (dispatch, getState) => {
    dispatch({ type: LOADING_CHANNELS });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`/tag/${tagID}/channel`)
        .then((response) => {
            dispatch({ type: RECEIVED_CHANNELS, payload: response.data });
            dispatch(getDesks());
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_DESKS = `${PREFIX}LOADING_DESKS`;
export const RECEIVED_DESKS = `${PREFIX}RECEIVED_DESKS`;

export const getDesks = () => (dispatch, getState) => {
    dispatch({ type: LOADING_DESKS });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`/tag/${tagID}/desk`)
        .then((response) => {
            dispatch({ type: RECEIVED_DESKS, payload: response.data });
            dispatch(getFields());
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_FIELDS = `${PREFIX}LOADING_FIELDS`;
export const RECEIVED_FIELDS = `${PREFIX}RECEIVED_FIELDS`;

export const getFields = () => (dispatch, getState) => {
    dispatch({ type: LOADING_FIELDS });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`/tag/${tagID}/field`)
        .then((response) => {
            dispatch({ type: RECEIVED_FIELDS, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_FROM_TOKEN_VALIDITY = `${PREFIX}LOADING_FROM_TOKEN_VALIDITY`;
export const RECEIVED_FROM_TOKEN_VALIDITY = `${PREFIX}RECEIVED_FROM_TOKEN_VALIDITY`;
export const FROM_TOKEN_TEXT_COLOR = `${PREFIX}FROM_TOKEN_TEXT_COLOR`;

export const checkValidityOfFromToken = (fromToken) => (dispatch, getState) => {
    dispatch({ type: LOADING_FROM_TOKEN_VALIDITY });

    const { tagID } = getState().tag;

    const params = {
        token: fromToken,
    };

    return issuingAPI
        .get(`/tag/${tagID}/validate-token`, { params })
        .then((response) => {
            if (response.data.valid === true) {
                dispatch({ type: RECEIVED_FROM_TOKEN_VALIDITY, payload: true });
                dispatch({ type: FROM_TOKEN_TEXT_COLOR, payload: "text-kadozGreen" });
            } else {
                dispatch({ type: RECEIVED_FROM_TOKEN_VALIDITY, payload: false });
                dispatch({ type: FROM_TOKEN_TEXT_COLOR, payload: "text-kadozRed" });
            }
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_TO_TOKEN_VALIDITY = `${PREFIX}LOADING_TO_TOKEN_VALIDITY`;
export const RECEIVED_TO_TOKEN_VALIDITY = `${PREFIX}RECEIVED_TO_TOKEN_VALIDITY`;
export const TO_TOKEN_TEXT_COLOR = `${PREFIX}TO_TOKEN_TEXT_COLOR`;

export const checkValidityOfToToken = (toToken) => (dispatch, getState) => {
    dispatch({ type: LOADING_TO_TOKEN_VALIDITY });

    const { tagID } = getState().tag;

    const params = {
        token: toToken,
    };

    return issuingAPI
        .get(`/tag/${tagID}/validate-token`, { params })
        .then((response) => {
            if (response.data.valid === true) {
                dispatch({ type: RECEIVED_TO_TOKEN_VALIDITY, payload: true });
                dispatch({ type: TO_TOKEN_TEXT_COLOR, payload: "text-kadozGreen" });
            } else {
                dispatch({ type: RECEIVED_TO_TOKEN_VALIDITY, payload: false });
                dispatch({ type: TO_TOKEN_TEXT_COLOR, payload: "text-kadozRed" });
            }
        })
        .catch((error) => {
            dispatch({ type: LOAD_CARD_RESPONSE_ERROR, payload: error });
            return error;
        });
};

export const LOADING_CARD_RANGE = `${PREFIX}LOADING_CARD_RANGE`;
export const RECEIVED_CARD_RANGE = `${PREFIX}RECEIVED_CARD_RANGE`;
export const ERROR_CARD_RANGE = `${PREFIX}ERROR_CARD_RANGE`;

export const getCardRange = (fromToken, toToken) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_RANGE });

    const params = {
        from: fromToken,
        to: toToken,
    };

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`/tag/${tagID}/validate-token-range`, { params })
        .then((response) => {
            const { addCardAmount } = getState().loadCard;
            let temp = response.data.data.map((obj) => ({ ...obj, amount: parseFloat(addCardAmount).toFixed(2) }));
            dispatch({ type: RECEIVED_CARD_RANGE, payload: temp });
            dispatch({ type: SET_TOTAL_AMOUNT });
        })
        .catch((error) => {
            dispatch({ type: ERROR_CARD_RANGE, payload: error });
            return error;
        });
};

export const LOADING_LOAD_CARDS = `${PREFIX}LOADING_LOAD_CARDS`;
export const SUCCESS_LOAD_CARDS = `${PREFIX}SUCCESS_LOAD_CARDS`;
export const ERROR_LOAD_CARDS = `${PREFIX}ERROR_LOAD_CARDS`;

export const loadCards =
    (cards, selectedPaymentMethods, selectedFields, selectedAdditionalProducts, selectedChannel, selectedDesk) =>
    (dispatch, getState) => {
        dispatch({ type: LOADING_LOAD_CARDS });

        const { tagID } = getState().tag;
        const { ticketNumber, loadCardOperationID } = getState().loadCard;

        const headers = { "operation-id": loadCardOperationID };

        return issuingAPI
            .post(
                `/tag/${tagID}/ticket/${ticketNumber}/load-card`,
                {
                    payments: selectedPaymentMethods,
                    tokens: cards,
                    fields: selectedFields,
                    additional_products: selectedAdditionalProducts,
                    channel: selectedChannel,
                    desk: selectedDesk,
                },
                { headers }
            )
            .then((response) => {
                dispatch({ type: SUCCESS_LOAD_CARDS, payload: response.data.ticket });
                dispatch(loadCardPayments());
            })
            .catch((error) => {
                dispatch({ type: ERROR_LOAD_CARDS, payload: error });
                return error;
            });
    };

export const LOADING_LOAD_CARDS_PAYMENT = `${PREFIX}LOADING_LOAD_CARDS_PAYMENT`;
export const SUCCESS_LOAD_CARDS_PAYMENT = `${PREFIX}SUCCESS_LOAD_CARDS_PAYMENT`;
export const ERROR_LOAD_CARDS_PAYMENTS = `${PREFIX}ERROR_LOAD_CARDS_PAYMENTS`;

export const loadCardPayments = () => (dispatch, getState) => {
    dispatch({ type: LOADING_LOAD_CARDS_PAYMENT });

    const { selectedTerminal } = getState().terminal;
    const { ticketNumber, loacCardPaymentOperationID } = getState().loadCard;
    const { tagID } = getState().tag;

    const headers = { "operation-id": loacCardPaymentOperationID };

    if (selectedTerminal !== null) {
        const params = { terminal_id: selectedTerminal.id };

        return issuingAPI
            .get(`/tag/${tagID}/ticket/${ticketNumber}/load-card-payment`, { headers, params })
            .then((response) => {
                dispatch({ type: SUCCESS_LOAD_CARDS_PAYMENT, payload: response.data });
                dispatch(getTicketInformation());
            })
            .catch((error) => {
                dispatch({ type: ERROR_LOAD_CARDS_PAYMENTS, payload: error });
                return error;
            });
    } else {
        return issuingAPI
            .get(`/tag/${tagID}/ticket/${ticketNumber}/load-card-payment`, { headers })
            .then((response) => {
                dispatch({ type: SUCCESS_LOAD_CARDS_PAYMENT, payload: response.data });
                dispatch(getTicketInformation());
            })
            .catch((error) => {
                dispatch({ type: ERROR_LOAD_CARDS_PAYMENTS, payload: error });
                return error;
            });
    }
};

export const LOADING_TICKET_INFO = `${PREFIX}LOADING_TICKET_INFO`;
export const RECEIVED_TICKET_INFO = `${PREFIX}RECEIVED_TICKET_INFO`;
export const ERROR_TICKET_INFO = `${PREFIX}ERROR_TICKET_INFO`;
export const OPEN_TICKET_PRINT = `${PREFIX}OPEN_TICKET_PRINT`;

export const getTicketInformation = () => (dispatch, getState) => {
    dispatch({ type: LOADING_TICKET_INFO });

    const { ticketNumber } = getState().loadCard;
    const { tagID } = getState().tag;

    return issuingAPI
        .get(`/tag/${tagID}/ticket/${ticketNumber}`)
        .then((response) => {
            dispatch({ type: RECEIVED_TICKET_INFO, payload: response.data });
            document.body.dispatchEvent(new Event(OPEN_TICKET_PRINT, { bubbles: true, cancelable: true }));
        })
        .catch((error) => {
            dispatch({ type: ERROR_TICKET_INFO, payload: error });
            return error;
        });
};
