/* eslint-disable import/no-anonymous-default-export */
import {
    ERROR_TOKENIZATION_REPORT,
    LOADING_TOKENIZATION_REPORT,
    RECEIVED_TOKENIZATION_REPORT,
    RESET_TOKENIZATION_REPORT,
} from "../../actions/TokenizationActions";

const initialState = {
    loadingTokenizationReport: false,
    receivingTokenizationReport: null,
    errorTokenizationReport: null,
    tokenizationReportSummary: null,
    populatedList: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_TOKENIZATION_REPORT:
            return {
                ...state,
                loadingTokenizationReport: true,
                receivingTokenizationReport: null,
                errorTokenizationReport: null,
                tokenizationReportSummary: null,
            };

        case RECEIVED_TOKENIZATION_REPORT:
            console.log(action.payload)
            return {
                ...state,
                loadingTokenizationReport: false,
                receivingTokenizationReport: action.payload.data,
                errorTokenizationReport: null,
                tokenizationReportSummary: action.payload.summary,
            };

        case ERROR_TOKENIZATION_REPORT:
            return {
                ...state,
                loadingTokenizationReport: false,
                receivingTokenizationReport: null,
                errorTokenizationReport: null,
                tokenizationReportSummary: action.payload,
            };

        case RESET_TOKENIZATION_REPORT:
            return {
                ...state,
                ...initialState,
            };

        default:
            return state;
    }
}
