import React, { useEffect, useState } from "react";
import UserAddIcon from "../../../assets/svg/UserAddIcon";
import ViewIcon from "../../../assets/svg/ViewIcon";
import EditIcon from "../../../assets/svg/EditIcon";
import TrashIcon from "../../../assets/svg/TrashIcon";
import Container from "../../UIComponents/Container";
import Table from "../../UIComponents/Table";
import AddUser from "./AddUser";
import { useDispatch, useSelector } from "react-redux";
import {
    activateDeactivateUserByID,
    deleteUserByID,
    getUserList,
    getUserListAfterChanges,
    getUserListKeyword,
    getUserOrganizationPermissions,
    OPEN_USER,
    RESET_ACTIVATE_DEACTIVATE_USER,
    RESET_DELETING_USER,
} from "../../../actions/Admin/UserManagement/UserManagementActions";
import ViewUser from "./ViewUser";
import UserDeleteConfirmation from "./UserDeleteConfirmation";
import ActivateDeactivateUser from "./ActivateDeactivateUser";
import LockIcon from "../../../assets/svg/LockIcon";
import UnLockIcon from "../../../assets/svg/UnLockIcon";
import EditUserNew from "./EditUserNew";
import Tooltip from "react-simple-tooltip";
import { GF_DARK_BLUE, GF_PERIWINKLE } from "../../../utils/Constants";
import SearchIcon from "../../../assets/svg/SearchIcon";
import ClearIcon from "../../../assets/svg/ClearIcon";
import { useTranslation } from "react-i18next";
import ButtonLoading from "../../../assets/svg/ButtonLoading";
import ShoppingCenterDropdown from "../../Dropdown/ShoppingCenterDropdown";
import AddExistingUser from "./AddExistingUser";
import MVCMgt from "../../../assets/svg/MVCMgt";
import UserMVCMgt from "./UserMVCMgt";
import { getLoggedInUserMVC, getMVCByUser } from "../../../actions/MVCManagementAction";

export default function UserManagement() {
    const dispatch = useDispatch();

    const { loadingUserList, receivedUserList, openUser } = useSelector((state) => state.userManagement);
    const { receivedLoggedInUserTagPermissions, addUserPermissions } = useSelector((state) => state.auth);
    const { loadingUserMVC } = useSelector((state) => state.mvc);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getUserList());
        dispatch(getLoggedInUserMVC());
    }, []);

    const [addUserIsOpen, setAddUserIsOpen] = useState(false);
    const [viewUserIsOpen, setViewUserIsOpen] = useState(false);
    const [viewUser, setViewUser] = useState({});
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [deleteUserID, setDeleteUserID] = useState("");
    const [activateDeactivateUser, setActivateDeactivateUser] = useState({});
    const [openActivateDeactivate, setOpenActivateDeactivate] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [editedUser, setEditedUser] = useState({});
    const [keyword, setKeyword] = useState("");
    const [correctUser, setCorrectUser] = useState("");
    const [existingUser, setExistingUser] = useState({});
    const [openExistingUser, setOpenExistingUser] = useState(false);
    const [openMVCMgt, setOpenMVCMgt] = useState(false);
    const [MVCUser, setMVCUser] = useState({});
    const [userMVCList, setUserMVCList] = useState([]);

    const addUserCloseHandler = () => {
        addUserPermissions.forEach((item) => {
            item.list.forEach((li) => {
                li.isChecked = false;
            });
        });
        setAddUserIsOpen(false);
    };

    const addUserOpenHandler = () => {
        setAddUserIsOpen(true);
    };

    const openViewUser = (user) => {
        dispatch(getUserOrganizationPermissions(user.id));
        setViewUser((prev) => Object.assign(prev, user));
        setViewUserIsOpen(true);
    };

    const viewUserCloseHandler = () => {
        setViewUser({});
        setViewUserIsOpen(false);
    };

    const openDeleteView = (user) => {
        setDeleteUserID(user);
        setDeleteIsOpen(!deleteIsOpen);
    };

    const deleteUser = () => {
        dispatch(deleteUserByID(deleteUserID)).then(
            (success) => {
                closeDeleteUser();
                dispatch(getUserListAfterChanges());
            },
            (error) => {
                console.log("error");
            }
        );
    };

    const closeDeleteUser = () => {
        setDeleteUserID("");
        setDeleteIsOpen(!deleteIsOpen);
        dispatch({ type: RESET_DELETING_USER });
    };

    const openActivateDeactivateUser = (user) => {
        setActivateDeactivateUser(user);
        setOpenActivateDeactivate(!openActivateDeactivate);
    };

    const activateDeactivateHandler = () => {
        dispatch(activateDeactivateUserByID(activateDeactivateUser)).then(
            (success) => {
                closeActivate();
                dispatch(getUserListAfterChanges());
            },
            (error) => {
                console.log("error");
            }
        );
    };

    const closeActivate = () => {
        setActivateDeactivateUser({});
        setOpenActivateDeactivate(!openActivateDeactivate);
        dispatch({ type: RESET_ACTIVATE_DEACTIVATE_USER });
    };

    const openEditView = (event, user) => {
        if (event.currentTarget.id === user.id) {
            setCorrectUser(event.currentTarget.id);
            //dispatch(getUserPermission(user.id));
            dispatch(getUserOrganizationPermissions(user.id));
            setEditedUser(user);
            dispatch({ type: OPEN_USER, payload: true });
            setTimeout(function () {
                setOpenEditUser(!openEditUser);
            }, 1000);
        }
    };

    const closeEditView = () => {
        setEditedUser({});
        setOpenEditUser(!openEditUser);
        addUserPermissions.forEach((item) => {
            item.list.forEach((li) => {
                li.isChecked = false;
            });
        });
    };

    const openMVCManagement = (event, user) => {
        console.log(event.currentTarget.id, user.id);
        if (event.currentTarget.id === user.id) {
            setMVCUser(user);
            setCorrectUser(event.currentTarget.id);
            dispatch(getMVCByUser(user.id)).then((success) => {
                setUserMVCList(success.data.data);
                setOpenMVCMgt(true);
            });
        }
    };

    const closeMVCManagement = () => {
        setMVCUser({});
        setUserMVCList([]);
        setOpenMVCMgt(false);
    };

    const clearShopSearchHandler = () => {
        setKeyword("");
        dispatch(getUserListKeyword(""));
    };

    const shopSearchHandler = (event) => {
        event.persist();
        setKeyword(event.target.value);
        dispatch(getUserListKeyword(event.target.value));
    };

    const checkPermissionsExist = (guardName) => {
        if (receivedLoggedInUserTagPermissions !== null) {
            for (let i = 0; i < receivedLoggedInUserTagPermissions.length; i++) {
                for (let k = 0; k < receivedLoggedInUserTagPermissions[i].list.length; k++) {
                    if (receivedLoggedInUserTagPermissions[i].list[k].guard_name === guardName) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    const openExistingUserHandler = (user) => {
        dispatch({ type: OPEN_USER, payload: true });

        setTimeout(function () {
            setExistingUser(user);

            setOpenExistingUser(!openExistingUser);
            setAddUserIsOpen(false);
        }, 1000);
    };

    const closeExistingView = () => {
        setExistingUser({});
        setOpenExistingUser(!openExistingUser);
        addUserPermissions.forEach((item) => {
            item.list.forEach((li) => {
                li.isChecked = false;
            });
        });
    };

    return (
        <Container title="User Management" loading={loadingUserList}>
            <div className="flex flex-col space-y-5">
                <div className="flex justify-between items-center">
                    <div className="flex flex-row space-x-5">
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                <button className="focus:outline-none focus:shadow-outline">
                                    <SearchIcon />
                                </button>
                            </span>
                            {keyword !== "" && (
                                <button
                                    onClick={clearShopSearchHandler}
                                    className="focus:outline-none focus:shadow-outline"
                                    style={{ position: "absolute", right: "5px", top: "9px" }}
                                >
                                    <ClearIcon />
                                </button>
                            )}
                            <input
                                value={keyword}
                                onChange={shopSearchHandler}
                                className="h-10 text-lg w-full placeholder-gfGrey rounded border border-gfPeriwinkle focus:border-gfPeriwinkle pl-10 focus:outline-none"
                                placeholder={t("Search") + "..."}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    {checkPermissionsExist("admin-user-add") && (
                        <Tooltip
                            content={t("Create-New-User")}
                            customCss={`white-space: nowrap;z-index: 1;`}
                            zIndex={1}
                            background="#FFF"
                            border={GF_PERIWINKLE}
                            color={GF_DARK_BLUE}
                            fontFamily="inherit"
                            radius={4}
                            padding={10}
                            placement="top"
                        >
                            <button
                                onClick={addUserOpenHandler}
                                className="border rounded py-2 px-4 bg-gfCoral hover:bg-opacity-75 cursor-pointer focus:outline-none"
                            >
                                <UserAddIcon />
                            </button>
                        </Tooltip>
                    )}
                </div>

                {receivedUserList && receivedUserList.length !== 0 && (
                    <Table>
                        <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
                            <tr>
                                <th scope="col" className="px-4 py-3 text-center uppercase tracking-wider">
                                    {t("User")}
                                </th>

                                <th scope="col" className="px-4 py-3 text-center uppercase tracking-wider">
                                    {t("Email-address")}
                                </th>

                                <th scope="col" className="px-4 py-3 w-32 text-center uppercase tracking-wider">
                                    {t("Actions")}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                            {receivedUserList !== null &&
                                receivedUserList.length !== 0 &&
                                receivedUserList.map((user) => (
                                    <tr key={user.id}>
                                        <td className="px-4 py-4">
                                            <div className="flex justify-center items-center">{user.name}</div>
                                        </td>
                                        <td className="px-4 py-4">
                                            <div className="flex justify-center items-center">{user.email}</div>
                                        </td>

                                        <td className="px-4 py-4">
                                            <div className="flex justify-between items-center space-x-5">
                                                <Tooltip
                                                    content={t("View-User")}
                                                    customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                                                    zIndex={1}
                                                    background="#FFF"
                                                    border={GF_PERIWINKLE}
                                                    color={GF_DARK_BLUE}
                                                    fontFamily="inherit"
                                                    radius={4}
                                                    padding={10}
                                                    placement="top"
                                                >
                                                    <button
                                                        onClick={() => openViewUser(user)}
                                                        className="bg-gfPeriwinkle rounded py-2 px-4 focus:outline-none"
                                                    >
                                                        <ViewIcon />
                                                    </button>
                                                </Tooltip>
                                                {checkPermissionsExist("admin-user-edit") && (
                                                    <Tooltip
                                                        content={t("Edit-User")}
                                                        customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                                                        zIndex={1}
                                                        background="#FFF"
                                                        border={GF_PERIWINKLE}
                                                        color={GF_DARK_BLUE}
                                                        fontFamily="inherit"
                                                        radius={4}
                                                        padding={10}
                                                        placement="top"
                                                    >
                                                        <button
                                                            id={user.id}
                                                            className="bg-gfPeriwinkle flex justify-between items-center rounded py-2 px-4 focus:outline-none"
                                                            onClick={(event) => openEditView(event, user)}
                                                        >
                                                            {openUser && correctUser === user.id && (
                                                                <ButtonLoading color={"#4A5568"} />
                                                            )}
                                                            <EditIcon />
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {checkPermissionsExist("admin-user-deactivate") && user.active && (
                                                    <Tooltip
                                                        content={t("Deactivate-User")}
                                                        customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                                                        zIndex={1}
                                                        background="#FFF"
                                                        border={GF_PERIWINKLE}
                                                        color={GF_DARK_BLUE}
                                                        fontFamily="inherit"
                                                        radius={4}
                                                        padding={10}
                                                        placement="top"
                                                    >
                                                        <button
                                                            className={`py-2 px-4 ${
                                                                user.active ? "bg-gfCoral" : "bg-gfPeriwinkle"
                                                            } focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded`}
                                                            onClick={() => openActivateDeactivateUser(user)}
                                                        >
                                                            <LockIcon />
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {checkPermissionsExist("admin-user-activate") && !user.active && (
                                                    <Tooltip
                                                        content={t("Activate-User")}
                                                        customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                                                        zIndex={1}
                                                        background="#FFF"
                                                        border={GF_PERIWINKLE}
                                                        color={GF_DARK_BLUE}
                                                        fontFamily="inherit"
                                                        radius={4}
                                                        padding={10}
                                                        placement="top"
                                                    >
                                                        <button
                                                            className={`py-2 px-4 ${
                                                                user.active ? "bg-gfCoral" : "bg-gfPeriwinkle"
                                                            } focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded`}
                                                            onClick={() => openActivateDeactivateUser(user)}
                                                        >
                                                            <UnLockIcon />
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {(checkPermissionsExist("mvc-list") ||
                                                    checkPermissionsExist("load-card")) && (
                                                    <Tooltip
                                                        content={t("MVC-management")}
                                                        customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                                                        zIndex={1}
                                                        background="#FFF"
                                                        border={GF_PERIWINKLE}
                                                        color={GF_DARK_BLUE}
                                                        fontFamily="inherit"
                                                        radius={4}
                                                        padding={10}
                                                        placement="top"
                                                    >
                                                        <button
                                                            id={user.id}
                                                            className="bg-gfPeriwinkle rounded py-2 px-4 focus:outline-none flex justify-between items-center"
                                                            onClick={(event) => openMVCManagement(event, user)}
                                                        >
                                                            {loadingUserMVC && correctUser === user.id && (
                                                                <ButtonLoading color={"#4A5568"} />
                                                            )}
                                                            <MVCMgt />
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {checkPermissionsExist("admin-user-delete") && (
                                                    <Tooltip
                                                        content={t("Delete-User")}
                                                        customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                                                        zIndex={1}
                                                        background="#FFF"
                                                        border={GF_PERIWINKLE}
                                                        color={GF_DARK_BLUE}
                                                        fontFamily="inherit"
                                                        radius={4}
                                                        padding={10}
                                                        placement="top"
                                                    >
                                                        <button
                                                            className="bg-gfPeriwinkle rounded py-2 px-4 focus:outline-none"
                                                            onClick={() => openDeleteView(user.id)}
                                                        >
                                                            <TrashIcon />
                                                        </button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                )}
            </div>
            <AddUser
                isOpen={addUserIsOpen}
                closeHandler={addUserCloseHandler}
                userData={viewUser}
                openExistingUserView={openExistingUserHandler}
            />
            <ViewUser isOpen={viewUserIsOpen} closeHandler={viewUserCloseHandler} userData={viewUser} />
            <EditUserNew isOpen={openEditUser} closeHandler={closeEditView} userData={editedUser} />
            <AddExistingUser isOpen={openExistingUser} userData={existingUser} closeHandler={closeExistingView} />

            <UserDeleteConfirmation isOpen={deleteIsOpen} yesOption={deleteUser} noOption={closeDeleteUser} />
            <ActivateDeactivateUser
                isOpen={openActivateDeactivate}
                yesOption={activateDeactivateHandler}
                noOption={closeActivate}
            />
            <UserMVCMgt isOpen={openMVCMgt} closeHandler={closeMVCManagement} userData={MVCUser} />
        </Container>
    );
}
