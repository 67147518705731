import { issuingAPI } from "../services/Axios";

export const PREFIX = "@cardStock/";

export const LOADING_CARD_STOCK = `${PREFIX}LOADING_CARD_STOCK`;
export const RECEIVED_CARD_STOCK = `${PREFIX}RECEIVED_CARD_STOCK`;
export const ERROR_CARD_STOCK = `${PREFIX}ERROR_CARD_STOCK`;

export const getCardStock = () => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_STOCK });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`/tag/${tagID}/card/stock`)
        .then((success) => {
            dispatch({ type: RECEIVED_CARD_STOCK, payload: success.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_CARD_STOCK, payload: error });
            return error;
        });
};
