import React from "react";
import { useTranslation } from "react-i18next";

const colors = [
    { status: "CANCELLED", color: "gfDarkSand" },
    { status: "ORDERED", color: "gfGrey" },
    { status: "PAID", color: "gfLightBlue" },
    { status: "PREPARED", color: "gfLightBlue" },
    { status: "SENT", color: "gfCoral" },
    { status: "PARTIALLY_ACTIVE", color: "gfPeriwinkle" },
    { status: "ACTIVE", color: "gfPeriwinkle" },
    { status: "TO_PICK_UP", color: "gfLightBlue" },
    { status: "ORDER_TO_FULFILL", color: "gfLightBlue" },
    { status: "PICKED_UP", color: "gfLightBlue" },
    { status: "AWAITING_DELIVERY", color: "gfLightBlue" }


];

export default function SalesStatus ({ status }) {
        
    const { t } = useTranslation();
    const col = colors
        .filter((obj) => obj.status === String(status).toUpperCase())
        .map((filteredObj) => filteredObj.color);

    let str;

    if (status === "cancelled") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Canceled")).toUpperCase() }
            </div>
        );
    } else if (status === "ordered") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Ordered")).toUpperCase() }
            </div>
        );
    } else if (status === "paid") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Paid")).toUpperCase() }
            </div>
        );
    } else if (status === "prepared") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Prepared")).toUpperCase() }
            </div>
        );
    } else if (status === "sent") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Sent")).toUpperCase() }
            </div>
        );
    } else if (status === "partially_active") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Partially-active")).toUpperCase() }
            </div>
        );
    } else if (status === "active") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Active")).toUpperCase() }
            </div>
        );
    }
    else if (status === "to_pick_up") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("To-Pick-Up")).toUpperCase() }
            </div>
        );
    }
    else if (status === "order_to_fulfill") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Orders-to-fulfill")).toUpperCase() }
            </div>
        );
    }

    else if (status === "picked_up") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Picked-Up")).toUpperCase() }
            </div>
        );
    }
    else if (status === "awaiting_delivery") {
        str = (
            <div className={ `text-${col}` }>
                { String(t("Awaiting-Delivery")).toUpperCase() }
            </div>
        );
    }
    return str;
}
