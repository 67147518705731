/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Userpilot } from "userpilot";
import { RESET_CSV_REPORT } from "../../../../actions/CommonActions";
import {
    exportCSVProductReport,
    getProductReport,
    POPULATED_LIST,
    RESET_PRODUCT_REPORT,
} from "../../../../actions/ProductReportActions";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import ChevronDown from "../../../../assets/svg/ChevronDown";
import ChevronUp from "../../../../assets/svg/ChevronUp";
import { getCalanderLocale } from "../../../../utils/CalanderLocale";
import { GF_DARK_BLUE } from "../../../../utils/Constants";
import FailAlert from "../../../Alert/FailAlert";
import MessageAlert from "../../../Alert/MessageAlert";
import CardInfoWidget from "../../../CardInfoWidget";
import Container from "../../../UIComponents/Container";
import Table from "../../../UIComponents/Table";

export default function ProductReport() {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [selectedID, setSelectedID] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_PRODUCT_REPORT });
        dispatch({ type: RESET_CSV_REPORT });

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_PRODUCT_REPORT });
            dispatch({ type: RESET_CSV_REPORT });
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_PRODUCT_REPORT });
            dispatch({ type: RESET_CSV_REPORT });
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    const { loadingProductReport, receivingProductReport, errorProductReport, populatedList } = useSelector(
        (state) => state.productReport
    );
    const { loadingCSV, errorCSV } = useSelector((state) => state.commonReducer);
    const { selectedLanguage } = useSelector((state) => state.language);
    const { selectedTag } = useSelector((state) => state.tag);

    getCalanderLocale(selectedLanguage);

    const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly={true}
            className="border cursor-pointer w-full text-center rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 focus:outline-none"
        />
    ));

    const fromDatePickHandler = (date) => {
        setFromDate(date);
    };

    const toDatePickHandler = (date) => {
        setToDate(date);
    };

    const getChartHandler = () => {
        let from = format(fromDate, "yyyy-MM-dd");
        let to;
        if (toDate !== null) {
            to = format(toDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }

        dispatch(getProductReport(from, to));
    };

    const exportCSV = () => {
        let from = format(fromDate, "yyyy-MM-dd");
        let to;
        if (toDate !== null) {
            to = format(toDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }

        dispatch(exportCSVProductReport(from, to, "Product Report"));
    };

    const handlePageClick = (event) => {
        dispatch({ type: POPULATED_LIST, payload: event.selected + 1 });
    };

    const showHideMoreCards = (event, ticketNumber) => {
        console.log(event.currentTarget.id, ticketNumber);
        if (event.currentTarget.id === ticketNumber) {
            setShowMore(!showMore);
            setSelectedID(ticketNumber);
        }
    };

    return (
        <Container title={t("Product-report")}>
            <div className="grid grid-cols-1 md:grid-cols-3 items-end md:gap-5 gap-10">
                <div className="flex flex-col">
                    <div className="flex items-center py-1">{t("Choose-the-date")}:</div>{" "}
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingProductReport}
                        dateFormat="dd/MM/yyyy"
                        customInput={<DPCustomInput />}
                        selected={fromDate}
                        onChange={(date) => fromDatePickHandler(date)}
                        locale={selectedLanguage.value}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="flex items-center  py-1">{t("End-date") + "(" + t("Optional") + ")"}:</div>{" "}
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingProductReport}
                        dateFormat="dd/MM/yyyy"
                        selected={toDate}
                        onChange={(date) => toDatePickHandler(date)}
                        customInput={<DPCustomInput />}
                        locale={selectedLanguage.value}
                    />
                </div>

                <button
                    disabled={loadingProductReport}
                    onClick={getChartHandler}
                    className="h-10 border flex flex-row items-center justify-center rounded  hover:bg-opacity-75 bg-gfCoral font-MulishBold px-4 focus:outline-none"
                >
                    {loadingProductReport && <ButtonLoading color={GF_DARK_BLUE} />}
                    {t("Go")}!
                </button>
            </div>
            {((receivingProductReport && receivingProductReport.products.length === 0) || errorProductReport) && (
                <div className="p-5">
                    {receivingProductReport && receivingProductReport.products.length === 0 && (
                        <MessageAlert message={t("There-are-no-information-to-display-for-this-date")} />
                    )}
                    {errorProductReport && <FailAlert message={errorProductReport.message} />}
                    {errorCSV && <FailAlert message={errorCSV.message} />}
                </div>
            )}

            {receivingProductReport && receivingProductReport.products.length !== 0 && (
                <div className="flex flex-col mt-20 space-y-5">
                    <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
                        <CardInfoWidget
                            bottomLabel={t("Number-of-products")}
                            isCurrency={false}
                            value={receivingProductReport.number_of_products}
                            labelColor={"text-gfLightBlue"}
                            valueColor={"text-gfLightBlue"}
                        />
                        <CardInfoWidget
                            bottomLabel={t("Total-amount")}
                            isCurrency
                            value={receivingProductReport.total_amount}
                            labelColor={"text-gfDarkBlue"}
                            valueColor={"text-gfDarkBlue"}
                        />
                        <CardInfoWidget
                            bottomLabel={t("Total-tax")}
                            isCurrency
                            value={receivingProductReport.total_tax}
                            labelColor={"text-gfLightBlue"}
                            valueColor={"text-gfLightBlue"}
                        />
                    </div>

                    <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
                        <button
                            onClick={exportCSV}
                            className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                        >
                            {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                            {t("Export-as-a-CSV-file")}
                        </button>
                        {receivingProductReport.products.length > process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                            <div>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={
                                        receivingProductReport.products.length /
                                        process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                                    }
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                    </div>

                    <Table>
                        <thead className="bg-gfGrey font-MulishBlack text-xs">
                            <tr>
                                <th scope="col" className="w-40 md:w-48 px-4 py-3 text-center uppercase tracking-wider">
                                    {t("Date")}
                                </th>
                                <th scope="col" className="px-2 py-3 w-32 text-center uppercase tracking-wider">
                                    {t("Ticket-number")}
                                </th>
                                <th scope="col" className="w-48 px-2 py-3 text-center uppercase tracking-wider">
                                    {t("Product-name")}
                                </th>
                                <th scope="col" className="w-24 px-2 py-3 text-center uppercase tracking-wider">
                                    {t("Unit-price")}
                                </th>
                                <th scope="col" className="px-6 py-3 text-center uppercase tracking-wider">
                                    {t("Tax-price")}
                                </th>
                                <th scope="col" className="px-2 py-3 w-24 text-center uppercase tracking-wider">
                                    {t("Quantity")}
                                </th>
                                <th scope="col" className="px-2 py-3 w-24 text-center uppercase tracking-wider">
                                    {t("Total-price")}
                                </th>
                                <th scope="col" className="px-2 py-3 w-24 text-center uppercase tracking-wider">
                                    {t("Action")}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                            {receivingProductReport &&
                                populatedList &&
                                populatedList.map((product) => (
                                    <tr
                                        key={product.ticket_number}
                                        className={`${product.product.length > 1 ? "shadow-md" : ""}`}
                                    >
                                        <td className="px-4 py-4">
                                            <div className="flex justify-center items-center">{product.date}</div>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className="flex justify-center items-center">
                                                {product.ticketNumber}
                                            </div>
                                        </td>
                                        <td className="px-2 py-4">
                                            {product.product.length === 1 && (
                                                <div className="flex flex-col justify-center items-center">
                                                    <span key={product.product[0].id}>{product.product[0].name}</span>
                                                </div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div className="flex flex-col justify-center items-center text-gfLightBlue font-MulishBlack">{`${
                                                    product.product.length
                                                } ${t("Products")}`}</div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div
                                                    className={`${
                                                        showMore && selectedID === product.ticketNumber
                                                            ? " flex flex-col justify-center items-center"
                                                            : "hidden"
                                                    }`}
                                                >
                                                    {product.product.map((entry) => (
                                                        <div key={entry.id} className="flex flex-row py-1 ">
                                                            <div>{entry.name}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-2 py-4">
                                            {product.product.length === 1 && (
                                                <div className="flex justify-center text-gfLightBlue font-MulishBlack">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={product.product[0].price}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                    <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                                </div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div className="flex justify-center text-gfLightBlue font-MulishBlack">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={product.product.reduce(
                                                            (a, pro) => (a += parseFloat(pro.price)),
                                                            0
                                                        )}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                    <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                                </div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div
                                                    className={`${
                                                        showMore && selectedID === product.ticketNumber
                                                            ? " flex flex-col justify-center items-center"
                                                            : "hidden"
                                                    }`}
                                                >
                                                    {product.product.map((entry) => (
                                                        <div key={entry.id} className="flex flex-row py-1 ">
                                                            <div className="flex justify-center text-gfLightBlue">
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={entry.price}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-6 py-4 w-48 whitespace-nowrap">
                                            {product.product.length === 1 && (
                                                <div className="flex justify-center text-gfLightBlue font-MulishBlack">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={product.product[0].tax}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                    <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                                </div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div className="flex justify-center text-gfLightBlue font-MulishBlack">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={product.product.reduce(
                                                            (a, pro) => (a += parseFloat(pro.tax)),
                                                            0
                                                        )}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                    <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                                </div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div
                                                    className={`${
                                                        showMore && selectedID === product.ticketNumber
                                                            ? " flex flex-col justify-center items-center"
                                                            : "hidden"
                                                    }`}
                                                >
                                                    {product.product.map((entry) => (
                                                        <div key={entry.id} className="flex flex-row py-1 ">
                                                            <div className="flex justify-center text-gfLightBlue">
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={entry.tax}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-2 py-4">
                                            {product.product.length === 1 && (
                                                <div className="flex justify-center items-center">
                                                    {product.quantity}
                                                </div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div className="flex justify-center text-gfLightBlue font-MulishBlack">
                                                    {product.quantity}
                                                </div>
                                            )}

                                            {product.product.length > 1 && (
                                                <div
                                                    className={`${
                                                        showMore && selectedID === product.ticketNumber
                                                            ? " flex flex-col justify-center items-center"
                                                            : "hidden"
                                                    }`}
                                                >
                                                    {product.product.map((entry) => (
                                                        <div key={entry.id} className="flex flex-row py-1 ">
                                                            <div className="flex justify-center items-center">
                                                                {entry.qty}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-2 py-4">
                                            <div className="flex justify-center text-gfLightBlue font-MulishBlack">
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={product.totalPrice}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                                <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                            </div>
                                            {product.product.length > 1 && (
                                                <div
                                                    className={`${
                                                        showMore && selectedID === product.ticketNumber
                                                            ? " flex flex-col justify-center items-center"
                                                            : "hidden"
                                                    }`}
                                                >
                                                    {product.product.map((entry) => (
                                                        <div key={entry.id} className="flex flex-row py-1 ">
                                                            <div className="flex justify-center text-gfLightBlue">
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={entry.net_total}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-2 py-4 ">
                                            {product.product.length > 1 && (
                                                <div className="flex justify-center items-center">
                                                    <button
                                                        className="focus:outline-none"
                                                        id={product.ticketNumber}
                                                        onClick={(event) =>
                                                            showHideMoreCards(event, product.ticketNumber)
                                                        }
                                                    >
                                                        {showMore && selectedID === product.ticketNumber ? (
                                                            <ChevronUp />
                                                        ) : (
                                                            <ChevronDown />
                                                        )}
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </Container>
    );
}
