/* eslint-disable import/no-anonymous-default-export */
import {
    CREATE_DELIVERY_ADDRESS,
    CREATE_DELIVERY_ADDRESS_ERROR,
    CREATE_DELIVERY_ADDRESS_SUCCESS,
    FETCH_DELIVERY_ADDRESSES,
    FETCH_DELIVERY_ADDRESSES_ERROR,
    FETCH_DELIVERY_ADDRESSES_SUCCESS,
    ORDER_CARDS_ADDRESS_LIST,
    REFRESH_ADDRESS_LIST,
    RESET_CREATE,
    SET_DEAFULT_DELIVERY_ADDRESS,
    SET_DEAFULT_DELIVERY_ADDRESS_ERROR,
    SET_DEAFULT_DELIVERY_ADDRESS_SUCCESS,
    SET_SELECTED_ADDRESS,
    UPDATE_DELIVERY_ADDRESS,
    UPDATE_DELIVERY_ADDRESS_ERROR,
    UPDATE_DELIVERY_ADDRESS_SUCCESS,
} from "../actions/DeliveryAddressActions";

const INITIAL_STATE = {
    deliveryAddresses: null,
    loadingDeliveryAddresses: false,
    errorDeliveryAddresses: null,

    selectedAddress: null,
    creatingDeliveryAddresses: false,

    updatingDeliveryAddresses: false,

    setDeafultAddress: false,

    creatingAddress: false,
    successCreatingAddress: null,
    errorCreatingAddress: null,

    orderCardsAddressList: null,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_DELIVERY_ADDRESSES:
            return {
                ...state,
                loadingDeliveryAddresses: true,
                deliveryAddresses: null,
                errorDeliveryAddresses: null,
            };

        case REFRESH_ADDRESS_LIST:
            return {
                ...state,
                loadingDeliveryAddresses: true,
            };

        case FETCH_DELIVERY_ADDRESSES_SUCCESS:
            return {
                ...state,
                deliveryAddresses: action.payload, //{ ...addresses },
                loadingDeliveryAddresses: false,
                errorDeliveryAddresses: null,
            };

        case FETCH_DELIVERY_ADDRESSES_ERROR:
            return {
                ...state,
                loadingDeliveryAddresses: false,
                errorDeliveryAddresses: action.payload,
                deliveryAddresses: null,
            };

        case ORDER_CARDS_ADDRESS_LIST:
            const filteredAddresses = action.payload.filter((address) => address.active !== false);
            return {
                ...state,
                orderCardsAddressList: filteredAddresses,
            };

        case CREATE_DELIVERY_ADDRESS:
            return { ...state, creatingAddress: true, successCreatingAddress: null, errorCreatingAddress: null };

        case CREATE_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                creatingAddress: false,
                successCreatingAddress: action.payload,
                errorCreatingAddress: null,
            };

        case RESET_CREATE:
            return {
                ...state,
                creatingAddress: false,
                successCreatingAddress: null,
                errorCreatingAddress: null,
            };

        case CREATE_DELIVERY_ADDRESS_ERROR:
            return {
                ...state,
                creatingAddress: false,
                successCreatingAddress: null,
                errorCreatingAddress: action.payload,
            };

        case SET_DEAFULT_DELIVERY_ADDRESS:
            return { ...state, setDeafultAddress: true };

        case SET_DEAFULT_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                setDeafultAddress: false,
            };

        case SET_DEAFULT_DELIVERY_ADDRESS_ERROR:
            return {
                ...state,
                setDeafultAddress: false,
            };

        case UPDATE_DELIVERY_ADDRESS:
            return {
                ...state,
                updatingDeliveryAddresses: true,
            };

        case UPDATE_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                updatingDeliveryAddresses: false,
            };

        case UPDATE_DELIVERY_ADDRESS_ERROR:
            return {
                ...state,
                updatingDeliveryAddresses: false,
            };

        case SET_SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress: action.payload,
            };

        default:
            return state;
    }
}
