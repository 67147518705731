import { issuingAPI } from "../services/Axios";

// CARD INFORMATION
export const LOADING_CARD_INFORMATION = "LOADING_CARD_INFORMATION";
export const RECEIVED_CARD_INFORMATION = "RECEIVED_CARD_INFORMATION";
export const ERROR_CARD_INFORMATION = "ERROR_CARD_INFORMATION";

// CARD TRANSACTIONS
export const LOADING_CARD_TRANSACTIONS = "LOADING_CARD_TRANSACTIONS";
export const RECEIVED_CARD_TRANSACTION_INFO = "RECEIVED_CARD_TRANSACTION_INFO";
export const ERROR_CARD_TRANSACTION_INFO = "ERROR_CARD_TRANSACTION_INFO";

// CARD BLOCK / UNBLOCK
export const LOADING_CARD_BLOCK_UNBLOCK = "LOADING_CARD_BLOCK_UNBLOCK";
export const SUCCESS_CARD_BLOCK_UNBLOCK = "SUCCESS_CARD_BLOCK_UNBLOCK";
export const FAIL_CARD_BLOCK_UNBLOCK = "FAIL_CARD_BLOCK_UNBLOCK";

export const RESET_CARD_INFO = "RESET_CARD_INFO";

export const getCardInformation = (token) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_INFORMATION });

    const { tagID } = getState().tag;

    return issuingAPI
        .get(`tag/${tagID}/card/${token}`)
        .then((success) => {
            dispatch({ type: RECEIVED_CARD_INFORMATION, payload: success.data });
            dispatch(getCardTransactionInformation(token, success.data.is_mvc, 1));
        })
        .catch((error) => {
            dispatch({ type: ERROR_CARD_INFORMATION, payload: error });
            return error;
        });
};

export const getCardTransactionInformation = (token, iSMVC, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_TRANSACTIONS });

    const { tagID } = getState().tag;

    const params = {
        per_page: process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        page: page,
    };

    issuingAPI
        .get(`/tag/${tagID}/card/${token}/${iSMVC ? "mvc-transfer" : "transaction"}`, { params })
        .then((success) => {
            dispatch({ type: RECEIVED_CARD_TRANSACTION_INFO, payload: success.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_CARD_TRANSACTION_INFO, payload: error });
            return error;
        });
};

export const blockCardByToken = (token, description) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_BLOCK_UNBLOCK });

    const { tagID } = getState().tag;

    const params = {
        description: description,
    };

    issuingAPI
        .post(`/tag/${tagID}/card/${token}/block`, { params })
        .then((success) => {
            dispatch({ type: SUCCESS_CARD_BLOCK_UNBLOCK, payload: true });
            window.location.reload();
        })
        .catch((error) => {
            dispatch({ type: FAIL_CARD_BLOCK_UNBLOCK, payload: error });
            return error;
        });
};

export const unblockCardByToken = (token, description) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_BLOCK_UNBLOCK });

    const { tagID } = getState().tag;

    const params = {
        description: description,
    };

    issuingAPI
        .post(`/tag/${tagID}/card/${token}/unblock`, { params })
        .then((success) => {
            dispatch({ type: SUCCESS_CARD_BLOCK_UNBLOCK, payload: true });
            window.location.reload();
        })
        .catch((error) => {
            dispatch({ type: FAIL_CARD_BLOCK_UNBLOCK, payload: error });
            return error;
        });
};
