import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../assets/svg/CloseIcon';
import Select from "react-select";
import routes from "../../routes/UserSettingsRoutes";
import { createUserSettings, getUserSettings, RESET_CREATING_LOGIN_USER_SETTINGS } from '../../actions/AuthActions';
import ButtonLoading from "../../assets/svg/ButtonLoading";
import SuccessAlert from '../Alert/SuccessAlert';
import FailAlert from '../Alert/FailAlert';
import ValidationErrors from '../Alert/ValidationErrors';

ReactModal.setAppElement("#root");

export default function UserSettings ({ isOpen, closeHandler }) {
  const { selectedLanguage, languages } = useSelector((state) => state.language);

  const { userSettings, errorUserSettings, creatingUserSettings, successUserSettings, failUserSettings } = useSelector((state) => state.auth);
  const { validationErrors } = useSelector((state) => state.commonReducer);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset, control, setValue } = useForm({
    mode: "onChange"
  });

  const [val, setVal] = useState(false);

  const onSubmit = (data) => {
    dispatch(createUserSettings(data));
  };

  useEffect(() => {
    const fields = ["notifications", "start_page", "default_language"];

    if (isOpen && userSettings) {
      setTimeout(() => {
        if (userSettings && userSettings.notifications) setVal(userSettings.notifications);
        fields.forEach((field) => setValue(field, userSettings[field]));
      }, 200);
    }
  }, [isOpen]);

  const getUserSettingAfterUpdated = () => {
    dispatch({ type: RESET_CREATING_LOGIN_USER_SETTINGS });
    dispatch(getUserSettings());
    closeHandler(!isOpen);
  };

  const cancelUpdating = () => {
    dispatch({ type: RESET_CREATING_LOGIN_USER_SETTINGS });
    dispatch(getUserSettings());
    closeHandler(!isOpen);
  }


  return (
    <ReactModal
      isOpen={ isOpen }
      contentLabel="PrepareOrder"
      className="bg-gray-700 z-50"
      style={ {
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(23, 35, 82, 0.6)",
          zIndex: 9999,
          backdropFilter: "blur(8px)",
        },
        content: {
          position: "absolute",
          top: "50px",
          left: "100px",
          right: "100px",
          bottom: "100px",

          backgroundColor: "rgba(23, 35, 82, 0.0)",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "50px",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        },
      } }
    >
      <div className="flex flex-col  mx-auto py-3 px-10 border max-w-lg rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
        <div className="flex justify-between items-center ">
          <div className="font-MulishBold">
            { t("user-settings").toUpperCase() }
          </div>
          <button
            className="focus:outline-none"
            onClick={ () => {
              closeHandler(!isOpen);
              dispatch({ type: RESET_CREATING_LOGIN_USER_SETTINGS });
              dispatch(getUserSettings());

            } }
          >
            <CloseIcon />
          </button>
        </div>


        <form autoComplete="off" onSubmit={ handleSubmit(onSubmit) }>
          <div className="flex flex-col my-6">
            <div className="flex flex-row items-center justify-start">
              <div className="font-MulishBold mr-1 ">{ t("user-notifications-e-mail-myportal") }</div>               
              <input ref={ register } id="inline-radio" type="checkbox" onChange={ e => {
                setVal(!val);
              } }
                checked={ val } name="notifications" className="ml-4 w-4 h-4 text-gfCoral bg-gfPeriwinkle border-gfCoral" />
            </div>

            {/* <Controller
              control={ control }
              defaultValue={ selectedLanguage.value }
              name="default_language"

              render={ ({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ ref }

                  options={ languages }
                  value={ languages.find(c => c.value === value) }
                  onChange={ val => onChange(val.value) }
                />
              ) }
            /> */}

            {/* {
              routes.map((route) => (
                <div>
                  <label htmlFor={ route.guard_name }>
                    <input
                      ref={ register }
                      type="radio"
                      value={ route.guard_name }
                      id={ route.guard_name }
                      name="start_page"
                    />
                    { t(route.guard_name) }
                  </label>
                </div>
              ))
            } */}

            <div className="flex flex-row justify-evenly items-center mt-10">
              { !successUserSettings && <button
                type="submit"
                className="w-32 flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
              >
                { creatingUserSettings && <ButtonLoading color={ "#4A5568" } /> }
                { !creatingUserSettings && !failUserSettings && <span>{ t("Update") }</span> }
                { failUserSettings && <span>{ t("Try-Again") }</span> }
              </button> }
              { successUserSettings && <button
                type="button" onClick={ getUserSettingAfterUpdated }
                className="w-32 flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfPeriwinkle focus:outline-none hover:bg-opacity-75  cursor-pointer"
              >
                { t("OK") }
              </button> }

              {failUserSettings && <button onClick={cancelUpdating} className="w-32 flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfPeriwinkle focus:outline-none hover:bg-opacity-75  cursor-pointer">{ t("Cancel") }</button> }

            </div>



          </div>

          <div className="flex flex-col my-5 text-sm justify-center">
            { successUserSettings && <SuccessAlert title="Success" message={ t("user-settings-updated") + "!" } /> }
            { failUserSettings && <FailAlert message={ failUserSettings.message } /> }
            { validationErrors && (
              <div className="py-2">
                <ValidationErrors { ...validationErrors } />
              </div>
            ) }
          </div>


        </form>
      </div>
    </ReactModal>
  );
}
