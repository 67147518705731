/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Userpilot } from "userpilot";
import { fetchCountries } from "../../../../actions/CountriesActions";
import { deleteDeliveryAddress, fetchDeliveryAddresses, setDefaultAddress } from "../../../../actions/DeliveryAddressActions";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import FailAlert from "../../../Alert/FailAlert";
import MessageAlert from "../../../Alert/MessageAlert";
import Container from "../../../UIComponents/Container";
import Table from "../../../UIComponents/Table";
import DeleteConfirmation from "./DeleteConfirmation";
import NewAddress from "./NewAddress";
import SetDefaultConfirmation from "./SetDefaultConfirmation";
import UpdateConfirmation from "./UpdateConfirmation";
import UpdateDeliveryAddress from "./UpdateDeliveryAddress";

export default function DeliveryAddress() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { deliveryAddresses, loadingDeliveryAddresses, errorDeliveryAddresses, updatingDeliveryAddresses } = useSelector((state) => state.deliveryAddress);

    const [createNewVisibility, setCreateNewVisibility] = useState(false);
    const [defaultConfirmation, setDefaultConfirmation] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [updateConfirmation, setUpdateConfirmation] = useState(false);
    const [updateVisibility, setUpdateVisibility] = useState(false);
    const [defaultData, setDefaultData] = useState({});
    const [confirmResult, setConfirmResult] = useState(false);

    useEffect(() => {
        Userpilot.reload();
        dispatch(fetchDeliveryAddresses());
        dispatch(fetchCountries());

        window.addEventListener(TAG_CHANGED, () => {
            dispatch(fetchDeliveryAddresses());
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch(fetchDeliveryAddresses());
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    const setAsDefaultAddress = (addressID, addressActive, addressDefault) => {
        const temp = {
            id: addressID,
            active: addressActive,
            default: addressDefault,
        };
        setDefaultData(temp);
        setDefaultConfirmation(true);
    };

    const createDeliveryAddress = () => {
        setCreateNewVisibility(!createNewVisibility);
    };

    const createNewClose = () => {
        setCreateNewVisibility(!createNewVisibility);
    };

    const confirmationCloseHandler = () => {
        setDefaultConfirmation(false);
    };

    const confirmationYesHandler = () => {
        dispatch(setDefaultAddress(defaultData.id, defaultData.active, defaultData.default)).then(
            (success) => {
                setDefaultConfirmation(false);
            },
            (error) => {
                setConfirmResult(true);
                setDefaultConfirmation(false);
            }
        );
    };

    const deleteAddress = (address) => {
        setDefaultData(address);
        setDeleteConfirmation(true);
    };

    const deleteYesOption = () => {
        dispatch(deleteDeliveryAddress(defaultData.id))
            .then((success) => {
                setDefaultData({});
                setDeleteConfirmation(false);
            })
            .catch((error) => {});
    };

    const deleteNoOption = () => {
        setDeleteConfirmation(false);
    };

    const editAddress = (address) => {
        setDefaultData(address);
        setUpdateConfirmation(true);
    };

    const updateYesOPtion = () => {
        setUpdateVisibility(true);
        setUpdateConfirmation(false);
    };

    const updateNoOption = () => {
        setDefaultData({});
        setUpdateConfirmation(false);
    };

    const updateViewCloseHandler = () => {
        setUpdateVisibility(false);
        setDefaultData({});
    };

    return (
        <Container title={t("delivery-addresses")} loading={loadingDeliveryAddresses || updatingDeliveryAddresses}>
            {deliveryAddresses && (
                <div className="mb-6 flex justify-start w-full">
                    <button onClick={createDeliveryAddress} className="border focus:outline-none py-2 px-8 font-MulishBold bg-gfCoral hover:bg-opacity-75 rounded">
                        {t("Create-New-Delivery-Address")}
                    </button>
                </div>
            )}

            {deliveryAddresses && deliveryAddresses.length === 0 && <MessageAlert message={"No records found"} />}
            {errorDeliveryAddresses && <FailAlert message={errorDeliveryAddresses.message} />}
            {confirmResult && <FailAlert message={"Error"} />}

            {deliveryAddresses && deliveryAddresses.length !== 0 && (
                <Table>
                    <thead className="bg-gfGrey font-MulishBlack text-sm">
                        <tr>
                            <th scope="col" className=" px-6 py-3  text-left   uppercase tracking-wider">
                                {t("Address")}
                            </th>
                            <th scope="col" className="px-2 py-3 text-left   uppercase tracking-wider">
                                {t("Contact-person")}
                            </th>
                            <th scope="col" className="px-2 py-3  text-center   uppercase tracking-wider">
                                {t("Phone-number")}
                            </th>
                            <th scope="col" className="px-2 py-3  text-center   uppercase tracking-wider">
                                {t("Default")}
                            </th>
                            <th scope="col" className="px-2 py-3  text-center   uppercase tracking-wider">
                                {t("Action")}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                        {deliveryAddresses &&
                            deliveryAddresses.map((address) => (
                                <tr className={`${address.default ? "font-semibold" : "font-normal"}`} key={address.id}>
                                    <td className="px-6 py-3">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row">
                                                <span>{`${address.delivery_name},`}&nbsp;</span>
                                                {address.pobox !== "" && <span>{`${address.pobox},`}&nbsp;</span>}
                                                {address.street !== "" && <span>{`${address.street}`}</span>}
                                            </div>
                                            <div className="flex flex-row">
                                                {address.zip && <span>{`${address.zip} - `}&nbsp;</span>}
                                                {address.city !== "" && <span>{`${address.city},`}&nbsp;</span>}
                                                {address.country_code !== "" && <span>{`${address.country_code}`}</span>}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-2 py-4 ">
                                        <div className="flex flex-col">
                                            <span>{address.contact_name}</span>
                                            <span>{address.contact_email}</span>
                                        </div>
                                    </td>
                                    <td className="px-2 py-4">
                                        <div className="flex justify-center items-center">{address.contact_phone}</div>
                                    </td>
                                    <td className="px-2 py-4">
                                        <div className="flex justify-center items-center">
                                            {(!address.active || address.active) && !address.default && (
                                                <button
                                                    onClick={() => setAsDefaultAddress(address.id, address.active, true)}
                                                    className=" text-gfDarkBlue bg-gfPeriwinkle py-2 px-4 font-MulishBold rounded focus:outline-none hover:underline"
                                                >
                                                    {t("Set")}
                                                </button>
                                            )}
                                            {address.active && address.default && <span>{t("Active")}</span>}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 flex justify-evenly">
                                        <button onClick={() => editAddress(address)} className="rounded bg-gfLightBlue text-white py-2 px-4 font-MulishBold focus:outline-none hover:underline ">
                                            Modify
                                        </button>
                                        <button onClick={() => deleteAddress(address)} className="rounded bg-gfCoral py-2 px-4 font-MulishBold focus:outline-none hover:underline ">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            )}
            <NewAddress isOpen={createNewVisibility} closeHandler={createNewClose} />
            <SetDefaultConfirmation result={confirmResult} isOpen={defaultConfirmation} closeHandler={confirmationCloseHandler} yesHandler={confirmationYesHandler} />
            <DeleteConfirmation isDefault={defaultData.default} yesOption={deleteYesOption} noOption={deleteNoOption} isOpen={deleteConfirmation} />
            <UpdateConfirmation isOpen={updateConfirmation} yesOption={updateYesOPtion} noOption={updateNoOption} address={defaultData} />
            <UpdateDeliveryAddress isOpen={updateVisibility} addressData={defaultData} closeHandler={updateViewCloseHandler} />
        </Container>
    );
}
