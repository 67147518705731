/* eslint-disable import/no-anonymous-default-export */

import {
    RESET_SHOP_LIST,
    CHANGING_SHOP_STATUS,
    ERROR_SHOP_LIST,
    ERROR_SHOP_LIST_COUNT,
    FAIL_SHOP_STATUS_CHANGE,
    LOADING_SHOP_LIST,
    LOADING_SHOP_LIST_COUNT,
    RECEIVED_SHOP_LIST,
    RECEIVED_SHOP_LIST_COUNT,
    SUCCESS_SHOP_STATUS_CHANGE,
    LIST_OF_SHOP_OPERATION_ID,
} from "../actions/ShopListActions";
import { randomString } from "../utils/Helper";

const initialState = {
    loadingShopList: false,
    shopList: null,
    errorShopList: null,
    shopListMeta: null,

    loadingShopListCount: false,
    receivedShopListCount: null,
    errorShopListCount: null,

    changingShopStatus: false,
    successShopStatusChanged: null,
    failShopStatusChanged: null,

    changeShopStatusOpID: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_SHOP_LIST:
            return {
                ...state,
                loadingShopList: true,
                errorShopList: null,
            };

        case RECEIVED_SHOP_LIST:
            return {
                ...state,
                loadingShopList: false,
                errorShopList: null,
                shopList: action.payload.data,
                shopListMeta: action.payload.meta,
            };

        case ERROR_SHOP_LIST:
            return {
                ...state,
                loadingShopList: false,
                errorShopList: action.payload,
                shopList: null,
            };

        case LOADING_SHOP_LIST_COUNT:
            return {
                ...state,
                loadingShopListCount: true,
                errorShopListCount: null,
            };

        case RECEIVED_SHOP_LIST_COUNT:
            return {
                ...state,
                loadingShopListCount: false,
                receivedShopListCount: action.payload,
                errorShopListCount: null,
            };

        case ERROR_SHOP_LIST_COUNT:
            return {
                ...state,
                loadingShopListCount: false,
                receivedShopListCount: null,
                errorShopListCount: action.payload,
            };

        case CHANGING_SHOP_STATUS:
            return {
                ...state,
                changingShopStatus: true,
                successShopStatusChanged: null,
                failShopStatusChanged: null,
            };

        case SUCCESS_SHOP_STATUS_CHANGE:
            return {
                ...state,
                changingShopStatus: false,
                successShopStatusChanged: action.payload,
                failShopStatusChanged: null,
            };

        case FAIL_SHOP_STATUS_CHANGE:
            return {
                ...state,
                changingShopStatus: false,
                successShopStatusChanged: null,
                failShopStatusChanged: action.payload,
            };

        case RESET_SHOP_LIST:
            return {
                ...state,
                ...initialState,
            };

        case LIST_OF_SHOP_OPERATION_ID:
            return {
                ...state,
                changeShopStatusOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
            };

        default:
            return state;
    }
}
