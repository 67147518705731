import React from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import DropdownIndicator from "./Components/DropdownIndicator";
import LoadingIndicator from "./Components/LoadingIndicator";
import { SELECTED_TAG, TAG_CHANGED } from "../../actions/TagActions";
import { getTerminals } from "../../actions/TerminalActions";
import { GF_CORAL, GF_DARK_BLUE, GF_PERIWINKLE } from "../../utils/Constants";
import { getLoggedInUserTagPermissions } from "../../actions/AuthActions";
import { useHistory } from "react-router-dom";

export default function TagListDropdown() {
    const dispatch = useDispatch();
    const { selectedTag, loadingTagList, receivedTagList } = useSelector((state) => state.tag);
    const { id } = useSelector((state) => state.auth.user);
    const history = useHistory();

    const customStyles = (value) => ({
        placeholder: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
        }),
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "40px",
            paddingBottom: "2px",
            color: GF_DARK_BLUE,
            cursor: "pointer",
            minWidth: "230px",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
                borderColor: GF_PERIWINKLE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: "#F7F7F8",
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && state.data.id === value.id ? GF_DARK_BLUE : state.isFocused ? GF_CORAL : "white",
            padding: 8,
            fontSize: "16px",
            color: state.isSelected && state.data.id === value.id ? "#FFF" : GF_DARK_BLUE,
            "&:hover": {
                background: "#FF666E",
                color: "#FFF",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
            fontSize: "18px",
        }),
    });

    const onOptionSelect = (event) => {
        dispatch({ type: SELECTED_TAG, payload: event });
        dispatch(getLoggedInUserTagPermissions(id, history));
        dispatch(getTerminals());
        document.body.dispatchEvent(new Event(TAG_CHANGED, { bubbles: false, cancelable: true }));
    };

    return (
        <Select
            className="w-64 h-10"
            value={selectedTag}
            isDisabled={loadingTagList || receivedTagList === null}
            placeholder={loadingTagList ? "Loading Tags..." : "Select a Tag"}
            onChange={onOptionSelect}
            styles={customStyles(selectedTag)}
            options={receivedTagList}
            isSearchable
            getOptionLabel={(option) => `${option.name}`}
            isLoading={loadingTagList}
            components={{
                IndicatorSeparator: null,
                DropdownIndicator: DropdownIndicator,
                LoadingIndicator: LoadingIndicator,
            }}
        />
    );
}
