import { issuingAPI } from "../services/Axios";

export const PREFIX = "@tokenizationReport/";

export const LOADING_TOKENIZATION_REPORT = `${PREFIX}LOADING_TOKENIZATION_REPORT`;
export const RECEIVED_TOKENIZATION_REPORT = `${PREFIX}RECEIVED_TOKENIZATION_REPORT`;
export const ERROR_TOKENIZATION_REPORT = `${PREFIX}ERROR_TOKENIZATION_REPORT`;
export const RESET_TOKENIZATION_REPORT = `${PREFIX}RESET_TOKENIZATION_REPORT`;
export const POPULATED_LIST = `${PREFIX}POPULATED_LIST`;

// https://portalapi.dev.giftify.me/tag/{tagId}/tokenized

export const getTokenizationReport = (fromDate, toDate, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_TOKENIZATION_REPORT });

    const { tagID } = getState().tag;

    const params = {
        from_date: fromDate,
        to_date: toDate,
        page: page,
        per_page: process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    };

    return issuingAPI
        .get(`tag/${tagID}/tokenized`, { params })
        .then((success) => {
            dispatch({ type: RECEIVED_TOKENIZATION_REPORT, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: ERROR_TOKENIZATION_REPORT, payload: error });
            return error;
        });
};