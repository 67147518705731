import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCardStock } from "../../../../actions/CardStockActions";
import Container from "../../../UIComponents/Container";

export default function CardStock() {
    const { t } = useTranslation();
    const [view, setView] = useState("1");
    const dispatch = useDispatch();

    const loadingSalesSummary = false;

    const viewChangeHandler = (view) => {
        setView(view);
    };

    const { receivedCardStock } = useSelector((state) => state.cardStock);

    useEffect(() => {
        dispatch(getCardStock());

        // window.addEventListener(TAG_CHANGED, () => {});

        // window.addEventListener(TERMINAL_CHANGED, () => {});

        // return () => {
        //     window.removeEventListener(TAG_CHANGED, () => {});
        //     window.removeEventListener(TERMINAL_CHANGED, () => {});
        // };
    }, []);

    const [chartType, setChartType] = useState("1");

    return (
        <Container title={t("card-stock")}>
            {receivedCardStock && (
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex flex-col">
                            <div className="w-full text-center font-MulishBold bg-gfDarkBlue text-white py-2 flex items-center justify-center">
                                {t("General-Information").toUpperCase()}
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("Brand-Name")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                    {receivedCardStock.card_brand.name}
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("Brand-ID")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                    {receivedCardStock.card_brand.id}
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("Stock-Validity")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                    {`${
                                        receivedCardStock.card_brand.stock_validity !== null
                                            ? receivedCardStock.card_brand.stock_validity + "Y"
                                            : "-"
                                    }`}
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("Brand-Validity")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">{`${receivedCardStock.card_brand.validity_period}`}</div>
                            </div>
                        </div>
                        <div>
                            <div className="w-full text-center font-MulishBold bg-gfDarkBlue text-white py-2 flex items-center justify-center">
                                {t("Cards-to-Activate-Before-Expiry").toUpperCase()}
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("90-days-or-less")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                    {receivedCardStock.stock.valid_for_three_months.count}
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("Between-90-and-180-days")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                    {receivedCardStock.stock.valid_for_six_months.count}
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("180-days-or-more")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                    {receivedCardStock.stock.valid_for_twelve_months.count}
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                    {t("Total-Valid-Stock")}
                                </div>
                                <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                    {receivedCardStock.stock.valid_for_today.count}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="w-full text-center font-MulishBold bg-gfDarkBlue text-white py-2 flex items-center justify-center">
                                    {t("Past-Sales").toUpperCase()}
                                </div>
                                <div className="flex flex-row">
                                    <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                        {t("Last-90-Days")}
                                    </div>
                                    <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                        {receivedCardStock.stock.card_sold_last_three_months.count}
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                        {t("Last-365-Days")}
                                    </div>
                                    <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                        {receivedCardStock.stock.card_sold_last_year.count}
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="py-2 w-1/2 px-4 text-center bg-gfPeriwinkle font-MulishBold border-b border-blue-900">
                                        {t("Daily-Average")}
                                    </div>
                                    <div className="py-2 px-4 w-1/2 text-center bg-gfPeriwinkle bg-opacity-50 text-gfDarkBlue border-b border-blue-900">
                                        {Math.ceil(receivedCardStock.stock.card_sold_last_year.count / 356)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-4 mt-16">
                        <div className="h-10 mb-5 sm:mb-5 md:mb-0 " id="switch-field">
                            <input
                                type="radio"
                                id="radio-one-card-type1"
                                name="switch-one-card-type1"
                                value="1"
                                checked={chartType === "1"}
                                onChange={(e) => {
                                    setChartType(e.target.value);
                                }}
                            />
                            <label htmlFor="radio-one-card-type1" className=" ">
                                {`${t("Card-Valid-for")} ${t("Today")}`}
                            </label>
                            <input
                                type="radio"
                                id="radio-two-card-type2"
                                name="switch-two-card-type2"
                                value="3"
                                checked={chartType === "3"}
                                onChange={(e) => {
                                    setChartType(e.target.value);
                                }}
                            />
                            <label htmlFor="radio-two-card-type2" className=" ">
                                {`${t("Card-Valid-for")} ${t("Three-Months")}`}
                            </label>
                            <input
                                type="radio"
                                id="radio-two-card-type3"
                                name="switch-two-card-type3"
                                value="6"
                                checked={chartType === "6"}
                                onChange={(e) => {
                                    setChartType(e.target.value);
                                }}
                            />
                            <label htmlFor="radio-two-card-type3" className=" ">
                                {`${t("Card-Valid-for")} ${t("Six-Months")}`}
                            </label>
                            <input
                                type="radio"
                                id="radio-two-card-type4"
                                name="switch-two-card-type4"
                                value="12"
                                checked={chartType === "12"}
                                onChange={(e) => {
                                    setChartType(e.target.value);
                                }}
                            />
                            <label htmlFor="radio-two-card-type4" className=" ">
                            {`${t("Card-Valid-for")} ${t("Year")}`}
                            </label>
                            <input
                                type="radio"
                                id="radio-two-card-type5"
                                name="switch-two-card-type5"
                                value="0"
                                checked={chartType === "0"}
                                onChange={(e) => {
                                    setChartType(e.target.value);
                                }}
                            />
                            <label htmlFor="radio-two-card-type5" className=" ">
                                {t("Expired-Cards")}
                            </label>
                        </div>
                    </div>
                    {/* <div className="my-5">
                        {chartType === "1" && ( <CardValidToday /> )}
                        {chartType === "3" && ( <CardValidThreeMonths /> )}
                        {chartType === "6" && ( <CardValidSixMonths /> )}
                        {chartType === "12" && ( <CardValidYear /> )}
                        {chartType === "0" && ( <ExpireCards /> )}
                    </div> */}
                </div>
            )}
        </Container>
    );
}
