/* eslint-disable react-hooks/exhaustive-deps */
import format from "date-fns/format";
import React, { forwardRef, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import FailAlert from "../../../Alert/FailAlert";
import MessageAlert from "../../../Alert/MessageAlert";
import CardInfoWidget from "../../../CardInfoWidget";
import Container from "../../../UIComponents/Container";
import { GF_DARK_BLUE } from "../../../../utils/Constants";
import Table from "../../../UIComponents/Table";
import { Link } from "react-router-dom";
import { getCalanderLocale } from "../../../../utils/CalanderLocale";
import { Userpilot } from "userpilot";
import { getTokenizationReport, RESET_TOKENIZATION_REPORT } from "../../../../actions/TokenizationActions";
import TokanizationPrintPreview from "./TokanizationPrintPreview";

export default function TokenizationReport() {
    const {
        loadingTokenizationReport,
        receivingTokenizationReport,
        errorTokenizationReport,
        tokenizationReportSummary,
    } = useSelector((state) => state.tokenizationReport);

    const { selectedLanguage } = useSelector((state) => state.language);

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_TOKENIZATION_REPORT });

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_TOKENIZATION_REPORT });
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_TOKENIZATION_REPORT });
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    getCalanderLocale(selectedLanguage);

    const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly={true}
            className="border cursor-pointer text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 focus:outline-none"
        />
    ));

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [chooseDate, setChooseDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [printPreviewVisibility, setPrintPreviewVisibility] = useState(false);
    const [printDetails, setPrintDetails] = useState({});
    const [page, setPage] = useState(1);

    const fromDatePickHandler = (date) => {
        setChooseDate(date);
    };

    const toDatePickHandler = (date) => {
        setEndDate(date);
    };

    const getChartHandler = () => {
        let from = format(chooseDate, "yyyy-MM-dd");
        let to;
        if (endDate !== null) {
            to = format(endDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }
        dispatch(getTokenizationReport(from, to, page));
    };

    const handlePageClick = (event) => {
        let from = format(chooseDate, "yyyy-MM-dd");
        let to;
        if (endDate !== null) {
            to = format(endDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }
        dispatch(getTokenizationReport(from, to, event.selected + 1));
    };

    const printPreview = (details) => {
        setPrintDetails((prev) => Object.assign(prev, details));
        setPrintPreviewVisibility(!printPreviewVisibility);
    };

    const printPreviewClose = () => {
        setPrintPreviewVisibility(!printPreviewVisibility);
    };

    return (
        <Container title={t("tokenization-report")}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-5 items-end">
                <div className="flex flex-col">
                    <div className="flex items-center   py-1">{t("Choose-the-date")}:</div>{" "}
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingTokenizationReport}
                        dateFormat="dd/MM/yyyy"
                        customInput={<DPCustomInput />}
                        selected={chooseDate}
                        onChange={(date) => fromDatePickHandler(date)}
                        locale={selectedLanguage.value}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="flex items-center   py-1">{t("End-date") + "(" + t("Optional") + ")"}</div>
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingTokenizationReport}
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date) => toDatePickHandler(date)}
                        customInput={<DPCustomInput />}
                        locale={selectedLanguage.value}
                    />
                </div>

                <button
                    disabled={loadingTokenizationReport}
                    onClick={getChartHandler}
                    className="h-10 py-2 border flex flex-row items-center justify-center rounded  hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none"
                >
                    {loadingTokenizationReport && <ButtonLoading color={GF_DARK_BLUE} />}
                    {t("Go")}!
                </button>
            </div>

            {((receivingTokenizationReport && receivingTokenizationReport.length === 0) || errorTokenizationReport) && (
                <div className="mt-10">
                    {receivingTokenizationReport && receivingTokenizationReport.length === 0 && (
                        <MessageAlert message={t("There-are-no-information-to-display-for-this-date")} />
                    )}
                    {errorTokenizationReport && <FailAlert message={errorTokenizationReport.message} />}
                </div>
            )}

            {receivingTokenizationReport && receivingTokenizationReport.length !== 0 && (
                <div className="flex flex-col mt-20 space-y-5">
                    <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
                        <CardInfoWidget
                            bottomLabel={t("Tokenized-Amount")}
                            isCurrency
                            value={tokenizationReportSummary.total_fees}
                            labelColor={"text-gfDarkBlue"}
                            valueColor={"text-gfDarkBlue"}
                        />
                        <CardInfoWidget
                            bottomLabel={t("Tokenized-Cards")}
                            isCurrency={false}
                            value={tokenizationReportSummary.total_entries}
                            labelColor={"text-gfDarkBlue"}
                            valueColor={"text-gfDarkBlue"}
                        />
                    </div>

                    <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
                        {receivingTokenizationReport.length > process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                            <div>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={
                                        receivingTokenizationReport.length /
                                        process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                                    }
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                    </div>

                    <Table>
                        <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
                            <tr>
                                <th scope="col" className="3/12 px-4 py-3  text-center  uppercase tracking-wider">
                                    {t("Date")}
                                </th>
                                <th scope="col" className="1/12 px-2 py-3  text-center  uppercase tracking-wider">
                                    {t("User")}
                                </th>
                                <th scope="col" className="3/12 px-2 py-3  text-center  uppercase tracking-wider">
                                    {t("Debited-card")}
                                </th>                                
                                <th scope="col" className="3/12 py-3  text-center  uppercase tracking-wider">
                                    {t("Credited-card")}
                                </th>
                                <th scope="col" className="1/12 py-3  text-center  uppercase tracking-wider">
                                    {t("Actions")}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gfPeriwinkle">
                            {receivingTokenizationReport &&
                                receivingTokenizationReport.length !== 0 &&
                                receivingTokenizationReport.map((tr) => (
                                    <tr key={tr.ticket_number}>
                                        <td className="px-4 py-4">
                                            <div className="flex justify-center items-center">
                                                {tr.transaction_date_time}
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className="flex justify-center items-center">{`${tr.user.first_name} ${tr.user.last_name}`}</div>
                                        </td>
                                        <td className="px-2 py-4">
                                            {tr.entries.map((en) => {
                                                if (en.is_unload)
                                                    return (
                                                        <div
                                                            key={en.token}
                                                            className="flex justify-center items-center space-x-2"
                                                        >
                                                            <Link
                                                                className="underline"
                                                                to={`/gift-cards/card-information?token=${en.token}`}
                                                            >
                                                                {en.token}
                                                            </Link>
                                                            <div className="flex flex-row font-MulishBold  text-gfLightBlue ">
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={en.amount}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span className="ml-1 ">{`${String(
                                                                    tr.currency
                                                                ).toUpperCase()}`}</span>
                                                            </div>
                                                        </div>
                                                    );
                                            })}
                                        </td>                                       
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {tr.entries.map((en) => {
                                                if (!en.is_unload)
                                                    return (
                                                        <div
                                                            key={en.token}
                                                            className="flex justify-center items-center space-x-2"
                                                        >
                                                            <Link
                                                                className="underline"
                                                                to={`/gift-cards/card-information?token=${en.token}`}
                                                            >
                                                                {en.token}
                                                            </Link>
                                                            <div className="flex flex-row font-MulishBold  text-gfLightBlue ">
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={en.amount}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span className="ml-1 ">{`${String(
                                                                    tr.currency
                                                                ).toUpperCase()}`}</span>
                                                            </div>
                                                        </div>
                                                    );
                                            })}
                                        </td>
                                        <td className="px-2 py-4 text-sm font-medium">
                                            <div className="flex justify-center items-center">
                                                <button
                                                    onClick={() => {
                                                        printPreview(tr);
                                                    }}
                                                    className="text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                                >
                                                    {t("Print-ticket")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            )}

            <TokanizationPrintPreview
                modalVisibility={printPreviewVisibility}
                closeHandler={printPreviewClose}
                transferDetails={printDetails}
            />
        </Container>
    );
}
