import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { checkLoggedInUserPermissions } from "../utils/Helper";
import FailAlert from "./Alert/FailAlert";
import { CLEAR_LOCAL_STORAGE } from "../actions/AuthActions";

export default function Welcome () {
    const { authenticationError, errorAccessToken, errorUser, isAuthenticated, receivedLoggedInUserTagPermissions } =
        useSelector((state) => state.auth);
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const i18Lan = localStorage.getItem("i18nextLng");
    const path = localStorage.getItem("path");
    let lan;

    if (i18Lan) {
        lan = i18Lan;
    } else {
        lan = "en";
    }

    const handleLogin = () => {
        dispatch({ type: CLEAR_LOCAL_STORAGE });
        window.location.assign(`${process.env.REACT_APP_AUTH_SERVER_PATH}`
        );
    };

    return (
        <div className="py-32 max-w-md mx-auto">
            {/* {isAuthenticated &&
                receivedLoggedInUserTagPermissions !== null &&
                receivedLoggedInUserTagPermissions.length !== 0 &&
                checkLoggedInUserPermissions("load-card") && <Redirect push to={`/card/load-card?lan=${lan}`} />} */}

            { (isAuthenticated &&
                receivedLoggedInUserTagPermissions !== null &&
                receivedLoggedInUserTagPermissions.length !== 0 &&
                checkLoggedInUserPermissions("load-card") && path !== null) ? (
                <Redirect push to={ `${path}?lan=${lan}` } />
            ) : (isAuthenticated &&
                receivedLoggedInUserTagPermissions !== null &&
                receivedLoggedInUserTagPermissions.length !== 0 &&
                checkLoggedInUserPermissions("load-card") && path === null) ? (
                <Redirect push to={ `/card/load-card?lan=${lan}` } />
            ) : <Redirect push to="/" /> }

            {/* {isAuthenticated &&
                receivedLoggedInUserTagPermissions !== null &&
                receivedLoggedInUserTagPermissions.length !== 0 &&
                checkAdminPermissions("admin", receivedLoggedInUserTagPermissions) &&
                checkAdminPermissions("admin-user-management", receivedLoggedInUserTagPermissions) && <Redirect push to="/administration" />} */}

            <div className="text-gfDarkBlue flex justify-center items-center text-4xl">{ t("Welcome-to-giftify") }</div>

            { authenticationError && <FailAlert message={ authenticationError.message } /> }
            { errorAccessToken && <FailAlert message={ errorAccessToken.message } /> }
            { errorUser && <FailAlert message={ errorUser.message } /> }

            { authenticationError && <div className="flex flex-row justify-center items-center my-5">
                <button
                    onClick={ () => handleLogin() }
                    className="bg-gfPeriwinkle border border-gfDarkBlue hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                    type="button"
                >
                    { t("please-login-again") }
                </button>
            </div> }

            { errorAccessToken && <div className="flex flex-row justify-center items-center my-5">
                <button
                    onClick={ () => handleLogin() }
                    className="bg-gfPeriwinkle border border-gfDarkBlue hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                    type="button"
                >
                    { t("please-login-again") }
                </button>
            </div> }

            { errorUser && <div className="flex flex-row justify-center items-center my-5">
                <button
                    onClick={ () => handleLogin() }
                    className="bg-gfPeriwinkle border border-gfDarkBlue hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                    type="button"
                >
                    { t("please-login-again") }
                </button>
            </div> }


            { isAuthenticated &&
                receivedLoggedInUserTagPermissions !== null &&
                receivedLoggedInUserTagPermissions.length === 0 && <Redirect push to="/" /> }
            { isAuthenticated &&
                receivedLoggedInUserTagPermissions !== null &&
                receivedLoggedInUserTagPermissions.length === 0 && (
                    <FailAlert message={ "Can not find any permissions assign to you" } />
                ) }
        </div>
    );
}
