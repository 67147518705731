import React from "react";

export default function ValidationErrors (props) {

    const errorObj = {
                errors: {
                    "tokens.0.id": ["The selected id is invalid", "The selected id is invalid error 2", "The selected id is invalid error 3"],
                    "tokens.0.token": ["The card cannot be loaded more than 5 time(s) code:400", "error 2", "error 3", "error 4"]
                },
                message: "The given data was invalid."
    }

    const valuesErrors = Object.values(props.errors);

    return (
        <div className="rounded bg-red-100 border border-red-700 py-2 px-4 flex flex-col justify-start items-start">
            <div className="font-MulishBold text-red-700 border-b border-700 w-full mb-3 uppercase">{ props.message }</div>
            <div className="flex flex-col text-base text-red-700">
                <div>
                    { valuesErrors && valuesErrors.map((item, index) => <ul className=" ml-3 list-disc float-left" key={ index }>{ item.map((e, i) => (<li key={ i }>{e}</li>)) }</ul>) }
                </div>
            </div>
        </div>
    );
}
