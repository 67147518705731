import format from "date-fns/format";
import React, { useRef } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import CloseIcon from "../../../assets/svg/CloseIcon";

ReactModal.setAppElement("#root");

export default function PrintEntireReport({ modalVisibility, closeHandler, fromDate, toDate }) {
    const { t } = useTranslation();
    const componentRef = useRef();
    const { selectedTag, tagCurrency } = useSelector((state) => state.tag);
    const { channelSummary, receivedReportByChannel } = useSelector((state) => state.reportByChannel);
    const { user } = useSelector((state) => state.auth);

    const printReceipt = useReactToPrint({
        content: () => componentRef.current,
    });

    const channelList = (channel) => {
        return (
            <div className="mb-2">
                <div className="flex flex-col justify-start items-start">
                    <span>{channel.channel_name}</span>
                </div>

                <div className="flex flex-row justify-start items-center">
                    <span style={{ fontWeight: "bold" }}>{`${t("Transactios")}: `}</span>
                    <span style={{ marginLeft: "4px" }}>{channel.operations}</span>
                </div>

                <div className="flex flex-row justify-start items-center">
                    <span style={{ fontWeight: "bold" }}>{`${t("Total")}: `}</span>
                    <div style={{ marginLeft: "4px" }} className="flex flex-row">
                        <div className="flex justify-center">
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={channel.total}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                            <span className="ml-1">{` ${selectedTag.currency}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const detailList = (detail) => {
        return (
            <div className="mb-2">
                <div className="flex flex-col justify-start items-start">
                    <span>{detail.reception_date}</span>
                </div>

                <div className="flex flex-col justify-start items-start">
                    <span>{detail.channel_name}</span>
                </div>

                <div className="flex flex-row justify-start items-center">
                    <span style={{ fontWeight: "bold" }}>{`${t("Sequence-number")}: `}</span>
                    <span style={{ marginLeft: "4px" }}>{detail.cmapi_sequence_number}</span>
                </div>

                <div className="flex flex-row justify-start items-center">
                    <span style={{ fontWeight: "bold" }}>{`${t("Card")}: `}</span>
                    <span style={{ marginLeft: "4px" }}>{detail.cmapi_public_token}</span>
                </div>

                <div className="flex flex-row justify-start items-center">
                    <span style={{ fontWeight: "bold" }}>{`${t("Amount")}: `}</span>
                    <div style={{ marginLeft: "4px" }} className="flex flex-row">
                        <div className="flex justify-center">
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={detail.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                            <span className="ml-1">{` ${selectedTag.currency}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <ReactModal
            isOpen={modalVisibility}
            contentLabel="Minimal Modal Example"
            className="bg-gray-700 z-50"
            style={{
                overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(23, 35, 82, 0.6)",
                    zIndex: 9999,
                    backdropFilter: "blur(8px)",
                },
                content: {
                    position: "absolute",
                    top: "50px",
                    left: "100px",
                    right: "100px",
                    bottom: "100px",

                    backgroundColor: "rgba(23, 35, 82, 0.0)",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                },
            }}
        >
            <div className="flex flex-col max-w-xl mx-auto bg-gray-100">
                <div className="flex justify-between p-5">
                    <button
                        className="focus:outline-none bg-gfCoral text-base rounded font-MulishBold hover:bg-opacity-75 px-4 py-1"
                        onClick={printReceipt}
                    >
                        {t("Print")}
                    </button>
                    <button onClick={() => closeHandler(!modalVisibility)}>
                        <CloseIcon />
                    </button>
                </div>
                <div
                    ref={componentRef}
                    style={{
                        maxWidth: "165px",
                        margin: "auto",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "10px",
                        paddingRight: "20px",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px" }}>
                        <img
                            style={{ maxWidth: "100px" }}
                            src={`${process.env.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
                            alt="logo"
                        />
                    </div>

                    <div
                        style={{
                            textAlign: "left",
                            fontSize: "16px",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                            fontWeight: "bolder",
                        }}
                    >
                        {selectedTag.name}
                    </div>

                    <div className="flex justify-start items-center mb-4">
                        <span style={{ fontWeight: "bold", fontSize: "12px" }}>{`${format(
                            fromDate,
                            "yyyy/MM/dd"
                        )}  -  ${format(toDate, "yyyy/MM/dd")} `}</span>
                    </div>

                    {user && (
                        <div style={{ display: "flex", paddingTop: "3px", paddingBottom: "3px" }}>
                            <span style={{ fontWeight: "bold" }}>{`${t("User")}: `}</span>
                            <span style={{ marginLeft: "4px" }}>{`${user.first_name} ${user.last_name}`}</span>
                        </div>
                    )}

                    {channelSummary && (
                        <div style={{ display: "flex", paddingTop: "3px", paddingBottom: "3px" }}>
                            <span style={{ fontWeight: "bold" }}>{`${t("Number-of-cards-sold")}: `}</span>
                            <span style={{ marginLeft: "4px" }}>{channelSummary.total_operations}</span>
                        </div>
                    )}

                    {channelSummary && (
                        <div style={{ display: "flex", paddingTop: "3px", paddingBottom: "3px" }}>
                            <span style={{ fontWeight: "bold" }}>{`${t("Total-amount")}: `}</span>
                            <div style={{ marginLeft: "4px" }} className="flex flex-row">
                                <div className="flex justify-center">
                                    <CurrencyFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={channelSummary.total_amount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                    <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {channelSummary && channelSummary.channel_summary.length !== 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                            }}
                        >
                            <div className="flex flex-row">
                                <span
                                    style={{
                                        textAlign: "left",
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        fontWeight: "bolder",
                                        fontSize: "13px",
                                    }}
                                >{`${t("Channels")}`}</span>
                            </div>
                            {channelSummary.channel_summary.map((channel) => channelList(channel))}
                        </div>
                    )}

                    {receivedReportByChannel && receivedReportByChannel.length !== 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                            }}
                        >
                            <div className="flex flex-row">
                                <span
                                    style={{
                                        textAlign: "left",
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        fontWeight: "bolder",
                                        fontSize: "13px",
                                    }}
                                >{`${t("Details")}`}</span>
                            </div>
                            {receivedReportByChannel.map((detail) => detailList(detail))}
                        </div>
                    )}

                    <div
                        style={{ display: "flex", justifyContent: "center", paddingTop: "30px", paddingBottom: "30px" }}
                    >
                        <img
                            style={{ maxWidth: "100px" }}
                            src={`${process.env.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
                            alt="logo"
                        />
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}
