/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ActiveIcon from "../../../../assets/svg/ActiveIcon";
import DeclineIcon from "../../../../assets/svg/DeclineIcon";
import PendingIcon from "../../../../assets/svg/PendingIcon";
import { useDispatch, useSelector } from "react-redux";
import {
    changeShopStatus,
    getListofShopsCSV,
    getShopList,
    getShopListCount,
    RESET_SHOP_LIST,
} from "../../../../actions/ShopListActions";
import SearchIcon from "../../../../assets/svg/SearchIcon";
import ReactPaginate from "react-paginate";
import TerminalsModal from "./TerminalsModal";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import FailAlert from "../../../Alert/FailAlert";
import MessageAlert from "../../../Alert/MessageAlert";
import { useTranslation } from "react-i18next";
import ClearIcon from "../../../../assets/svg/ClearIcon";
import Select from "react-select";
import DropdownIndicator from "../../../Dropdown/Components/DropdownIndicator";
import Container from "../../../UIComponents/Container";
import { GF_CORAL, GF_DARK_BLUE, GF_PERIWINKLE } from "../../../../utils/Constants";
import Table from "../../../UIComponents/Table";
import { Userpilot } from "userpilot";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";

export default function ShopList(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [visibility, setVisibility] = useState(false);
    const [data, setData] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [selectedPage, setSelectedPage] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState({ value: -1, label: t("All") });
    const statusOptions = [
        { value: -1, label: t("All") },
        { value: "accepted", label: t("Accepted") },
        { value: "rejected", label: t("Rejected") },
        { value: "unconfirmed", label: t("Unconfirmed") },
    ];

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_SHOP_LIST });

        dispatch(getShopListCount());

        const params = new URLSearchParams(props.location.search);
        const keyword = params.get("keyword");

        if (keyword) {
            setSelectedStatus({ value: -1, label: t("All") });
            setKeyword(keyword);
            dispatch(getShopList(keyword, -1, selectedPage));
        } else {
            setSelectedStatus({ value: "unconfirmed", label: t("Unconfirmed") });
            dispatch(getShopList(keyword, "unconfirmed", selectedPage));
        }

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_SHOP_LIST });
            setKeyword("");
            setSelectedPage(1);
            setSelectedStatus({ value: "unconfirmed", label: t("Unconfirmed") });
            dispatch(getShopListCount());
            dispatch(getShopList(keyword, selectedStatus.value, selectedPage));
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_SHOP_LIST });
            setKeyword("");
            setSelectedPage(1);
            setSelectedStatus({ value: "unconfirmed", label: t("Unconfirmed") });
            dispatch(getShopListCount());
            dispatch(getShopList(keyword, selectedStatus.value, selectedPage));
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    const {
        loadingShopList,
        shopList,
        errorShopList,
        shopListMeta,

        loadingShopListCount,
        receivedShopListCount,
        errorShopListCount,

        failShopStatusChanged,
    } = useSelector((state) => state.shopList);
    const { loadingCSV, errorCSV } = useSelector((state) => state.commonReducer);

    const shopStatusChangedHandler = (event) => {
        setSelectedStatus(event);
        dispatch(getShopList(keyword, event.value));
    };

    const shopSearchHandler = (event) => {
        event.persist();
        setKeyword(event.target.value);
        dispatch(getShopList(event.target.value, selectedStatus.value));
    };

    const clearShopSearchHandler = () => {
        setKeyword("");
        setSelectedPage(1);
        setSelectedStatus({ value: -1, label: t("All") });
        dispatch(getShopList("", -1, 1));
    };

    const paginationHandler = (event) => {
        setSelectedPage(event.selected + 1);
        dispatch(getShopList(keyword, selectedStatus.value, event.selected + 1));
    };

    const changeShopStatusHandler = (shopID, currentStatus, newStatus) => {
        dispatch(changeShopStatus(shopID, currentStatus, newStatus)).then((success) => {
            dispatch(getShopListCount());
            dispatch(getShopList(keyword, selectedStatus.value, selectedPage));
        });
    };

    const moreTerminals = (shop) => {
        const temp = [];
        shop.terminals.forEach(function (terminal) {
            terminal.devices.forEach(function (device) {
                temp.push(device.serial_number ? device.serial_number : "-");
            });
        });

        setData(temp);
        setVisibility(!visibility);
    };

    const modalClose = () => {
        setData([]);
        setVisibility(!visibility);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "40px",
            paddingBottom: "2px",
            color: GF_DARK_BLUE,
            cursor: "pointer",
            fontWeight: "600",
            maxWidth: "100%",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: GF_PERIWINKLE,
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "8px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? GF_DARK_BLUE : "#FFFFFF",
            color: state.isSelected ? "#FFFFFF" : GF_DARK_BLUE,
            "&:hover": {
                background: GF_CORAL,
                color: "#FFF",
            },
        }),

        menuList: (provided) => ({
            ...provided,
            maxHeight: "100%",
            color: GF_DARK_BLUE,
        }),

        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
        }),
    };

    const getCSV = () => {
        dispatch(getListofShopsCSV(selectedStatus.value));
    };

    return (
        <Container title={t("list-of-shops")} loading={loadingShopList || loadingShopListCount}>
            <div className="flex flex-col w-full mx-auto justify-start space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
                    <div>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                <button className="focus:outline-none focus:shadow-outline">
                                    <SearchIcon />
                                </button>
                            </span>
                            {keyword !== "" && (
                                <button
                                    onClick={clearShopSearchHandler}
                                    className="focus:outline-none focus:shadow-outline"
                                    style={{ position: "absolute", right: "5px", top: "9px" }}
                                >
                                    <ClearIcon />
                                </button>
                            )}
                            <input
                                value={keyword}
                                onChange={shopSearchHandler}
                                className="h-10 text-lg w-full placeholder-gfGrey rounded border border-gfPeriwinkle focus:border-gfPeriwinkle pl-10 focus:outline-none"
                                placeholder={t("Search") + "..."}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                        <span className=" ">{t("Status")}:</span>
                        <Select
                            value={selectedStatus}
                            className="w-full"
                            styles={customStyles}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={shopStatusChangedHandler}
                            placeholder={false}
                            options={statusOptions}
                            isSearchable={false}
                            components={{
                                IndicatorSeparator: null,
                                DropdownIndicator: DropdownIndicator,
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-col">
                    {shopList && shopList.length !== 0 && (
                        <div className="flex flex-col mt-8">
                            <div className="flex flex-col md:flex-row mb-5">
                                {receivedShopListCount &&
                                    receivedShopListCount.map((item) => (
                                        <div key={item.count} className="flex flex-row">
                                            {item.status === "accepted" && (
                                                <div className="flex flex-row w-52 items-center justify-start">
                                                    <div className="flex flex-row items-center">
                                                        <ActiveIcon w={20} h={20} />
                                                        <div className="text-base mx-2 ">
                                                            {t("Accepted").toUpperCase()} :
                                                        </div>
                                                        <div className="text-xl">{item.count}</div>
                                                    </div>
                                                </div>
                                            )}

                                            {item.status === "rejected" && (
                                                <div className="flex flex-row w-52 items-center justify-start">
                                                    <div className="flex flex-row items-center">
                                                        <DeclineIcon w={20} h={20} />
                                                        <div className="text-base mx-2 ">
                                                            {t("Rejected").toUpperCase()} :
                                                        </div>
                                                        <div className="text-xl">{item.count}</div>
                                                    </div>
                                                </div>
                                            )}

                                            {item.status === "unconfirmed" && (
                                                <div className="flex flex-row w-52 items-center justify-start">
                                                    <div className="flex flex-row items-center">
                                                        <PendingIcon w={20} h={20} />
                                                        <div className="text-base mx-2 ">
                                                            {t("Unconfirmed").toUpperCase()} :
                                                        </div>
                                                        <div className="text-xl">{item.count}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                <div className="flex flex-row justify-end items-center w-full">
                                    <button
                                        onClick={getCSV}
                                        className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                                    >
                                        {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                                        {t("Export-as-a-CSV-file")}
                                    </button>
                                </div>
                            </div>

                            {shopListMeta && shopListMeta.total > process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                                <div className="w-full flex flex-row justify-end">
                                    <div>
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={
                                                shopListMeta.total / process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                                            }
                                            marginPagesDisplayed={4}
                                            pageRangeDisplayed={5}
                                            onPageChange={paginationHandler}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {(failShopStatusChanged ||
                        errorShopList ||
                        errorShopListCount ||
                        (shopList && shopList.length === 0)) && (
                        <div className="mx-auto py-10">
                            {failShopStatusChanged && <FailAlert message={failShopStatusChanged.message} />}
                            {errorShopList && <FailAlert message={errorShopList.message} />}
                            {errorShopListCount && <FailAlert message={errorShopListCount.message} />}
                            {shopList && shopList.length === 0 && (
                                <MessageAlert message={t("There-are-no-shops-to-display")} />
                            )}
                        </div>
                    )}

                    {shopList && shopList.length !== 0 && (
                        <Table>
                            <thead className="bg-gfGrey font-MulishBlack text-sm">
                                <tr>
                                    <th scope="col" className="p-3  text-center   uppercase tracking-wider">
                                        {t("Status")}
                                    </th>
                                    <th scope="col" className="p-3  text-center  uppercase tracking-wider">
                                        {t("Alias")}
                                    </th>
                                    <th scope="col" className="p-3  text-center  uppercase tracking-wider">
                                        {t("Shop-name")}
                                    </th>
                                    <th scope="col" className="p-3  text-center  uppercase tracking-wider">
                                        {t("Modification-Date")}
                                    </th>
                                    <th scope="col" className="p-3  text-center   uppercase tracking-wider">
                                        {t("Terminals")}
                                    </th>
                                    <th scope="col" className="p-3  text-center  uppercase tracking-wider">
                                        {t("Actions")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                                {shopList &&
                                    shopList.length !== 0 &&
                                    shopList.map((shop) => (
                                        <tr key={shop.id}>
                                            <td className="px-4 py-4 whitespace-nowrap">
                                                <div className="flex justify-center items-center">
                                                    {shop.card_brands[0].status === "accepted" && (
                                                        <ActiveIcon w={20} h={20} />
                                                    )}
                                                    {shop.card_brands[0].status === "rejected" && (
                                                        <DeclineIcon w={20} h={20} />
                                                    )}
                                                    {shop.card_brands[0].status === "unconfirmed" && (
                                                        <PendingIcon w={20} h={20} />
                                                    )}
                                                </div>
                                            </td>

                                            <td className="px-2 py-4 whitespace-nowrap text-center">
                                                {shop.alias === null ? "-" : shop.alias}
                                            </td>

                                            <td className="px-2 py-4 whitespace-nowrap text-center">{shop.name}</td>

                                            <td className="px-2 py-4 whitespace-nowrap text-center">
                                                {shop.card_brands[0].status_changed_date !== null
                                                    ? shop.card_brands[0].status_changed_date
                                                    : "-"}
                                            </td>

                                            <td className="px-2 py-4 whitespace-nowrap text-center">
                                                {shop.terminals.length > 1 && (
                                                    <div>
                                                        <span>
                                                            {shop.terminals[0].devices[0].serial_number
                                                                ? shop.terminals[0].devices[0].serial_number
                                                                : "-"}
                                                            ,
                                                        </span>
                                                        <button
                                                            onClick={() => moreTerminals(shop)}
                                                            className="focus:outline-none ml-2 text-gfLightBlue hover:text-opacity-75 text-xs  hover:underline"
                                                        >
                                                            {t("More")}...
                                                        </button>
                                                    </div>
                                                )}
                                                {shop.terminals.length === 0 && <span>--</span>}
                                                {shop.terminals.length === 1 && (
                                                    <span>
                                                        {shop.terminals[0].devices[0].serial_number
                                                            ? shop.terminals[0].devices[0].serial_number
                                                            : "-"}
                                                    </span>
                                                )}
                                            </td>

                                            <td className="px-2 py-4 whitespace-nowrap text-center">
                                                {shop.card_brands[0].status === "accepted" && (
                                                    <button
                                                        onClick={() =>
                                                            changeShopStatusHandler(
                                                                shop.id,
                                                                shop.card_brands[0].status,
                                                                "rejected"
                                                            )
                                                        }
                                                        className="focus:outline-none text-gfCoral hover:text-opacity-75 font-MulishBold   hover:underline"
                                                    >
                                                        {t("Decline-shop")}
                                                    </button>
                                                )}
                                                {shop.card_brands[0].status === "rejected" && (
                                                    <button
                                                        onClick={() =>
                                                            changeShopStatusHandler(
                                                                shop.id,
                                                                shop.card_brands[0].status,
                                                                "accepted"
                                                            )
                                                        }
                                                        className="focus:outline-none text-gfLightBlue hover:text-opacity-75 font-MulishBold   hover:underline"
                                                    >
                                                        {t("Authorize-shop")}
                                                    </button>
                                                )}
                                                {shop.card_brands[0].status === "unconfirmed" && (
                                                    <div className="flex flex-col md:flex-row justify-center items-center">
                                                        <button
                                                            onClick={() =>
                                                                changeShopStatusHandler(
                                                                    shop.id,
                                                                    shop.card_brands[0].status,
                                                                    "accepted"
                                                                )
                                                            }
                                                            className="focus:outline-none text-gfLightBlue hover:text-opacity-75 font-MulishBold   hover:underline"
                                                        >
                                                            {t("Authorize-shop")}
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                changeShopStatusHandler(
                                                                    shop.id,
                                                                    shop.card_brands[0].status,
                                                                    "rejected"
                                                                )
                                                            }
                                                            className="focus:outline-none text-gfCoral hover:text-opacity-75 font-MulishBold  ml-5 hover:underline"
                                                        >
                                                            {t("Decline-shop")}
                                                        </button>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    )}
                    {/** Errors */}

                    {/** terminal list */}
                    <TerminalsModal modalVisibility={visibility} modalData={data} modalCloseHandler={modalClose} />
                </div>
            </div>
        </Container>
    );
}
