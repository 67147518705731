import React from "react";

export default function ButtonLoading({ color }) {
    return (
        <div className="">
            <svg className="animate-spin mx-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 18.4C14.6392 18.4 18.4 14.6392 18.4 10C18.4 5.36081 14.6392 1.60001 10 1.60001C5.36081 1.60001 1.60001 5.36081 1.60001 10C1.60001 14.6392 5.36081 18.4 10 18.4Z" stroke={color} strokeWidth="2.4" strokeOpacity="0.3" />

                <path
                    d="M10 18.4C14.6392 18.4 18.4 14.6392 18.4 10C18.4 5.36081 14.6392 1.60001 10 1.60001C5.36081 1.60001 1.60001 5.36081 1.60001 10C1.60001 14.6392 5.36081 18.4 10 18.4Z"
                    stroke={color}
                    strokeWidth="2.4"
                    strokeDasharray="39.58 13.67"
                />
            </svg>
        </div>
    );
}
