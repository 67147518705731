import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DropdownIndicator from "./Components/DropdownIndicator";
import { useDispatch, useSelector } from "react-redux";
import { SELECTED_LANGUAGE } from "../../actions/LanguageAction";
import { GF_CORAL, GF_DARK_BLUE, GF_GREY, GF_PERIWINKLE } from "../../utils/Constants";
import { useHistory } from "react-router";

export default function LanguageDropdown() {
    const { selectedLanguage, languages } = useSelector((state) => state.language);
    const dispatch = useDispatch();
    const history = useHistory()

    const customStyles = (value) => ({
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "40px",
            paddingBottom: "2px",
            color: GF_DARK_BLUE,
            cursor: "pointer",
            fontWeight: "600",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: GF_PERIWINKLE,
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && state.data.id === value.id ? GF_DARK_BLUE : state.isFocused ? GF_CORAL : "white",
            color: state.isSelected && state.data.id === value.id ? "#FFF" : GF_DARK_BLUE,
            "&:hover": {
                background: GF_CORAL,
                color: "#FFF",
            },
        }),

        menuList: (provided) => ({
            ...provided,
            maxHeight: "100%",
            color: GF_DARK_BLUE,
        }),

        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
            fontSize: "16px",
        }),

        placeholder: (provided) => ({
            ...provided,
            color: GF_GREY,
        }),
    });

    const { i18n } = useTranslation();

    const handleChange = (event) => {
        
        history.push({
            pathname: history.location.pathname,
            search: `?lan=${event.value}`,
        });
        i18n.changeLanguage(event.value);
        dispatch({ type: SELECTED_LANGUAGE, payload: event.value });
    };

    return (
        <div>
            <Select
                value={selectedLanguage}
                getOptionLabel={(option) => `${option.label}`}
                onChange={handleChange}
                placeholder={false}
                styles={customStyles(selectedLanguage)}
                options={languages}
                isSearchable={false}
                components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: DropdownIndicator,
                }}
            />
        </div>
    );
}
