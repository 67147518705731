/* eslint-disable import/no-anonymous-default-export */
import {
    ERROR_DAILY_REPORT,
    ERROR_DESK_LIST,
    ERROR_USER_LIST,
    LOADING_DAILY_REPORT,
    LOADING_DESK_LIST,
    LOADING_USER_LIST,
    POPULATED_LIST,
    RECEIVED_DAILY_REPORT,
    RECEIVED_DESK_LIST,
    RECEIVED_USER_LIST,
    RESET_DAILY_REPORT,
} from "../../actions/DailyReportActions";
import { paginate } from "../../utils/Helper";
import i18n from "i18next";

const PAGINATE_FIRST_PAGE = 1;

const initialState = {
    loadingUserList: false,
    receivedUserList: null,
    errorUserList: null,
    userArray: null,

    loadingDeskList: false,
    receivingDeskList: null,
    errorDeskList: null,
    deskArray: null,

    loadingDailyReport: false,
    receivingDailyReport: null,
    errorDailyReport: null,
    dailyReportSummary: null,
    populatedList: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_USER_LIST:
            return {
                ...state,
                loadingUserList: true,
                receivedUserList: null,
                errorUserList: null,
            };

        case RECEIVED_USER_LIST:
            let tempUserArray;
            let temp;
            if (action.payload && action.payload.length !== 0) {
                tempUserArray = action.payload.map((item) => item.id);
                temp = [{ id: -1, firstName: i18n.t("All"), lastName: "" }, ...action.payload];
            } else {
                temp = action.payload;
            }

            return {
                ...state,
                loadingUserList: false,
                receivedUserList: temp,
                errorUserList: null,
                userArray: tempUserArray,
            };

        case ERROR_USER_LIST:
            return {
                ...state,
                loadingUserList: false,
                receivedUserList: null,
                errorUserList: action.payload,
            };

        case LOADING_DESK_LIST:
            return {
                ...state,
                loadingDeskList: true,
                receivingDeskList: null,
                errorDeskList: null,
            };

        case RECEIVED_DESK_LIST:
            const tempDeskArray = action.payload.map((item) => item.id);
            const tempDesk =
                action.payload.length === 1 ? action.payload : [{ id: -1, name: i18n.t("All") }, ...action.payload];
            return {
                ...state,
                loadingDeskList: false,
                receivingDeskList: tempDesk,
                errorDeskList: null,
                deskArray: tempDeskArray,
            };

        case ERROR_DESK_LIST:
            return {
                ...state,
                loadingDeskList: false,
                receivingDeskList: action.payload,
                errorDeskList: null,
            };

        case LOADING_DAILY_REPORT:
            return {
                ...state,
                loadingDailyReport: true,
                receivingDailyReport: null,
                errorDailyReport: null,
            };

        case RECEIVED_DAILY_REPORT:
            return {
                ...state,
                loadingDailyReport: false,
                receivingDailyReport: action.payload.transactions,
                errorDailyReport: null,
                dailyReportSummary: action.payload.summary,
                populatedList: paginate(
                    action.payload.transactions,
                    PAGINATE_FIRST_PAGE,
                    process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                ),
            };

        case ERROR_DAILY_REPORT:
            return {
                ...state,
                loadingDailyReport: false,
                receivingDailyReport: null,
                errorDailyReport: action.payload,
            };

        case POPULATED_LIST:
            return {
                ...state,
                populatedList: paginate(
                    state.receivingDailyReport,
                    action.payload,
                    process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                ),
            };

        case RESET_DAILY_REPORT:
            return {
                ...state,
                ...initialState,
            };

        default:
            return state;
    }
}

// const paginate = function (array, index, size) {
//     index = Math.abs(parseInt(index));
//     index = index > 0 ? index - 1 : index;
//     size = parseInt(size);
//     size = size < 1 ? 1 : size;

//     return [
//         ...array.filter((value, n) => {
//             return n >= index * size && n < (index + 1) * size;
//         }),
//     ];
// };
