import { format } from "date-fns";
import React, { forwardRef, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMVCtoMVCTransferHistory, getMVCtoMVCTransferHistoryPaginate, RESET_ALL_MVC_MVC_TRANSFER } from "../../../actions/MVCToMVCTransferActions";
import { TAG_CHANGED } from "../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../actions/TerminalActions";
import ButtonLoading from "../../../assets/svg/ButtonLoading";
import { GF_DARK_BLUE } from "../../../utils/Constants";
import FailAlert from "../../Alert/FailAlert";
import MessageAlert from "../../Alert/MessageAlert";
import Container from "../../UIComponents/Container";
import Table from "../../UIComponents/Table";
import CardInfoWidget from "../../../components/CardInfoWidget";
import { checkLoggedInUserPermissions } from "../../../utils/Helper";
import { getCalanderLocale } from "../../../utils/CalanderLocale";
import { Userpilot } from "userpilot";

export default function MVCTransferHistory() {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(null);
    const [selectedPage, setSelectedPage] = useState(1);
    const { t } = useTranslation();

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_ALL_MVC_MVC_TRANSFER });

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_ALL_MVC_MVC_TRANSFER });
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_ALL_MVC_MVC_TRANSFER });
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    

    const { loadingMVCTransHistory, receivedMVCTransHistory, errorMVCTransHistory, metaMVCTansHistory, summaryMVCTansHistory } = useSelector((state) => state.mvc_mcvTransfer);
    const { tagCurrency } = useSelector((state) => state.tag);
    const { selectedLanguage } = useSelector((state) => state.language)

    getCalanderLocale(selectedLanguage)

    const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly={true}
            className="border cursor-pointer text-center w-full rounded hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 h-10 focus:outline-none"
        />
    ));

    const fromDatePickHandler = (date) => {
        setFromDate(date);
    };

    const toDatePickHandler = (date) => {
        setToDate(date);
    };

    const getList = () => {
        let from = format(fromDate, "yyyy-MM-dd");
        let to;
        if (toDate !== null) {
            to = format(toDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }

        dispatch(getMVCtoMVCTransferHistory(from, to, selectedPage));
    };

    const paginationHandler = (event) => {
        setSelectedPage(event.selected + 1);

        let from = format(fromDate, "yyyy-MM-dd");
        let to;
        if (toDate !== null) {
            to = format(toDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }
        dispatch(getMVCtoMVCTransferHistoryPaginate(from, to, event.selected + 1));
    };

    return (
        <Container title={t("MVC-transfer-History")}>
            <div className="grid grid-cols-1 md:grid-cols-3 items-end gap-5 mb-10">
                <div className="flex flex-col">
                    <div className="flex items-center  py-1">{t("Choose-the-date")}:</div>{" "}
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingMVCTransHistory}
                        dateFormat="dd/MM/yyyy"
                        customInput={<DPCustomInput />}
                        selected={fromDate}
                        onChange={(date) => fromDatePickHandler(date)}
                        locale={selectedLanguage.value}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="flex items-center  py-1">{t("End-date") + "(" + t("Optional") + ")"}:</div>{" "}
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingMVCTransHistory}
                        dateFormat="dd/MM/yyyy"
                        selected={toDate}
                        onChange={(date) => toDatePickHandler(date)}
                        customInput={<DPCustomInput />}
                        locale={selectedLanguage.value}
                    />
                </div>

                <button
                    disabled={loadingMVCTransHistory}
                    onClick={getList}
                    className="h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none"
                >
                    {loadingMVCTransHistory && <ButtonLoading color={GF_DARK_BLUE} />}
                    {t("Go")}!
                </button>
            </div>

            {((receivedMVCTransHistory && receivedMVCTransHistory.length === 0) || errorMVCTransHistory) && (
                <div className="p-5">
                    {receivedMVCTransHistory && receivedMVCTransHistory.length === 0 && <MessageAlert message={t("There-are-no-information-to-display-for-this-date")} />}
                    {errorMVCTransHistory && <FailAlert message={errorMVCTransHistory.message} />}
                </div>
            )}

            {receivedMVCTransHistory && receivedMVCTransHistory.length !== 0 && summaryMVCTansHistory && (
                <div className="grid grid-cols-1  md:grid-cols-2 gap-5 md:gap-20 py-5">
                    <CardInfoWidget
                        bottomLabel={t("Number-of-Transfers")}
                        isCurrency={false}
                        value={summaryMVCTansHistory.total_entries}
                        labelColor={"text-gfDarkBlue"}
                        valueColor={"text-gfDarkBlue"}
                    />
                    <CardInfoWidget bottomLabel={t("Total-amount")} isCurrency value={summaryMVCTansHistory.total_fees} labelColor={"text-gfLightBlue"} valueColor={"text-gfLightBlue"} />
                </div>
            )}

            {metaMVCTansHistory && metaMVCTansHistory.total > process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                <div className="w-full flex flex-row justify-end">
                    <div>
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={metaMVCTansHistory.total / process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS}
                            marginPagesDisplayed={4}
                            pageRangeDisplayed={5}
                            onPageChange={paginationHandler}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            )}

            {receivedMVCTransHistory && receivedMVCTransHistory.length !== 0 && (
                <Table>
                    <thead className="bg-gfGrey font-MulishBlack text-sm">
                        <tr>
                            <th scope="col" className="px-4 py-3  text-center  uppercase tracking-wider">
                                {t("Transfer-Date-and-Time")}
                            </th>
                            <th scope="col" className="py-3 px-10  text-center  uppercase tracking-wider">
                                {t("Debited-card")}
                            </th>
                            <th scope="col" className="px-10 py-3  text-center   uppercase tracking-wider">
                                {t("Credited-card")}
                            </th>
                            <th scope="col" className="px-10 py-3  text-right   uppercase tracking-wider">
                                {t("Amount")}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                        {receivedMVCTransHistory &&
                            receivedMVCTransHistory.length !== 0 &&
                            receivedMVCTransHistory.map((history) => (
                                <tr key={history.card_transfer_id}>
                                    <td className="px-4 py-4 whitespace-nowrap text-center">{history.transaction_date_time}</td>

                                    <td className="px-10 py-4 whitespace-nowrap text-center">
                                        <div className="flex justify-center items-center text-gfLightBlue">
                                            {checkLoggedInUserPermissions("card-information") ? (
                                                <Link className="underline" to={`/gift-cards/card-information?token=${history.source_card.token}`}>
                                                    {history.source_card.token}
                                                </Link>
                                            ) : (
                                                history.source_card.token
                                            )}
                                        </div>
                                    </td>

                                    <td className="px-10 py-4 whitespace-nowrap text-center">
                                        <div className="flex justify-center items-center text-gfLightBlue">
                                            {checkLoggedInUserPermissions("card-information") ? (
                                                <Link className="underline" to={`/gift-cards/card-information?token=${history.destination_card.token}`}>
                                                    {history.destination_card.token}
                                                </Link>
                                            ) : (
                                                history.destination_card.token
                                            )}
                                        </div>
                                    </td>

                                    <td className="px-10 py-4 whitespace-nowrap text-left">
                                        <div className="flex justify-end text-gfLightBlue font-MulishBold">
                                            <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={history.amount} displayType={"text"} thousandSeparator={true} />
                                            <span className="ml-2">{` ${tagCurrency}`}</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
}
