import { format, subMonths } from "date-fns";
import React, { useState, forwardRef, useRef, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import PageTitle from "../../../PageTitle";
import MessageAlert from "../../../Alert/MessageAlert";
import FailAlert from "../../../Alert/FailAlert";
import ReactPaginate from "react-paginate";
import CurrencyFormat from "react-currency-format";
import {
    cancelOrder,
    fetchCardOrders,
    getOrderDetails,
    RESET_ORDER_DETAILS,
    RESET_ORDER_HISTORY,
} from "../../../../actions/CardOrderActions";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import { useTranslation } from "react-i18next";
import Container from "../../../UIComponents/Container";
import { GF_DARK_BLUE, GF_DARK_SAND } from "../../../../utils/Constants";
import Table from "../../../UIComponents/Table";
import CancelOrderConfirmation from "./CancelOrderConfirmation";
import SearchIcon from "../../../../assets/svg/SearchIcon";
import ClearIcon from "../../../../assets/svg/ClearIcon";
import { getCalanderLocale } from "../../../../utils/CalanderLocale";
import { Userpilot } from "userpilot";
import OrderDetails from "./OrderDetails";

export default function CardOrderList(props) {
    const dispatch = useDispatch();
    const { cardOrderList, loadingCardOrderList, errorOrderList, metaCardOrderList } = useSelector(
        (state) => state.cardOrders
    );
    const { tagCurrency } = useSelector((state) => state.tag);
    const [chooseDate, setChooseDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [cancelOrderIsOpen, setSCancelOrderIsOpen] = useState(false);
    const [reference, setReference] = useState("");
    const [keyword, setKeyword] = useState("");
    const [orderDetailsVisibility, setOrderDetailsVisibility] = useState(false);
    const { t } = useTranslation();
    //const { loadingOrderDetails } = useSelector((state) => state.cardOrders);

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_ORDER_HISTORY });

        const params = new URLSearchParams(props.location.search);
        const query = params.get("query");
        const from_date = params.get("from_date");
        const to_date = params.get("to_date");
        if (query && from_date && to_date) {
            setKeyword(query);
            setChooseDate(new Date(from_date));
            setEndDate(new Date(to_date));

            dispatch(fetchCardOrders(from_date, to_date, 1, query));
        }

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_ORDER_HISTORY });
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_ORDER_HISTORY });
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    const { selectedLanguage } = useSelector((state) => state.language);

    getCalanderLocale(selectedLanguage);

    const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly={true}
            className="border cursor-pointer w-full rounded text-center hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 focus:outline-none"
        />
    ));

    const fromDatePickHandler = (date) => {
        setChooseDate(date);
    };

    const toDatePickHandler = (date) => {
        setEndDate(date);
    };

    const getCardOrderList = () => {
        let from = format(chooseDate, "yyyy-MM-dd");
        let to;
        if (endDate !== null) {
            to = format(endDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }

        dispatch(fetchCardOrders(from, to, 1, keyword));
    };

    const handlePageClick = (event) => {
        let from = format(chooseDate, "yyyy-MM-dd");
        let to;
        if (endDate !== null) {
            to = format(endDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }

        dispatch(fetchCardOrders(from, to, event.selected + 1));
    };

    const cancelOrderHandler = (order) => {
        setReference(order);
        setSCancelOrderIsOpen(true);
    };

    const yesOptionHandler = () => {
        dispatch(cancelOrder(reference)).then(function (success) {
            let from = format(chooseDate, "yyyy-MM-dd");
            let to;
            if (endDate !== null) {
                to = format(endDate, "yyyy-MM-dd");
            } else {
                to = format(new Date(), "yyyy-MM-dd");
            }

            dispatch(fetchCardOrders(from, to, 1));

            setReference("");
            setSCancelOrderIsOpen(false);
        });
    };

    const noOptionHandler = () => {
        setReference("");
        setSCancelOrderIsOpen(false);
    };

    const clearSearchBox = () => {
        setKeyword("");
        let from = format(chooseDate, "yyyy-MM-dd");
        let to;
        if (endDate !== null) {
            to = format(endDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }
        dispatch(fetchCardOrders(from, to, 1));
    };

    const searchOnChangeHandler = (event) => {
        setKeyword(event.target.value);
        let from = format(chooseDate, "yyyy-MM-dd");
        let to;
        if (endDate !== null) {
            to = format(endDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }
        dispatch(fetchCardOrders(from, to, 1, event.target.value));
    };

    const openOderDetailsView = (ref) => {
        dispatch(getOrderDetails(ref)).then(function (success) {
            setReference(ref);
            setOrderDetailsVisibility(true);
        });
    };

    const closeOrderDetailsView = () => {
        setReference("");
        setOrderDetailsVisibility(false);
        dispatch({ type: RESET_ORDER_DETAILS });
    };

    return (
        <Container title={t("order-history")}>
            <div
                className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4  gap-5 items-end`}
            >
                <div>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="focus:outline-none focus:shadow-outline">
                                <SearchIcon />
                            </button>
                        </span>
                        {keyword !== "" && (
                            <button
                                onClick={clearSearchBox}
                                className="focus:outline-none focus:shadow-outline"
                                style={{ position: "absolute", right: "5px", top: "10px" }}
                            >
                                <ClearIcon />
                            </button>
                        )}
                        <input
                            value={keyword}
                            onChange={searchOnChangeHandler}
                            className="h-10 text-lg w-full placeholder-gfGrey  rounded border border-gfPeriwinkle focus:border-gfPeriwinkle pl-10 focus:outline-none"
                            placeholder={t("Search") + "..."}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex items-center  py-1">{t("Choose-the-date")}:</div>{" "}
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingCardOrderList}
                        dateFormat="dd/MM/yyyy"
                        customInput={<DPCustomInput />}
                        selected={chooseDate}
                        onChange={(date) => fromDatePickHandler(date)}
                        locale={selectedLanguage.value}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="flex items-center   py-1">{t("End-date") + "(" + t("Optional") + ")"}</div>
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        disabled={loadingCardOrderList}
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date) => toDatePickHandler(date)}
                        customInput={<DPCustomInput />}
                        locale={selectedLanguage.value}
                    />
                </div>

                <button
                    disabled={loadingCardOrderList}
                    onClick={getCardOrderList}
                    className=" py-2 border flex flex-row items-center justify-center rounded  hover:bg-opacity-75 font-MulishBold bg-gfCoral px-4 focus:outline-none"
                >
                    {loadingCardOrderList && <ButtonLoading color={GF_DARK_BLUE} />}
                    {t("Go")}!
                </button>
            </div>

            <div className="flex flex-col mt-20 space-y-5">
                <div className="lg:px-40 md:px-40 px-3">
                    {cardOrderList && cardOrderList.length === 0 && (
                        <MessageAlert message={t("There-are-no-information-to-display-for-this-date")} />
                    )}
                    {errorOrderList && <FailAlert message={errorOrderList.message} />}
                </div>
                <div className="flex md:flex-row flex-col justify-between items-center my-3 ">
                    {cardOrderList &&
                        metaCardOrderList &&
                        metaCardOrderList.total > process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                            <div>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={metaCardOrderList.total / process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                </div>

                {cardOrderList && cardOrderList.length !== 0 && (
                    <Table>
                        <thead className="bg-gfGrey font-MulishBlack text-gfDarkBlue text-sm">
                            <tr>
                                <th scope="col" className="px-4 py-3 text-center   uppercase tracking-wider">
                                    {t("Date")}
                                </th>
                                <th scope="col" className=" px-2 py-3 text-center   uppercase tracking-wider">
                                    {t("Order-Ref")}
                                </th>
                                <th scope="col" className=" px-2 py-3 text-center   uppercase tracking-wider">
                                    {t("Status")}
                                </th>
                                <th scope="col" className=" px-2 py-3 text-center   uppercase tracking-wider">
                                    {t("Delivery-address")}
                                </th>
                                <th scope="col" className=" py-3 px-2 text-center   uppercase tracking-wider">
                                    {t("Contact-person")}
                                </th>
                                <th scope="col" className=" py-3 px-2 text-center   uppercase tracking-wider">
                                    {t("Quantity")}
                                </th>
                                <th scope="col" className=" py-3 px-2 text-center   uppercase tracking-wider">
                                    {t("Total-amount")}
                                </th>
                                <th scope="col" className=" py-3 px-2 text-center   uppercase tracking-wider">
                                    {t("Action")}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                            {cardOrderList &&
                                cardOrderList.map((order) => (
                                    <tr key={order.id}>
                                        <td className="px-4 py-4">
                                            <div className="flex justify-center items-center">
                                                {order.creation_date}
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className="flex justify-center items-center">{order.order_ref}</div>
                                        </td>
                                        <td className="px-2 py-4">
                                            <div className="flex justify-center items-center">
                                                {String(order.status).toLocaleUpperCase()}
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 text-center">
                                            <div className="flex justify-center items-center ">
                                                <span>{`${order.address}`}</span>
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className="flex justify-center items-center">{`${order.contact.name}`}</div>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className="flex justify-center items-center">{`x${order.total_quantity}`}</div>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className="flex justify-center items-center">
                                                <div className="flex flex-row text-gfLightBlue font-MulishBold">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={order.total_amount}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                    <span className="ml-1 ">{`${String(
                                                        tagCurrency
                                                    ).toUpperCase()}`}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 text-center">
                                            <div className="flex flex-col gap-2">
                                                {order.status === "received" && (
                                                    <button
                                                        onClick={() => cancelOrderHandler(order.order_ref)}
                                                        className="rounded bg-gfCoral text-white py-2 px-4 font-MulishBold focus:outline-none hover:underline "
                                                    >
                                                        {t("Cancel-order")}
                                                    </button>
                                                )}
                                                <button id={order.order_ref}
                                                    className="rounded bg-gfPeriwinkle text-gfDarkBlue py-2 px-4 font-MulishBold focus:outline-none hover:underline flex flex-row justify-center"
                                                    onClick={() => openOderDetailsView(order.order_ref)}
                                                >
                                                    {/* {loadingOrderDetails && <ButtonLoading color={GF_DARK_SAND} />} */}
                                                    {t("View-details")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                )}
            </div>
            <CancelOrderConfirmation
                isOpen={cancelOrderIsOpen}
                noOption={noOptionHandler}
                yesOption={yesOptionHandler}
            />
            <OrderDetails
                modalVisibility={orderDetailsVisibility}
                closeHandler={closeOrderDetailsView}
                data={reference}
            />
        </Container>
    );
}
