import React from "react";
import { useTranslation } from "react-i18next";

export default function CheckBox(props) {
    const { t } = useTranslation();
    //console.log(props)
    return (
        <li key={props.permission_id ? props.permission_id : props.id}>
            <div className="flex flex-row px-10 items-center">
                <input
                    key={props.permission_id ? props.permission_id : props.id}
                    className="mr-2"
                    type="checkbox"
                    id={`${props.permission_id}`}
                    name={props.name}
                    value={props.name}
                    checked={props.isChecked}
                    disabled={props.disabled}
                    onChange={props.handleCheckChieldElement}
                />
                <label className={`${props.disabled ? "text-gray-300" : "text-gfDarkBlue"}`} htmlFor={`${props.id}`}>
                    {t(props.guard_name)}
                </label>
            </div>
        </li>
    );
}
