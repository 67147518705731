import React, { useRef, useState } from "react";
import { format, parseISO } from "date-fns";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import CardInfoWidget from "../../../../CardInfoWidget";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GF_CORAL, GF_DARK_BLUE, GF_LIGHT_BLUE, GF_PERIWINKLE } from "../../../../../utils/Constants";
import MessageAlert from "../../../../Alert/MessageAlert";
import FailAlert from "../../../../Alert/FailAlert";
import CurrencyFormat from "react-currency-format";
import { exportComponentAsJPEG, exportComponentAsPNG } from "react-component-export-image";
import { useReactToPrint } from "react-to-print";
import { getCSVReoprtSales } from "../../../../../actions/SalesSummaryActions";
import { getDateLocale } from "../../../../../utils/CalanderLocale";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default function CardSales({ chooseDate, endDate, groupBy }) {
    const { t } = useTranslation();
    const { receivedSalesSummary, errorSalesSummary, summary } = useSelector((state) => state.salesSummary);
    const { tagCurrency } = useSelector((state) => state.tag);
    const { selectedLanguage } = useSelector((state) => state.language);
    const dispatch = useDispatch();

    const componentRef = useRef();

    const dateTickFormatter = (tickItem) => {
        return format(new Date(tickItem), "dd-MM-yy");
    };

    const rightYAxis = (props) => {
        const { x, y, payload } = props;
        return (
            <g>
                <text className="text-sm font-MulishBold" x={x} y={y} dy={-15} fill={GF_LIGHT_BLUE}>
                    {parseFloat(payload.value).toFixed(2)}
                </text>
                <text className="text-xs font-MulishBold" x={x} y={y + 15} dy={-15} fill={GF_LIGHT_BLUE}>
                    {tagCurrency}
                </text>
            </g>
        );
    };

    const leftYAxis = (props) => {
        const { x, y, payload } = props;
        return (
            <g>
                <text className="text-sm font-MulishBold text-right" x={x} y={y} dy={5} dx={-25} fill={GF_DARK_BLUE}>
                    {payload.value}
                </text>
            </g>
        );
    };

    const CustomizedTooltip = ({ active, payload, label }) => {
        if (active && label !== undefined) {
            return (
                <div className="flex flex-col p-4 border bg-white rounded border-gfDarkSand font-MulishBold text-sm space-y-2 shadow-md">
                    <div className="py-1 text-lg">
                        {format(parseISO(label), "EEEE,MMMM dd, yyyy", { locale: getDateLocale(selectedLanguage) })}
                    </div>
                    {(chartType === "card_sales_count" || chartType === "both") && (
                        <div className="">
                            <span className="text-gfCoral">{`${t("Cards-Sold")}: ${
                                payload[0].payload.card_sales_count
                            }`}</span>
                        </div>
                    )}

                    {(chartType === "card_sales_value" || chartType === "both") && (
                        <div className="flex flex-row">
                            <span className="text-gfLightBlue mr-1">{`${t("Amounts-loaded")}: `}</span>
                            <div className="flex justify-start text-gfLightBlue">
                                <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={payload[0].payload.card_sales_value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                                <span className="ml-2">{` ${tagCurrency}`}</span>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;

        return (
            <span style={{ color }}>{`${value === "card_sales_count" ? t("Cards-Sold") : t("Amounts-loaded")}`}</span>
        );
    };

    const [chartType, setChartType] = useState("card_sales_count");

    const printReceipt = useReactToPrint({
        content: () => componentRef.current,
    });

    const getReport = () => {
        let from = format(chooseDate, "yyyy-MM-dd");
        let to = format(endDate === null ? chooseDate : endDate, "yyyy-MM-dd");
        dispatch(getCSVReoprtSales(from, to, groupBy));
    };

    const savePDF = () => {
        var divHeight = document.getElementById("myDiv").clientHeight;
        var divWidth = document.getElementById("myDiv").clientWidth;
        var ratio = divHeight / divWidth;

        html2canvas(componentRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({ orientation: "l" });
            let width = pdf.internal.pageSize.getWidth();
            let height = pdf.internal.pageSize.getHeight();
            let h = ratio * width;
            pdf.addImage(imgData, "JPEG", 0, 0, width, h);
            pdf.save("myPage.pdf"); //Download the rendered PDF.
        });
    };

    return (
        <div>
            {receivedSalesSummary &&
                receivedSalesSummary.length !== 0 &&
                receivedSalesSummary[0].sales &&
                receivedSalesSummary[0].sales.length !== 0 && (
                    <div className="flex flex-col space-y-5">
                        <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20 mb-10">
                            <CardInfoWidget
                                bottomLabel={t("Cards-Sold")}
                                isCurrency={false}
                                value={summary.total_sales}
                                labelColor={"text-gfLightBlue"}
                                valueColor={"text-gfLightBlue"}
                            />
                            <CardInfoWidget
                                bottomLabel={t("Total-loaded")}
                                isCurrency
                                value={summary.total_value}
                                labelColor={"text-gfDarkBlue"}
                                valueColor={"text-gfDarkBlue"}
                            />
                            <CardInfoWidget
                                bottomLabel={t("Average-load")}
                                isCurrency
                                value={summary.average}
                                labelColor={"text-gfLightBlue"}
                                valueColor={"text-gfLightBlue"}
                            />
                        </div>

                        <div className="flex justify-between items-center">
                            <div className="py-4">
                                <div className="h-10 mb-5 sm:mb-5 md:mb-0 " id="switch-field">
                                    <input
                                        type="radio"
                                        id="radio-one-card-type1"
                                        name="switch-one-card-type1"
                                        value="card_sales_count"
                                        checked={chartType === "card_sales_count"}
                                        onChange={(e) => {
                                            setChartType(e.target.value);
                                        }}
                                    />
                                    <label htmlFor="radio-one-card-type1" className=" ">
                                        {t("Cards-Sold")}
                                    </label>
                                    <input
                                        type="radio"
                                        id="radio-two-card-type2"
                                        name="switch-two-card-type2"
                                        value="card_sales_value"
                                        checked={chartType === "card_sales_value"}
                                        onChange={(e) => {
                                            setChartType(e.target.value);
                                        }}
                                    />
                                    <label htmlFor="radio-two-card-type2" className=" ">
                                        {t("Amounts-loaded")}
                                    </label>
                                    <input
                                        type="radio"
                                        id="radio-two-card-type3"
                                        name="switch-two-card-type3"
                                        value="both"
                                        checked={chartType === "both"}
                                        onChange={(e) => {
                                            setChartType(e.target.value);
                                        }}
                                    />
                                    <label htmlFor="radio-two-card-type3" className=" ">
                                        {t("Both")}
                                    </label>
                                </div>
                            </div>

                            <div>
                                <button
                                    className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
                                    onClick={() => exportComponentAsPNG(componentRef)}
                                >
                                    {t("save-png")}
                                </button>
                                <button
                                    className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
                                    onClick={() => exportComponentAsJPEG(componentRef)}
                                >
                                    {t("save-jpeg")}
                                </button>
                                <button
                                    className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
                                    onClick={savePDF}
                                >
                                    {t("export-pdf")}
                                </button>
                                <button
                                    className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
                                    onClick={getReport}
                                >
                                    {t("export-csv")}
                                </button>
                            </div>
                        </div>

                        <div ref={componentRef} id="myDiv" className="flex justify-center w-full h-800 my-3 flex-col">
                            <div className="flex flex-row justify-center items-center font-MulishBold py-4 text-2xl">{`${t(
                                "sales-summary"
                            )} / ${t("Cards-Sold")} -> ${format(chooseDate, "yyyy-MM-dd")} - ${format(
                                endDate,
                                "yyyy-MM-dd"
                            )}`}</div>
                            {chartType === "card_sales_count" && (
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart
                                        margin={{
                                            top: 20,
                                            right: 40,
                                            left: 40,
                                            bottom: 50,
                                        }}
                                        data={receivedSalesSummary[0].sales}
                                        width={500}
                                        height={500}
                                    >
                                        <CartesianGrid stroke={GF_PERIWINKLE} />
                                        <XAxis
                                            dataKey="reception_date"
                                            tickFormatter={dateTickFormatter}
                                            stroke={GF_DARK_BLUE}
                                            angle={90}
                                            minTickGap={-200}
                                            padding={{ left: 20, right: 20, top: 20, bottom: 20 }}
                                            tick={{ fontSize: 14 }}
                                            dy={40}
                                        ></XAxis>
                                        <YAxis
                                            allowDecimals={false}
                                            yAxisId="left"
                                            dataKey="card_sales_count"
                                            stroke={GF_DARK_BLUE}
                                        >
                                            <Label className="font-MulishBold" angle={-90} position="insideLeft">
                                                Cards
                                            </Label>
                                        </YAxis>
                                        <Tooltip content={CustomizedTooltip} />
                                        <Legend verticalAlign="top" formatter={renderColorfulLegendText} />
                                        <Line
                                            yAxisId="left"
                                            type="linear"
                                            dataKey="card_sales_count"
                                            stroke={GF_CORAL}
                                            strokeWidth={2}
                                        />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            )}

                            {chartType === "card_sales_value" && (
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart
                                        margin={{
                                            top: 20,
                                            right: 40,
                                            left: 40,
                                            bottom: 50,
                                        }}
                                        data={receivedSalesSummary[0].sales}
                                        width={500}
                                        height={500}
                                    >
                                        <CartesianGrid stroke={GF_PERIWINKLE} />
                                        <XAxis
                                            dataKey="reception_date"
                                            tickFormatter={dateTickFormatter}
                                            stroke={GF_DARK_BLUE}
                                            angle={90}
                                            minTickGap={-200}
                                            padding={{ left: 20, right: 20, top: 20, bottom: 20 }}
                                            tick={{ fontSize: 14 }}
                                            dy={40}
                                        />
                                        <YAxis
                                            tick={rightYAxis}
                                            yAxisId="right"
                                            dataKey="card_sales_value"
                                            orientation="right"
                                            stroke={GF_DARK_BLUE}
                                        >
                                            <Label className="font-MulishBold" angle={-90} position="insideRight">
                                                Amount
                                            </Label>
                                        </YAxis>
                                        <Tooltip content={CustomizedTooltip} />
                                        <Legend verticalAlign="top" formatter={renderColorfulLegendText} />
                                        <Line
                                            yAxisId="right"
                                            type="linear"
                                            dataKey="card_sales_value"
                                            stroke={GF_LIGHT_BLUE}
                                            strokeWidth={2}
                                        />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            )}

                            {chartType === "both" && (
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart
                                        margin={{
                                            top: 20,
                                            right: 40,
                                            left: 40,
                                            bottom: 50,
                                        }}
                                        data={receivedSalesSummary[0].sales}
                                        width={500}
                                        height={300}
                                    >
                                        <CartesianGrid stroke={GF_PERIWINKLE} />
                                        <XAxis
                                            dataKey="reception_date"
                                            tickFormatter={dateTickFormatter}
                                            stroke={GF_DARK_BLUE}
                                            angle={90}
                                            minTickGap={-200}
                                            padding={{ left: 20, right: 20, top: 20, bottom: 20 }}
                                            tick={{ fontSize: 14 }}
                                            dy={40}
                                        />
                                        <YAxis
                                            //tick={leftYAxis}
                                            yAxisId="left"
                                            dataKey="card_sales_count"
                                            stroke={GF_DARK_BLUE}
                                        >
                                            <Label className="font-MulishBold" angle={-90} position="insideLeft">
                                                {t("Cards")}
                                            </Label>
                                        </YAxis>
                                        <YAxis
                                            tick={rightYAxis}
                                            yAxisId="right"
                                            dataKey="card_sales_value"
                                            orientation="right"
                                            stroke={GF_DARK_BLUE}
                                        >
                                            <Label className="font-MulishBold" angle={-90} position="insideRight">
                                                {t("Amount")}
                                            </Label>
                                        </YAxis>
                                        <Tooltip content={CustomizedTooltip} />
                                        <Legend verticalAlign="top" formatter={renderColorfulLegendText} />
                                        <Line
                                            yAxisId="right"
                                            type="linear"
                                            dataKey="card_sales_value"
                                            stroke={GF_LIGHT_BLUE}
                                            strokeWidth={2}
                                        />
                                        <Line
                                            yAxisId="left"
                                            type="linear"
                                            dataKey="card_sales_count"
                                            stroke={GF_CORAL}
                                            strokeWidth={2}
                                        />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            )}
                        </div>
                    </div>
                )}

            <div>
                {receivedSalesSummary && receivedSalesSummary[0].sales && receivedSalesSummary[0].sales.length === 0 && (
                    <div className="px-2 md:px-48">
                        <MessageAlert message={t("There-are-no-information-to-display-for-this-date")} />
                    </div>
                )}
                {errorSalesSummary && <FailAlert message={errorSalesSummary.message} />}
            </div>
        </div>
    );
}
