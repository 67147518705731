import { issuingAPI } from "../services/Axios";
import { exportCSVFile } from "../utils/Helper";
import { ERROR_CSV_REPORT, LOADING_CSV_REPORT, SUCCESS_CSV_REPORT } from "./CommonActions";

const PREFIX = "@feesOnlineReport/";

export const LOADING_FEES_ONLINE = `${PREFIX}LOADING_FEES_ONLINE`;
export const RECEIVED_FEES_ONLINE = `${PREFIX}RECEIVED_FEES_ONLINE`;
export const ERROR_FEES_ONLINE = `${PREFIX}ERROR_FEES_ONLINE`;
export const PAGINATE_FEES_ONLINE_LIST = `${PREFIX}PAGINATE_FEES_ONLINE_LIST`;

export const RESET_FEES_REPORT = `${PREFIX}RESET_FEES_REPORT`;

export const getFeesOnlineReport = (fromDate, toDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_FEES_ONLINE });

    const { tagID } = getState().tag;
    const params = {
        from_date: fromDate,
        to_date: toDate,
    };

    return issuingAPI
        .get(`tag/${tagID}/online-fees`, { params })
        .then((success) => {
            dispatch({ type: RECEIVED_FEES_ONLINE, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: ERROR_FEES_ONLINE, payload: error });
            return error;
        });
};

export const exportCSVFeesReport = (fromDate, toDate, reportName) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT });

    const { tagID } = getState().tag;
    const params = {
        from_date: fromDate,
        to_date: toDate,
    };

    const headers = {
        Accept: "text/csv",
    };

    const data = null;

    const fileName = `${reportName}-${fromDate}-${toDate}`;

    return issuingAPI
        .get(`tag/${tagID}/online-fees/stream`, { headers, params, data })
        .then((success) => {
            dispatch({ type: SUCCESS_CSV_REPORT });
            exportCSVFile(success.data, fileName);
        })
        .catch((error) => {
            dispatch({ type: ERROR_CSV_REPORT, payload: error });
            return error;
        });
};
