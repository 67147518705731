/* eslint-disable import/no-anonymous-default-export */
import {
    ERROR_CSV_REPORT,
    LOADING_CSV_REPORT,
    RESET_CSV_REPORT,
    RESET_VALIDATION_ERRORS,
    SUCCESS_CSV_REPORT,
    VALIDATION_ERRORS,
} from "../actions/CommonActions";

const initialState = {
    loadingCSV: false,
    successCSV: false,
    errorCSV: null,

    validationErrors: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_CSV_REPORT:
            return {
                ...state,
                loadingCSV: true,
                successCSV: false,
                errorCSV: null,
            };

        case SUCCESS_CSV_REPORT:
            return {
                ...state,
                loadingCSV: false,
                successCSV: true,
                errorCSV: null,
            };

        case ERROR_CSV_REPORT:
            return {
                ...state,
                loadingCSV: false,
                successCSV: false,
                errorCSV: action.payload,
            };

        case RESET_CSV_REPORT:
            return {
                ...state,
                ...initialState,
            };

        case VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.payload,
            };

        case RESET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: null,
            };

        default:
            return state;
    }
}
