/* eslint-disable import/no-anonymous-default-export */

import {
    ACTIVATING_ORDER,
    CHANGING_ORDER_STATUS,
    ERROR_MANAGE_SALES,
    FAIL_ACTIVATE_ORDER,
    FAIL_ORDER_STATUS,
    LOADING_MANAGE_SALES,
    MANAGE_SALES_OPERATION_ID,
    RECEIVED_MANAGE_SALES,
    RESET_ACTIVATE_ORDER,
    RESET_MANAGE_SALES,
    RESET_ORDER_STATUS,
    SUCCESS_ACTIVATE_ORDER,
    SUCCESS_ORDER_STATUS,
} from "../../actions/ManageSaleActions";
import { randomString } from "../../utils/Helper";

const initialState = {
    loadingManageSales: false,
    receivedManageSales: null,
    errorManageSales: null,
    meta: null,

    changingOrderStatus: false,
    successOrderStatus: false,
    failOrderStatus: null,

    activatingOrder: false,
    successActivateOrder: null,
    failActivateOrder: null,

    prepareOrderOpID: null,
    activateOerderOpID: null,
    changeManageSalesStatusOpID: null,
    changeManageSalesStatusSentOpID: null,
    changeManageSalesStatusPaidOpID: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_MANAGE_SALES:
            return {
                ...state,
                loadingManageSales: action.payload,
                errorManageSales: null,
            };

        case RECEIVED_MANAGE_SALES:
            return {
                ...state,
                loadingManageSales: false,
                receivedManageSales: action.payload.data,
                errorManageSales: null,
                meta: action.payload.meta,
            };

        case ERROR_MANAGE_SALES:
            return {
                ...state,
                loadingManageSales: false,
                receivedManageSales: null,
                errorManageSales: action.payload,
            };

        case MANAGE_SALES_OPERATION_ID: {
            return {
                ...state,
                prepareOrderOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
                activateOerderOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
                changeManageSalesStatusOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
                changeManageSalesStatusSentOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
                changeManageSalesStatusPaidOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
            };
        }
        case RESET_MANAGE_SALES:
            return {
                ...state,
                ...initialState,
            };

        case CHANGING_ORDER_STATUS:
            return {
                ...state,
                changingOrderStatus: true,
                successOrderStatus: false,
                failOrderStatus: null,
            };

        case SUCCESS_ORDER_STATUS:
            return {
                ...state,
                changingOrderStatus: false,
                successOrderStatus: true,
                failOrderStatus: null,
            };

        case FAIL_ORDER_STATUS:
            return {
                ...state,
                changingOrderStatus: false,
                successOrderStatus: false,
                failOrderStatus: action.payload,
            };

        case RESET_ORDER_STATUS:
            return {
                ...state,
                changingOrderStatus: false,
                successOrderStatus: false,
                failOrderStatus: null,
            };

        case ACTIVATING_ORDER:
            return {
                ...state,
                activatingOrder: true,
                successActivateOrder: null,
                failActivateOrder: null,
            };

        case SUCCESS_ACTIVATE_ORDER:
            return {
                ...state,
                activatingOrder: false,
                changingOrderStatus: false,
                successActivateOrder: action.payload,
                failActivateOrder: null,
            };
        case FAIL_ACTIVATE_ORDER:
            return {
                ...state,
                activatingOrder: false,
                successActivateOrder: null,
                failActivateOrder: action.payload,
            };

        case RESET_ACTIVATE_ORDER:
            return {
                ...state,
                activatingOrder: false,
                successActivateOrder: null,
                failActivateOrder: null,
            };

        default:
            return state;
    }
}
