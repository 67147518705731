import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RESET_CARD_INFO } from "../../../../actions/CardInformationActions";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import FailAlert from "../../../Alert/FailAlert";
import CardInfoWidget from "../../../CardInfoWidget";

export default function CardInfo({ modalVisibility, closeHandler, mvcToken }) {
    const { loadingCardInformation, cardInformation, errorCardInformation, loadingCardTransactions, cardTransactionInfo, errorTransactionInfo } = useSelector((state) => state.cardInformation);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div>
            {/* <!--Create Payment Method--> */}
            <div className={`modal ${modalVisibility ? " opacity-100" : " opacity-0 pointer-events-none"} fixed w-full h-full top-0 left-0 flex items-center justify-center`}>
                <div className="modal-overlay absolute w-full h-full bg-gfDarkBlue opacity-100"></div>
                <div className="modal-container bg-white w-11/12 md:max-w-4xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    {/* <!-- Add margin if you want to see some of the overlay behind the modal--> */}
                    <div className="modal-content py-4 text-left px-6 text-base text-gfDarkBlue">
                        {/* <!--Title--> */}
                        <div className="flex justify-between items-center ">
                            <div className="font-semibold">{t("Transaction-details-of ", { token: mvcToken })}</div>
                            <button
                                onClick={() => {
                                    dispatch({ type: RESET_CARD_INFO });
                                    closeHandler(!modalVisibility);
                                }}>
                                <CloseIcon />
                            </button>
                        </div>
                        {/* <!--Body--> */}
                        <div className="flex mx-auto justify-center items-center">
                            {loadingCardInformation && (
                                <div className="flex flex-row justify-center items-center py-20 mx-auto">
                                    <ButtonLoading color={"#4A5568"} />
                                    <span>{t("Getting-card-information")}...</span>
                                </div>
                            )}
                        </div>
                        {cardInformation && (
                            <div className="flex flex-col my-5 text-sm  ">
                                <div className="md:flex space-5 justify-between w-full ">
                                    <CardInfoWidget bottomLabel={t("Card-balance")} isCurrency value={cardInformation.balance_available} labelColor={"text-gfDarkBlue"} valueColor={"text-giftifyDarkBlue"} />
                                    <CardInfoWidget bottomLabel={t("Expiry-date")} value={cardInformation.expiration_date} labelColor={"text-gfDarkBlue"} valueColor={"text-giftifyDarkBlue"} />
                                    <CardInfoWidget bottomLabel={t("Status")} value={cardInformation.status.toUpperCase()} labelColor={"text-gfDarkBlue"} valueColor={"text-kadozGreen"} />
                                    <CardInfoWidget bottomLabel={t("Card-brand")} value={cardInformation.card_brand.name} labelColor={"text-gfDarkBlue"} valueColor={"text-giftifyDarkBlue"} />
                                </div>
                            </div>
                        )}
                        {errorCardInformation && (
                            <div className="flex flex-col my-5 text-sm justify-center">
                                <FailAlert message={errorCardInformation.message} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
