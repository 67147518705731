/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCardSales, getCardUsage } from "../../../../actions/SalesSummaryActions";
import { subMonths, format } from "date-fns";
import CardSales from "./Charts/CardSales";
import { RESET_SALES_SUMMARY } from "../../../../actions/SalesSummaryActions";
import CardUsage from "./Charts/CardUsage";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { useTranslation } from "react-i18next";
import Container from "../../../UIComponents/Container";
import ReactDatePicker from "react-datepicker";
import { getCalanderLocale } from "../../../../utils/CalanderLocale";
import { GF_CORAL, GF_DARK_BLUE, GF_PERIWINKLE } from "../../../../utils/Constants";
import Select from "react-select";
import { Userpilot } from "userpilot";

export default function SalesSummaryReport () {
    const GROUP_BY = "month";

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_SALES_SUMMARY });

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_SALES_SUMMARY });
            //getChartHandler();
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => { });
        };
    }, []);

    const { loadingSalesSummary, receivedSalesSummary } = useSelector((state) => state.salesSummary);

    const { selectedLanguage } = useSelector((state) => state.language);

    getCalanderLocale(selectedLanguage);

    const [cardType, setCardType] = useState("sales");
    const [dateRange, setDateRange] = useState("");
    const [selectedGroupBy, setSelectedGroupBy] = useState(null);

    const [chooseDate, setChooseDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const { t } = useTranslation();

    const groupBy = [
        { label: t("Day"), value: "day" },
        { label: t("Week"), value: "week" },
        { label: t("Month"), value: "month" },
        { label: t("Year"), value: "year" },
    ];

    const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ ref }
            value={ value }
            onClick={ onClick }
            readOnly={ true }
            className="border cursor-pointer text-sm text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 h-10 focus:outline-none"
        />
    ));

    const dispatch = useDispatch();

    const getChartHandler = () => {
        let from = format(chooseDate, "yyyy-MM-dd");
        let to = format(endDate === null ? new Date() : endDate, "yyyy-MM-dd");

        dispatch({ type: RESET_SALES_SUMMARY });

        if (cardType === "sales") {
            dispatch(getCardSales(from, to, selectedGroupBy !== null ? selectedGroupBy.value : GROUP_BY));
        } else {
            dispatch(getCardUsage(from, to, selectedGroupBy !== null ? selectedGroupBy.value : GROUP_BY));
        }
    };

    const chooseDatePickHandler = (date) => {
        setChooseDate(date);
    };

    const endDatePickHandler = (date) => {
        setEndDate(date);
    };

    const dateRangeHandler = (range) => {
        setDateRange(range);
        setEndDate(new Date());
        setChooseDate(subMonths(new Date(), parseInt(range)));
    };

    const setCardTypeHandler = (type) => {
        setCardType(type);

        let from = format(chooseDate, "yyyy-MM-dd");
        let to = format(endDate === null ? new Date() : endDate, "yyyy-MM-dd");

        dispatch({ type: RESET_SALES_SUMMARY });

        if (type === "sales") {
            dispatch(getCardSales(from, to, selectedGroupBy !== null ? selectedGroupBy.value : GROUP_BY));
        } else {
            dispatch(getCardUsage(from, to, selectedGroupBy !== null ? selectedGroupBy.value : GROUP_BY));
        }
    };

    const customStyles = (value) => ({
        placeholder: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
        }),
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "40px",
            paddingBottom: "2px",
            color: GF_DARK_BLUE,
            cursor: "pointer",
            minWidth: "230px",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
                borderColor: GF_PERIWINKLE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: "#F7F7F8",
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor:
                state.isSelected && state.data.value === value.value
                    ? GF_DARK_BLUE
                    : state.isFocused
                        ? GF_CORAL
                        : "white",
            padding: 8,
            fontSize: "16px",
            color: state.isSelected && state.data.value === value.value ? "#FFF" : GF_DARK_BLUE,
            "&:hover": {
                background: "#FF666E",
                color: "#FFF",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
            fontSize: "18px",
        }),
    });

    return (
        <Container title={ t("sales-summary") } loading={ loadingSalesSummary }>
            <div className="flex flex-col">
                <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row justify-start md:justify-evenly  md:items-start">
                    <div className="h-10 mb-5 sm:mb-5 md:mb-0 " id="switch-field">
                        <input
                            disabled={ loadingSalesSummary }
                            type="radio"
                            id="radio-one-card-type"
                            name="switch-one-card-type"
                            value="sales"
                            checked={ cardType === "sales" }
                            onChange={ (e) => {
                                setCardTypeHandler(e.target.value);
                            } }
                            locale={ selectedLanguage.value }
                        />
                        <label htmlFor="radio-one-card-type" className=" ">
                            { t("Card-Sales") }
                        </label>
                        <input
                            disabled={ loadingSalesSummary }
                            type="radio"
                            id="radio-two-card-type"
                            name="switch-two-card-type"
                            value="usage"
                            checked={ cardType === "usage" }
                            onChange={ (e) => {
                                setCardTypeHandler(e.target.value);
                            } }
                            locale={ selectedLanguage.value }
                        />
                        <label htmlFor="radio-two-card-type" className=" ">
                            { t("Card-Usage") }
                        </label>
                    </div>

                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-col w-full sm:w-full md:w-48 pr-5">
                            <div className="flex items-center   py-1">{ t("Choose-the-date") }:</div>{ " " }
                            <ReactDatePicker
                                showMonthDropdown
                                showYearDropdown
                                disabled={ loadingSalesSummary }
                                dateFormat="dd/MM/yyyy"
                                customInput={ <DPCustomInput /> }
                                selected={ chooseDate }
                                onChange={ (date) => chooseDatePickHandler(date) }
                                locale={ selectedLanguage.value }
                            />
                        </div>
                        <div className="flex flex-col w-full sm:w-full md:w-48">
                            <div className="flex items-center   py-1">{ t("End-date") + "(" + t("Optional") + ")" }</div>
                            <ReactDatePicker
                                showMonthDropdown
                                showYearDropdown
                                disabled={ loadingSalesSummary }
                                dateFormat="dd/MM/yyyy"
                                selected={ endDate }
                                onChange={ (date) => endDatePickHandler(date) }
                                customInput={ <DPCustomInput /> }
                                locale={ selectedLanguage.value }
                            />
                        </div>
                    </div>
                    <div className="mb-5 sm:mb-5 md:mb-0" id="switch-field">
                        <input
                            disabled={ loadingSalesSummary }
                            type="radio"
                            id="radio-zero-date-range"
                            name="switch-zero-date-range"
                            value="1"
                            checked={ dateRange === "1" }
                            onChange={ (e) => {
                                dateRangeHandler(e.target.value);
                            } }
                        />
                        <label htmlFor="radio-zero-date-range" className=" ">
                            1 { t("Month") }
                        </label>
                        <input
                            disabled={ loadingSalesSummary }
                            type="radio"
                            id="radio-one-date-range"
                            name="switch-one-date-range"
                            value="3"
                            checked={ dateRange === "3" }
                            onChange={ (e) => {
                                dateRangeHandler(e.target.value);
                            } }
                        />
                        <label htmlFor="radio-one-date-range" className=" ">
                            3 { t("Months") }
                        </label>
                        <input
                            disabled={ loadingSalesSummary }
                            type="radio"
                            id="radio-two-date-range"
                            name="switch-one-date-range"
                            value="6"
                            checked={ dateRange === "6" }
                            onChange={ (e) => {
                                dateRangeHandler(e.target.value);
                            } }
                        />
                        <label htmlFor="radio-two-date-range" className=" ">
                            6 { t("Months") }
                        </label>
                        <input
                            disabled={ loadingSalesSummary }
                            type="radio"
                            id="radio-three-date-range"
                            name="switch-one-date-range"
                            value="12"
                            checked={ dateRange === "12" }
                            onChange={ (e) => {
                                dateRangeHandler(e.target.value);
                            } }
                        />
                        <label htmlFor="radio-three-date-range" className=" ">
                            1 { t("Year") }
                        </label>
                    </div>
                    <div className="inline-block">
                        <Select
                            styles={ customStyles(selectedGroupBy) }
                            className="block"
                            placeholder={ t("Group-By") }
                            options={ groupBy }
                            value={ selectedGroupBy }
                            onChange={ (event) => setSelectedGroupBy(event) }
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 items-end gap-5 mx-auto mt-10">
                    <div className="flex flex-col w-full sm:w-full md:w-48">
                        <div className="flex items-center   py-1">{ t("Choose-the-date") }:</div>{ " " }
                        <ReactDatePicker
                            showMonthDropdown
                            showYearDropdown
                            disabled={ loadingSalesSummary }
                            dateFormat="yyyy-MM-dd"
                            customInput={ <DPCustomInput /> }
                            selected={ chooseDate }
                            onChange={ (date) => chooseDatePickHandler(date) }
                            locale={ selectedLanguage.value }
                        />
                    </div>
                    <div className="flex flex-col w-full sm:w-full md:w-48">
                        <div className="flex items-center   py-1">{ t("End-date") + "(" + t("Optional") + ")" }</div>
                        <ReactDatePicker
                            showMonthDropdown
                            showYearDropdown
                            disabled={ loadingSalesSummary }
                            dateFormat="yyyy-MM-dd"
                            selected={ endDate }
                            onChange={ (date) => endDatePickHandler(date) }
                            customInput={ <DPCustomInput /> }
                            locale={ selectedLanguage.value }
                        />
                    </div>

                    <button
                        disabled={ loadingSalesSummary }
                        onClick={ getChartHandler }
                        className="border  w-full sm:w-full md:w-48 flex flex-row rounded justify-center items-center  bg-gfCoral hover:bg-opacity-75 font-MulishBold  px-4 py-2 focus:outline-none"
                    >
                        { t("Go") }!
                    </button>
                </div>
            </div>

            <div className="mt-20 w-full">
                { receivedSalesSummary && receivedSalesSummary.length !== 0 && (
                    <div>
                        { cardType === "sales" ? (
                            <CardSales chooseDate={ chooseDate } endDate={ endDate !== null ? endDate : new Date() } groupBy={ selectedGroupBy !== null ? selectedGroupBy.value : GROUP_BY } />
                        ) : (
                            <CardUsage cardUsage={ receivedSalesSummary } chooseDate={ chooseDate } endDate={ endDate !== null ? endDate : new Date() } groupBy={ selectedGroupBy !== null ? selectedGroupBy.value : GROUP_BY } />
                        ) }
                    </div>
                ) }
            </div>
        </Container>
    );
}
