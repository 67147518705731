/* eslint-disable import/no-anonymous-default-export */

import { ERROR_SALES_SUMMARY, LOADING_SALES_SUMMARY, RECEIVED_SALES_SUMMARY, RESET_SALES_SUMMARY } from "../actions/SalesSummaryActions";

const initialState = {
    loadingSalesSummary: false,
    receivedSalesSummary: false,
    errorSalesSummary: false,
    summary: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_SALES_SUMMARY:
            return {
                ...state,
                loadingSalesSummary: true,
                receivedSalesSummary: false,
                errorSalesSummary: false,
            };

        case RECEIVED_SALES_SUMMARY:
            return {
                ...state,
                loadingSalesSummary: false,
                receivedSalesSummary: action.payload.data,
                errorSalesSummary: false,
                summary: action.payload.summary
            };

        case ERROR_SALES_SUMMARY:
            return {
                ...state,
                loadingSalesSummary: false,
                receivedSalesSummary: false,
                errorSalesSummary: action.payload,
            };

        case RESET_SALES_SUMMARY:
            return {
                ...state,
                ...initialState,
            };

        default:
            return state;
    }
}
