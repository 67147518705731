import React, { useState, useRef } from "react";
import { exportComponentAsJPEG, exportComponentAsPNG } from "react-component-export-image";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
    CartesianGrid,
    Cell,
    ComposedChart,
    Label,
    Legend,
    Line,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { getCSVReoprtWeeklyUsage } from "../../../../../actions/WeeklyBreakdownActions";
import { GF_CORAL, GF_DARK_BLUE, GF_LIGHT_BLUE, GF_PERIWINKLE } from "../../../../../utils/Constants";
import MessageAlert from "../../../../Alert/MessageAlert";
import CardInfoWidget from "../../../../CardInfoWidget";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default function WeeklyCardUsage({ chooseDate, endDate }) {
    const { receivedWeeklyBreakdown, summary } = useSelector((state) => state.weeklyBreakdown);
    const { tagCurrency } = useSelector((state) => state.tag);
    const { t } = useTranslation();
    const componentRef = useRef();
    const dispatch = useDispatch();

    const rightYAxis = (props) => {
        const { x, y, payload } = props;
        return (
            <g>
                <text className="text-sm font-MulishBold text-right" x={x} y={y} dy={5} fill={GF_LIGHT_BLUE}>
                    {parseFloat(payload.value).toFixed(2)}
                </text>

                <text className="text-sm font-MulishBold text-right" x={x} y={y + 15} dy={5} fill={GF_LIGHT_BLUE}>
                    {tagCurrency}
                </text>
            </g>
        );
    };

    const leftYAxis = (props) => {
        const { x, y, payload } = props;
        return (
            <g>
                <text className="text-sm font-MulishBold text-right" x={x} y={y} dy={5} dx={-25} fill={GF_LIGHT_BLUE}>
                    {payload.value}
                </text>
            </g>
        );
    };

    const CustomizedTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="flex flex-col p-4 border bg-white rounded border-gfDarkSand font-MulishBold text-sm space-y-2 shadow-md">
                    <div className="py-1">{t(label ? label : payload[0].payload.day_of_week)}</div>
                    {(chartType === "operations" || chartType === "both") && (
                        <div className="">
                            <span className="text-gfCoral">{` ${t("Number-of-transactions")} : ${
                                payload[0].payload.operations
                            }`}</span>
                        </div>
                    )}

                    {(chartType === "amount" || chartType === "both") && (
                        <div className="flex flex-row">
                            <span className="text-gfLightBlue mr-1">{`${t("Total-Spend")} : `}</span>
                            <div className="flex justify-start text-gfLightBlue">
                                <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={payload[0].payload.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                                <span className="ml-2">{` ${tagCurrency}`}</span>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };

    const customizedXAxis = (props) => {
        const { x, y, payload } = props;

        return (
            <g>
                <text x={x - 20} y={y + 30} fill={GF_DARK_BLUE}>
                    {t(payload.value)}
                </text>
            </g>
        );
    };

    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;

        return (
            <span style={{ color }}>{`${value === "amount" ? t("Total-Spend") : t("Number-of-transactions")}`}</span>
        );
    };

    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <div className="flex flex-row gap-5 justify-center items-center">
                {payload.map((entry, index) => (
                    <div key={index} className="flex flex-row items-center gap-1">
                        <div style={{ width: "10px", height: "10px", backgroundColor: entry.payload.fill }}></div>
                        <div style={{ color: entry.payload.fill }}>{t(entry.payload.day_of_week)}</div>
                    </div>
                ))}
            </div>
        );
    };

    const COLORS = ["#172352", "#FF666E", "#F8F1EC", "#5B6282", "#D3D3E9", "#EEE1D8", "#BEBAB7"];

    const [chartType, setChartType] = useState("operations");

    const printReceipt = useReactToPrint({
        content: () => componentRef.current,
    });

    const getReport = () => {
        dispatch(getCSVReoprtWeeklyUsage(chooseDate, endDate));
    };

    const savePDF = () => {
        var divHeight = document.getElementById("myDiv").clientHeight;
        var divWidth = document.getElementById("myDiv").clientWidth;
        var ratio = divHeight / divWidth;

        html2canvas(componentRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            let width = pdf.internal.pageSize.getWidth();
            let height = pdf.internal.pageSize.getHeight();
            let h = ratio * width;
            pdf.addImage(imgData, "JPEG", 0, 0, width, h);
            pdf.save("myPage.pdf"); //Download the rendered PDF.
        });
    };

    return (
        <div>
            <div className="flex flex-row justify-end items-center">
                <button
                    className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
                    onClick={() => exportComponentAsPNG(componentRef)}
                >
                    {t("save-png")}
                </button>

                <button
                    className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
                    onClick={() => exportComponentAsJPEG(componentRef)}
                >
                    {t("save-jpeg")}
                </button>
                <button className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2" onClick={savePDF}>
                    {t("export-pdf")}
                </button>
                <button className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2" onClick={getReport}>
                    {t("export-csv")}
                </button>
            </div>
            <div ref={componentRef} id="myDiv">
                {receivedWeeklyBreakdown && summary && receivedWeeklyBreakdown.length !== 0 && (
                    <div className="flex flex-col mt-20 space-y-20">
                        <div className="flex flex-col">
                            <div className="text-2xl font-MulishBold text-gfDarkBlue pl-5 pt-5 mb-4">{`${t(
                                "weekly-breakdown"
                            )}- ${t("Card-Usage")} -> ${chooseDate}  -  ${endDate}`}</div>
                            <div className="grid grid-cols-1  md:grid-cols-2 gap-5 md:gap-20 mx-auto ">
                                <CardInfoWidget
                                    bottomLabel={t("Number-of-transactions")}
                                    isCurrency={false}
                                    value={summary.total_operations}
                                    labelColor={"text-gfDarkBlue"}
                                    valueColor={"text-gfDarkBlue"}
                                />
                                <CardInfoWidget
                                    bottomLabel={t("Total-Spend")}
                                    isCurrency
                                    value={summary.total_amount}
                                    labelColor={"text-gfLightBlue"}
                                    valueColor={"text-gfLightBlue"}
                                />
                            </div>
                        </div>

                        {receivedWeeklyBreakdown && receivedWeeklyBreakdown.length !== 0 && (
                            <div className="grid gap-3 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 ">
                                {receivedWeeklyBreakdown.map((item) => (
                                    <div
                                        key={item.day_of_week}
                                        className="flex flex-col border rounded text-sm shadow-lg bg-white border-gfDarkSand"
                                    >
                                        <div className=" flex flex-col rounded p-2 bg-gfSand">
                                            <div className="flex flex-row 2xl:flex-col 2xl:justify-center items-center py-1">
                                                <div className="mr-2">{`${t("Total-Spend")}:`}</div>
                                                <div>
                                                    <div className="flex justify-end text-gfLightBlue font-MulishBold">
                                                        <CurrencyFormat
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            value={item.amount}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        />
                                                        <span className="ml-2 text-xs">{` ${tagCurrency}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-row py-1 2xl:flex-col 2xl:justify-center items-center">
                                                <div className="mr-2">{`${t("Transactions")}:`}</div>
                                                <div className="font-MulishBold">{item.operations}</div>
                                            </div>
                                            <div className="flex flex-row py-1 2xl:flex-col 2xl:justify-center items-center">
                                                <div className="mr-2">{`${t("Card-Usage")} %:`}</div>
                                                <div className="font-MulishBold">
                                                    {item.operations_percentage.toFixed(2)} %
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center font-MulishBold py-2 text-gfLightBlue text-lg">
                                            {t(`${item.day_of_week}`)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="flex flex-col">
                            <div className="mx-auto py-5 text-2xl font-MulishBold text-gfLightBlue">
                                {t("Number-of-transactions")}
                            </div>

                            {receivedWeeklyBreakdown !== null && summary.total_operations !== 0 ? (
                                <div className="flex justify-center w-full h-72">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart
                                            width={700}
                                            height={700}
                                            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                                        >
                                            <Pie
                                                data={receivedWeeklyBreakdown !== null ? receivedWeeklyBreakdown : []}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                // label={renderCustomizedLabel}
                                                outerRadius={120}
                                                fill="#8884d8"
                                                dataKey="operations"
                                                width={700}
                                                height={700}
                                            >
                                                {receivedWeeklyBreakdown &&
                                                    receivedWeeklyBreakdown.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={COLORS[index % COLORS.length]}
                                                        />
                                                    ))}
                                            </Pie>
                                            <Legend content={renderLegend} dataKey="day_of_week" />
                                            <Tooltip content={CustomizedTooltip} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                            ) : (
                                <MessageAlert message={t("No-data-was-found-for-the-specified-dates")} />
                            )}
                        </div>

                        <div className="flex flex-col">
                            <div className="mx-auto py-5 text-2xl font-MulishBold text-gfLightBlue">
                                {t("Number-of-transactions")} vs {t("Total-Spend")}
                            </div>

                            {receivedWeeklyBreakdown !== null && summary.total_operations !== 0 ? (
                                <div>
                                    <div>
                                        <div className="h-10 mb-5 sm:mb-5 md:mb-0 " id="switch-field">
                                            <input
                                                type="radio"
                                                id="radio-one-card-type1"
                                                name="switch-one-card-type1"
                                                value="operations"
                                                checked={chartType === "operations"}
                                                onChange={(e) => {
                                                    setChartType(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="radio-one-card-type1" className=" ">
                                                {t("Number-of-transactions")}
                                            </label>
                                            <input
                                                type="radio"
                                                id="radio-two-card-type2"
                                                name="switch-two-card-type2"
                                                value="amount"
                                                checked={chartType === "amount"}
                                                onChange={(e) => {
                                                    setChartType(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="radio-two-card-type2" className=" ">
                                                {t("Total-Spend")}
                                            </label>
                                            <input
                                                type="radio"
                                                id="radio-two-card-type3"
                                                name="switch-two-card-type3"
                                                value="both"
                                                checked={chartType === "both"}
                                                onChange={(e) => {
                                                    setChartType(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="radio-two-card-type3" className=" ">
                                                {t("Both")}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex justify-center w-full h-600">
                                        {chartType === "operations" && (
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    margin={{
                                                        top: 20,
                                                        right: 40,
                                                        left: 40,
                                                        bottom: 20,
                                                    }}
                                                    data={
                                                        receivedWeeklyBreakdown !== null ? receivedWeeklyBreakdown : []
                                                    }
                                                    width={500}
                                                    height={300}
                                                >
                                                    <CartesianGrid stroke={GF_PERIWINKLE} />
                                                    <XAxis
                                                        tick={customizedXAxis}
                                                        dataKey="day_of_week"
                                                        stroke={GF_DARK_BLUE}
                                                    />
                                                    <YAxis
                                                        yAxisId="left"
                                                        tick={leftYAxis}
                                                        dataKey="operations"
                                                        stroke={GF_DARK_BLUE}
                                                    >
                                                        <Label
                                                            className="font-MulishBold"
                                                            angle={-90}
                                                            position="insideLeft"
                                                        >
                                                            {t("Transactions")}
                                                        </Label>
                                                    </YAxis>
                                                    <Tooltip content={CustomizedTooltip} />
                                                    <Legend verticalAlign="top" formatter={renderColorfulLegendText} />
                                                    <Line
                                                        yAxisId="left"
                                                        type="linear"
                                                        dataKey="operations"
                                                        stroke={GF_CORAL}
                                                        strokeWidth={2}
                                                    />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        )}

                                        {chartType === "amount" && (
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    margin={{
                                                        top: 20,
                                                        right: 40,
                                                        left: 40,
                                                        bottom: 20,
                                                    }}
                                                    data={
                                                        receivedWeeklyBreakdown !== null ? receivedWeeklyBreakdown : []
                                                    }
                                                    width={500}
                                                    height={300}
                                                >
                                                    <CartesianGrid stroke={GF_PERIWINKLE} />
                                                    <XAxis
                                                        tick={customizedXAxis}
                                                        dataKey="day_of_week"
                                                        stroke={GF_DARK_BLUE}
                                                    />
                                                    <YAxis
                                                        yAxisId="right"
                                                        tick={rightYAxis}
                                                        dataKey="amount"
                                                        orientation="right"
                                                        stroke={GF_DARK_BLUE}
                                                    >
                                                        <Label
                                                            className="font-MulishBold"
                                                            angle={-90}
                                                            position="insideRight"
                                                        >
                                                            {t("Amount")}
                                                        </Label>
                                                    </YAxis>
                                                    <Tooltip content={CustomizedTooltip} />
                                                    <Legend verticalAlign="top" formatter={renderColorfulLegendText} />
                                                    <Line
                                                        yAxisId="right"
                                                        type="linear"
                                                        dataKey="amount"
                                                        stroke={GF_LIGHT_BLUE}
                                                        strokeWidth={2}
                                                    />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        )}

                                        {chartType === "both" && (
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    margin={{
                                                        top: 20,
                                                        right: 40,
                                                        left: 40,
                                                        bottom: 20,
                                                    }}
                                                    data={
                                                        receivedWeeklyBreakdown !== null ? receivedWeeklyBreakdown : []
                                                    }
                                                    width={500}
                                                    height={300}
                                                >
                                                    <CartesianGrid stroke={GF_PERIWINKLE} />
                                                    <XAxis
                                                        tick={customizedXAxis}
                                                        dataKey="day_of_week"
                                                        stroke={GF_DARK_BLUE}
                                                    />
                                                    <YAxis
                                                        yAxisId="left"
                                                        tick={leftYAxis}
                                                        dataKey="operations"
                                                        stroke={GF_DARK_BLUE}
                                                    >
                                                        <Label
                                                            className="font-MulishBold"
                                                            angle={-90}
                                                            position="insideLeft"
                                                        >
                                                            {t("Transactions")}
                                                        </Label>
                                                    </YAxis>
                                                    <YAxis
                                                        yAxisId="right"
                                                        tick={rightYAxis}
                                                        dataKey="amount"
                                                        orientation="right"
                                                        stroke={GF_DARK_BLUE}
                                                    >
                                                        <Label
                                                            className="font-MulishBold"
                                                            angle={-90}
                                                            position="insideRight"
                                                        >
                                                            {t("Amount")}
                                                        </Label>
                                                    </YAxis>
                                                    <Tooltip content={CustomizedTooltip} />
                                                    <Legend verticalAlign="top" formatter={renderColorfulLegendText} />
                                                    <Line
                                                        yAxisId="right"
                                                        type="linear"
                                                        dataKey="amount"
                                                        stroke={GF_LIGHT_BLUE}
                                                        strokeWidth={2}
                                                    />
                                                    <Line
                                                        yAxisId="left"
                                                        type="linear"
                                                        dataKey="operations"
                                                        stroke={GF_CORAL}
                                                        strokeWidth={2}
                                                    />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <MessageAlert message={t("No-data-was-found-for-the-specified-dates")} />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
