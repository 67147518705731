import { ERROR_CARD_STOCK, LOADING_CARD_STOCK, RECEIVED_CARD_STOCK } from "../actions/CardStockActions";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    loadingCardStock: false,
    receivedCardStock: null,
    errorCardStock: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_CARD_STOCK:
            return {
                ...state,
                loadingCardStock: true,
                receivedCardStock: null,
                errorCardStock: null,
            };

        case RECEIVED_CARD_STOCK:
            return {
                ...state,
                loadingCardStock: false,
                receivedCardStock: action.payload,
                errorCardStock: null,
            };

        case ERROR_CARD_STOCK:
            return {
                ...state,
                loadingCardStock: false,
                receivedCardStock: null,
                errorCardStock: action.payload,
            };
        default:
            return state;
    }
}
