import format from "date-fns/format";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Userpilot } from "userpilot";
import { RESET_CSV_REPORT } from "../../../actions/CommonActions";
import {
    exportCSVReportByChannels,
    getChannelList,
    getReportByChannels,
    getUserList,
    POPULATED_LIST,
    RESET_REPORT_BY_CHANNELS,
} from "../../../actions/ReportByChannelActions";
import { TAG_CHANGED } from "../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../actions/TerminalActions";
import ButtonLoading from "../../../assets/svg/ButtonLoading";
import { getCalanderLocale } from "../../../utils/CalanderLocale";
import { GF_DARK_BLUE } from "../../../utils/Constants";
import { checkLoggedInUserPermissions } from "../../../utils/Helper";
import FailAlert from "../../Alert/FailAlert";
import MessageAlert from "../../Alert/MessageAlert";
import CardInfoWidget from "../../CardInfoWidget";
import CardTransferUsersDropdown from "../../Dropdown/CardTransferUsersDropdown";
import ChannelListDropdown from "../../Dropdown/ChannelListDropdown";
import Container from "../../UIComponents/Container";
import Table from "../../UIComponents/Table";
import PrintEntireReport from "./PrintEntireReport";
import PrintOne from "./PrintOne";

export default function ReportByChannel () {
    const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ ref }
            value={ value }
            onClick={ onClick }
            readOnly={ true }
            className="border border-gfPeriwinkle cursor-pointer text-center w-full rounded hover:bg-gfPeriwinkle px-8 py-2 focus:outline-none"
        />
    ));

    const fromRef = useRef(null);
    const toRef = useRef(null);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(null);
    const [selectedChannelID, setSelectedChannelID] = useState({
        card_brands: [],
        id: -1,
        name: t("All"),
        terminals: [],
    });
    const [user, setUser] = useState({ id: -1, firstName: t("All"), lastName: "" });

    const [printDetails, setPrintDetails] = useState({});
    const [printOneVisibility, setPrintOneVisibility] = useState(false);

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_REPORT_BY_CHANNELS });
        dispatch({ type: RESET_CSV_REPORT });
        dispatch(getChannelList());
        dispatch(getUserList());
        getDataFirstCall();

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_REPORT_BY_CHANNELS });
            dispatch({ type: RESET_CSV_REPORT });
            dispatch(getChannelList());
            dispatch(getUserList());
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_REPORT_BY_CHANNELS });
            dispatch({ type: RESET_CSV_REPORT });
            dispatch(getChannelList());
            dispatch(getUserList());
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => { });
            window.removeEventListener(TERMINAL_CHANGED, () => { });
        };
    }, []);

    const {
        loadingChannelList,
        receivedChannelList,
        errorChannelList,
        channelArray,

        loadingReportByChannel,
        receivedReportByChannel,
        errorReportByChannel,
        populatedList,
        channelSummary,

        loadingUserList,
        receivedUserList,
        errorUserList,
    } = useSelector((state) => state.reportByChannel);

    const { tagCurrency } = useSelector((state) => state.tag);

    const { loadingCSV, errorCSV } = useSelector((state) => state.commonReducer);
    const { selectedLanguage } = useSelector((state) => state.language);

    const [printPreviewVisibility, setPrintPreviewVisibility] = useState(false);

    getCalanderLocale(selectedLanguage);

    const fromDatePickHandler = (date) => {
        setFromDate(date);
    };

    const toDatePickHandler = (date) => {
        setToDate(date);
    };

    const getDataFirstCall = () => {
        dispatch(getReportByChannels(format(new Date(), "yyyy-MM-dd"), format(new Date(), "yyyy-MM-dd"), -1, -1));
    };

    const getChartHandler = () => {
        let from = format(fromDate, "yyyy-MM-dd");
        let to;
        if (toDate !== null) {
            to = format(toDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }

        dispatch(getReportByChannels(from, to, selectedChannelID.id, user.id));
    };

    const handlePageClick = (event) => {
        dispatch({ type: POPULATED_LIST, payload: event.selected + 1 });
    };

    const exportCSV = () => {
        let from = format(fromDate, "yyyy-MM-dd");
        let to;
        if (toDate !== null) {
            to = format(toDate, "yyyy-MM-dd");
        } else {
            to = format(new Date(), "yyyy-MM-dd");
        }
        dispatch(exportCSVReportByChannels(from, to, selectedChannelID.id, user.id, "Report By Channel"));
    };

    const userOnChanged = (event) => {
        if (event.id === -1) {
            setUser({ id: -1, firstName: t("All"), lastName: "" });
        } else {
            setUser(event);
        }
    };

    const printPreview = (details) => {
        setPrintPreviewVisibility(!printPreviewVisibility);
        document.body.style.overflow = "hidden";
    };

    const printPreviewClose = () => {
        setPrintPreviewVisibility(!printPreviewVisibility);
        document.body.style.overflow = "unset";
    };

    const printOnePreview = (details) => {
        setPrintDetails((prev) => Object.assign(prev, details));
        setPrintOneVisibility(!printOneVisibility);
    };

    const printOnePreviewClose = () => {
        setPrintDetails({});
        setPrintOneVisibility(!printOneVisibility);
    };

    return (
        <Container title={ t("report-by-channel") } loading={ loadingChannelList || loadingReportByChannel }>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-5 items-end">
                <div className="flex flex-col">
                    <div className="flex items-center  py-1">{ t("Choose-the-date") }:</div>{ " " }
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        ref={ fromRef }
                        disabled={ loadingReportByChannel }
                        dateFormat="dd/MM/yyyy"
                        customInput={ <DPCustomInput /> }
                        selected={ fromDate }
                        onChange={ (date) => fromDatePickHandler(date) }
                        locale={ selectedLanguage.value }
                    />
                </div>
                <div className="flex flex-col">
                    <div className="flex items-center  py-1">{ t("End-date") + "(" + t("Optional") + ")" }:</div>{ " " }
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        ref={ toRef }
                        disabled={ loadingReportByChannel }
                        dateFormat="dd/MM/yyyy"
                        selected={ toDate }
                        onChange={ (date) => toDatePickHandler(date) }
                        customInput={ <DPCustomInput /> }
                        locale={ selectedLanguage.value }
                    />
                </div>

                <div className="flex flex-col w-full">
                    <div className="flex items-center  py-1">{ `${t("Channel")} :` }</div>
                    <ChannelListDropdown
                        value={ selectedChannelID }
                        options={ receivedChannelList }
                        onChangeHandler={ (event) => setSelectedChannelID(event) }
                    />
                </div>

                {false &&receivedUserList && receivedUserList.length !== 0 && (
                    <div className="flex flex-col w-full">
                        <div className="flex items-center   py-1">{t("User")}: </div>{" "}
                        <CardTransferUsersDropdown
                            value={user}
                            onChangeHandler={userOnChanged}
                            options={receivedUserList}
                        />
                    </div>
                )}

                <button
                    disabled={ loadingReportByChannel }
                    onClick={ getChartHandler }
                    className="h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75  font-MulishBold bg-gfCoral px-4 focus:outline-none"
                >
                    { loadingReportByChannel && <ButtonLoading color={ GF_DARK_BLUE } /> }
                    { t("Go") }!
                </button>
            </div>

            { ((receivedReportByChannel && receivedReportByChannel.length === 0) ||
                errorReportByChannel ||
                errorChannelList) && (
                    <div className="mt-10">
                        { receivedReportByChannel && receivedReportByChannel.length === 0 && (
                            <MessageAlert message={ t("There-are-no-information-to-display-for-this-date") } />
                        ) }
                        { errorReportByChannel && <FailAlert message={ errorReportByChannel.message } /> }
                        { errorChannelList && <FailAlert message={ errorReportByChannel.errorChannelList } /> }
                        { errorCSV && <FailAlert message={ errorCSV.message } /> }
                    </div>
                ) }

            { receivedReportByChannel && receivedReportByChannel.length !== 0 && (
                <div className="flex flex-col mt-20 space-y-5">
                    <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
                        <CardInfoWidget
                            bottomLabel={ t("number-of-operations") }
                            isCurrency={ false }
                            value={ channelSummary.total_operations }
                            labelColor={ "text-gfDarkBlue" }
                            valueColor={ "text-gfDarkBlue" }
                        />
                        <CardInfoWidget
                            bottomLabel={ t("Total-amount") }
                            isCurrency
                            value={ channelSummary.total_amount }
                            labelColor={ "text-gfLightBlue" }
                            valueColor={ "text-gfLightBlue" }
                        />
                    </div>

                    <div className="flex flex-col">
                        <div className="font-MulishBold">{ t("Summary-by-channel") }</div>
                        <div className="flex flex-wrap -mx-6 overflow-hidden sm:-mx-1  lg:-mx-8 xl:-mx-2 border rounded bg-gfSand">
                            { channelSummary.channel_summary.map((item) => (
                                <div
                                    key={ item.channel_id }
                                    className="my-6 px-6 w-1/6 overflow-hidden sm:my-1 sm:px-1 sm:w-1/3 md:my-4 md:px-4 md:w-1/2 lg:my-8 lg:px-8 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/6"
                                >
                                    <div key={ item.channel_id } className="flex flex-col">
                                        <span>{ item.channel_name }</span>
                                        <div key={ item.channel_id } className="flex flex-row">
                                            { t("number-of-operations") }: &nbsp;
                                            <span className="text-gfLightBlue font-MulishBold">{ item.operations }</span>
                                        </div>
                                        <div className="flex flex-row">
                                            { t("Total") }: &nbsp;
                                            <div className="flex justify-start text-gfLightBlue font-MulishBold">
                                                <CurrencyFormat
                                                    decimalScale={ 2 }
                                                    fixedDecimalScale={ true }
                                                    value={ item.total }
                                                    displayType={ "text" }
                                                    thousandSeparator={ true }
                                                />
                                                <span className="ml-2">{ ` ${tagCurrency}` }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
                        <div className="flex">
                            <button
                                onClick={ exportCSV }
                                className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                            >
                                { loadingCSV && <ButtonLoading color={ GF_DARK_BLUE } /> }
                                { t("Export-as-a-CSV-file") }
                            </button>
                            <button
                                onClick={ printPreview }
                                className="ml-3 px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                            >
                                { t("Print-Report") }
                            </button>
                        </div>
                        { receivedReportByChannel.length > process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                            <div>
                                <ReactPaginate
                                    previousLabel={ "prev" }
                                    nextLabel={ "next" }
                                    breakLabel={ "..." }
                                    breakClassName={ "break-me" }
                                    pageCount={
                                        receivedReportByChannel.length / process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                                    }
                                    marginPagesDisplayed={ 4 }
                                    pageRangeDisplayed={ 5 }
                                    onPageChange={ handlePageClick }
                                    containerClassName={ "pagination" }
                                    subContainerClassName={ "pages pagination" }
                                    activeClassName={ "active" }
                                />
                            </div>
                        ) }
                    </div>

                    <Table>
                        <thead className="bg-gfGrey text-gfDarkBlue text-sm font-MulishBlack">
                            <tr>
                                <th scope="col" className="px-4 py-3  text-center  uppercase tracking-wider">
                                    { t("Date") }
                                </th>
                                <th scope="col" className="px-2 py-3 text-center  uppercase tracking-wider">
                                    { t("Channel") } { t("Name") }
                                </th>
                                <th scope="col" className="px-2 py-3  text-center  uppercase tracking-wider">
                                    { t("User") }
                                </th>
                                <th scope="col" className="px-2 py-3  text-center  uppercase tracking-wider">
                                    { t("Sequence-number") }
                                </th>
                                <th scope="col" className="px-2 py-3  text-center  uppercase tracking-wider">
                                    { t("Card") }
                                </th>
                                <th scope="col" className="px-2 py-3   text-center  uppercase tracking-wider">
                                    { t("Amount") }
                                </th>
                                <th scope="col" className="px-2 py-3   text-center  uppercase tracking-wider">
                                    { t("Action") }
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gfPeriwinkle">
                            { receivedReportByChannel &&
                                populatedList &&
                                populatedList.map((channel, index) => (
                                    <tr key={ index }>
                                        <td className="px-4 py-4">
                                            <div className="flex justify-center items-center">
                                                { channel.reception_date }
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className="flex justify-center items-center">
                                                { channel.channel_name }
                                            </div>
                                        </td>
                                        <td className="px-2 py-4">
                                            { channel.user ? (
                                                <div className="flex justify-center items-center">
                                                    { `${channel.user.firstname} ${channel.user.lastname}` }
                                                </div>
                                            ) : (
                                                <div className="flex justify-center items-center">-</div>
                                            ) }
                                        </td>
                                        <td className="px-2 py-4">
                                            <div className="flex justify-center items-center">
                                                { channel.cmapi_sequence_number !== null
                                                    ? channel.cmapi_sequence_number
                                                    : "-" }
                                            </div>
                                        </td>
                                        <td className="px-2 py-4">
                                            <div className="flex justify-center items-center  text-gfLightBlue">
                                                { checkLoggedInUserPermissions("card-information") ? (
                                                    <Link
                                                        className="underline"
                                                        to={ `/gift-cards/card-information?token=${channel.cmapi_public_token !== null
                                                                ? channel.cmapi_public_token
                                                                : ""
                                                            }` }
                                                    >
                                                        { channel.cmapi_public_token !== null
                                                            ? channel.cmapi_public_token
                                                            : "-" }
                                                    </Link>
                                                ) : channel.cmapi_public_token !== null ? (
                                                    channel.cmapi_public_token
                                                ) : (
                                                    "-"
                                                ) }
                                            </div>
                                        </td>

                                        <td className="px-2 py-4 text-sm ">
                                            <div className="flex justify-center text-gfLightBlue font-MulishBold">
                                                <CurrencyFormat
                                                    decimalScale={ 2 }
                                                    fixedDecimalScale={ true }
                                                    value={ channel.amount }
                                                    displayType={ "text" }
                                                    thousandSeparator={ true }
                                                />
                                                <span className="ml-2">{ ` ${tagCurrency}` }</span>
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 text-center">
                                            <button
                                                onClick={ () => {
                                                    printOnePreview(channel);
                                                } }
                                                className="text-gfLightBlue text-opacity-75 focus:outline-none hover:underline"
                                            >
                                                { t("Print-ticket") }
                                            </button>
                                        </td>
                                    </tr>
                                )) }
                        </tbody>
                    </Table>
                </div>
            ) }
            <PrintEntireReport
                modalVisibility={ printPreviewVisibility }
                closeHandler={ printPreviewClose }
                fromDate={ fromDate }
                toDate={ toDate !== null ? toDate : new Date() }
            />

            <PrintOne
                modalVisibility={ printOneVisibility }
                closeHandler={ printOnePreviewClose }
                transferDetails={ printDetails }
            />
        </Container>
    );
}
