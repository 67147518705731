import React, { forwardRef, useState } from "react";
import { differenceInDays, format, getDay} from "date-fns";
import enGb from "date-fns/locale/en-GB";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import Container from "../../../UIComponents/Container";
import { useDispatch, useSelector } from "react-redux";
import MessageAlert from "../../../Alert/MessageAlert";
import FailAlert from "../../../Alert/FailAlert";
import { useEffect } from "react";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import WeeklyCardSales from "./Charts/WeeklyCardSales";
import WeeklyCardUsage from "./Charts/WeeklyCardUsage";
import {
    getWeeklyUsage,
    getWeeklySales,
    RESET_WEEKLY_SALES,
    RESET_WEEKLY_USAGE,
} from "../../../../actions/WeeklyBreakdownActions";
import { getCalanderLocale } from "../../../../utils/CalanderLocale";
import { Userpilot } from "userpilot";

registerLocale("en-gb", enGb);

export default function WeeklySales() {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay() - 6; // Gets day of the month (e.g. 21) - the day of the week (e.g. wednesday = 3) = Sunday (18th) - 6
    var last = first + 6; // last day is the first day + 6
    var startDate = new Date(curr.setDate(first));
    var endDateNew = new Date(curr.setDate(last));

    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));



    const [chooseDate, setChooseDate] = useState(startDate);
    const [weeks, setWeeks] = useState(true);
    const [endDate, setEndDate] = useState(lastSunday);
    const [cardType, setCardType] = useState("sales");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loadingWeeklyBreakdown, receivedWeeklyBreakdown, errorWeeklyBreakdown, summary } = useSelector(
        (state) => state.weeklyBreakdown
    );

    const { selectedLanguage } = useSelector((state) => state.language)

    getCalanderLocale(selectedLanguage)

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_WEEKLY_SALES });
        dispatch({ type: RESET_WEEKLY_USAGE });

        let choose = format(chooseDate, "yyyy-MM-dd");
        let end = format(endDateNew === null ? chooseDate : endDate, "yyyy-MM-dd");

        dispatch(getWeeklySales(choose, end));

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_WEEKLY_SALES });
            dispatch({ type: RESET_WEEKLY_USAGE });
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_WEEKLY_SALES });
            dispatch({ type: RESET_WEEKLY_USAGE });
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    const isMondays = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6;
    };

    const isSundays = (date) => {
        const day = getDay(date);
        return day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6;
    };

    const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly={true}
            className="border cursor-pointer text-sm text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 h-10 focus:outline-none"
        />
    ));

    const fromDatePickHandler = (date) => {
        setChooseDate(date);
    };

    const toDatePickHandler = (date) => {
        setEndDate(date);
    };

    const getChartHandler = () => {
        const diffDays = differenceInDays(chooseDate, endDate);

        if (diffDays === 1) {
            dispatch({ type: RESET_WEEKLY_SALES });
            setWeeks(false);
        } else {
            setWeeks(true);
            let choose = format(chooseDate, "yyyy-MM-dd");
            let end = format(endDate === null ? chooseDate : endDate, "yyyy-MM-dd");

            if (cardType === "sales") {
                dispatch({ type: RESET_WEEKLY_USAGE });
                dispatch(getWeeklySales(choose, end));
            } else {
                dispatch({ type: RESET_WEEKLY_SALES });
                dispatch(getWeeklyUsage(choose, end));
            }
        }
    };

    const setCardTypeHandler = (type) => {
        setCardType(type);
        const diffDays = differenceInDays(chooseDate, endDate);

        if (diffDays === 1) {
            dispatch({ type: RESET_WEEKLY_SALES });
            setWeeks(false);
        } else {
            setWeeks(true);
            let choose = format(chooseDate, "yyyy-MM-dd");
            let end = format(endDate === null ? chooseDate : endDate, "yyyy-MM-dd");

            if (type === "sales") {
                dispatch({ type: RESET_WEEKLY_USAGE });
                dispatch(getWeeklySales(choose, end));
            } else {
                dispatch({ type: RESET_WEEKLY_SALES });
                dispatch(getWeeklyUsage(choose, end));
            }
        }
    };

    return (
        <Container title={t("weekly-breakdown")} loading={loadingWeeklyBreakdown}>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5 items-end">
                <div className="h-10 mb-5 sm:mb-5 md:mb-0 " id="switch-field">
                    <input
                        type="radio"
                        id="radio-one-card-type"
                        name="switch-one-card-type"
                        value="sales"
                        checked={cardType === "sales"}
                        onChange={(e) => {
                            setCardTypeHandler(e.target.value);
                        }}
                        locale={selectedLanguage.value}
                    />
                    <label htmlFor="radio-one-card-type" className=" ">
                        {t("Card-Sales")}
                    </label>
                    <input
                        type="radio"
                        id="radio-two-card-type"
                        name="switch-two-card-type"
                        value="usage"
                        checked={cardType === "usage"}
                        onChange={(e) => {
                            setCardTypeHandler(e.target.value);
                        }}
                        locale={selectedLanguage.value}
                    />
                    <label htmlFor="radio-two-card-type" className=" ">
                    {t("Card-Usage")}
                    </label>
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex items-center   py-1">{t("Start-date-of-the-week")} :</div>
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        locale={selectedLanguage.value}
                        disabled={loadingWeeklyBreakdown}
                        dateFormat="dd/MM/yyyy"
                        customInput={<DPCustomInput />}
                        selected={chooseDate}
                        onChange={(date) => fromDatePickHandler(date)}
                        filterDate={isMondays}
                    />
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex items-center   py-1">{t("End-date-of-the-week")} :</div>
                    <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        locale={selectedLanguage.value}
                        disabled={loadingWeeklyBreakdown}
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date) => toDatePickHandler(date)}
                        customInput={<DPCustomInput />}
                        filterDate={isSundays}
                    />
                </div>

                <button
                    disabled={loadingWeeklyBreakdown}
                    onClick={getChartHandler}
                    className="border w-full sm:w-full md:w-32 flex flex-row rounded justify-center items-center  bg-gfCoral hover:bg-opacity-75 font-MulishBold  px-4 py-2 focus:outline-none"
                >
                    {t("Go")}!
                </button>
            </div>

            {!weeks && (
                <div className="border-gfCoral bg-red-100 rounded text-gfCoral py-2 px-5 my-4 mx-auto">
                    Selected week is wrong
                </div>
            )}

            {((receivedWeeklyBreakdown && receivedWeeklyBreakdown.length === 0) || errorWeeklyBreakdown) && (
                <div className="my-5">
                    {receivedWeeklyBreakdown.length === 0 && (
                        <MessageAlert message={t("There-are-no-information-to-display-for-this-date")} />
                    )}
                    {errorWeeklyBreakdown && <FailAlert message={errorWeeklyBreakdown.message} />}
                </div>
            )}

            <div className="mt-20 w-full">
                {receivedWeeklyBreakdown && summary.length !== 0 && (
                    <div>{cardType === "sales" ? <WeeklyCardSales chooseDate={format(chooseDate, "yyyy-MM-dd")} endDate={format(endDate === null ? chooseDate : endDate, "yyyy-MM-dd")} /> : <WeeklyCardUsage chooseDate={format(chooseDate, "yyyy-MM-dd")} endDate={format(endDate === null ? chooseDate : endDate, "yyyy-MM-dd")} />}</div>
                )}
            </div>
        </Container>
    );
}
