import React, { useRef, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import IdleTimer from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { TIMEOUT_LOGOUT, USER_LOGOUT } from "../../actions/AuthActions";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { randomString } from "../../utils/Helper";
import { useHistory } from "react-router";

ReactModal.setAppElement("#root");

export default function IdleTimerContainer() {
    const idleTimerRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { isAuthenticated, isTimeoutLogout } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory()

    const onIdle = () => {
        setModalIsOpen(true);
    };

    const logOutHandler = () => {
        setModalIsOpen(false);
        dispatch({ type: USER_LOGOUT, payload: history.location.pathname });        
        window.location.assign(
            `${process.env.REACT_APP_AUTH_SERVER_PATH}`
        );
    };

    const cancelTimer = () => {
        setModalIsOpen(false);
    };

    const timerCompleteHandler = () => {
        dispatch({ type: TIMEOUT_LOGOUT, payload: true });
        logOutHandler();
    };

    return (
        <div>
            {isTimeoutLogout && (
                <div className="mx-auto w-full flex justify-center">
                    <div className="mt-5 bg-gfCoral bg-opacity-25 border border-gfCoral text-gfCoral px-4 py-3 flex flex-row justify-between rounded" role="alert">
                        <span className="block sm:inline">{t("Your-session-has-expired-due-to-a-high-period-of-inactivity")}</span>
                    </div>
                </div>
            )}
            {isAuthenticated && <IdleTimer ref={idleTimerRef} timeout={1000 * 60 * 20} onIdle={onIdle}></IdleTimer>}
            <ReactModal
                isOpen={modalIsOpen}
                contentLabel="Minimal Modal Example"
                className="bg-gray-700 z-50"
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(23, 35, 82, 0.6)",
                        zIndex: 9999,
                        backdropFilter: "blur(8px)",
                    },
                    content: {
                        position: "absolute",
                        top: "160px",
                        left: "100px",
                        right: "100px",
                        bottom: "100px",

                        backgroundColor: "rgba(23, 35, 82, 0.0)",
                        overflow: "hidden",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        outline: "none",
                        padding: "50px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                    },
                }}
            >
                <div className="flex flex-col items-center mx-auto py-3 px-10 border w-2/6 rounded shadow-md bg-white text-gfDarkBlue font-MulishRegular">
                    <div className="flex flex-row justify-center items-center space-x-3">
                        <span className="py-5 ">{t("You-are-about-to-log-out-in")}</span>
                        <CountdownCircleTimer
                            isPlaying={modalIsOpen}
                            duration={60}
                            onComplete={timerCompleteHandler}
                            size={90}
                            strokeWidth={6}
                            ariaLabel={"Hey"}
                            colors={[
                                ["#004777", 0.33],
                                ["#F7B801", 0.33],
                                ["#A30000", 0.33],
                            ]}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                        <span className="py-5 ">{t("seconds") + "..."}</span>
                    </div>
                    <div className="flex justify-between w-full">
                        <button onClick={logOutHandler} className="px-3 h-10 rounded font-MulishBold bg-gfCoral hover:bg-opacity-75 focus:outline-none ">
                            {t("Yes-log-out")}
                        </button>
                        <button onClick={cancelTimer} className="px-3 h-10 rounded font-MulishBold bg-gfPeriwinkle hover:bg-opacity-75 focus:outline-none ">
                            {t("Cancel")}
                        </button>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
}
