/* eslint-disable import/no-anonymous-default-export */
import { RESET_EXPIRY_REPORT, ERROR_EXPIRY_REPORT, LOADING_EXPIRY_REPORT, PAGINATE_EXPIRY_LIST, RECEIVED_EXPIRY_REPORT } from "../../actions/ExpiryReportActions";
import { paginate } from "../../utils/Helper";

const PAGINATE_FIRST_PAGE = 1;

const initialState = {
    loadingExpiryReport: false,
    receivingExpiryReport: null,
    errorExpiryReport: null,
    populatedList: null,
    summary: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_EXPIRY_REPORT:
            return {
                ...state,
                loadingExpiryReport: true,
                receivingExpiryReport: null,
                errorExpiryReport: null,
                summary: null,
            };

        case RECEIVED_EXPIRY_REPORT:
            return {
                ...state,
                loadingExpiryReport: false,
                receivingExpiryReport: action.payload.data.cards,
                errorExpiryReport: null,
                populatedList: paginate(action.payload.data.cards, PAGINATE_FIRST_PAGE, process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS),
                summary: action.payload.summary,
            };

        case ERROR_EXPIRY_REPORT:
            return {
                ...state,
                loadingExpiryReport: false,
                receivingExpiryReport: null,
                errorExpiryReport: action.payload,
                summary: null,
            };

        case PAGINATE_EXPIRY_LIST:
            return {
                ...state,
                populatedList: paginate(state.receivingExpiryReport, action.payload, process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS),
            };

        case RESET_EXPIRY_REPORT:
            return {
                ...state,
                ...initialState,
            };

        default:
            return state;
    }
}
