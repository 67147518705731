/* eslint-disable no-fallthrough */
/* eslint-disable import/no-anonymous-default-export */

import {
    ACTIVATE_PRO_CARD_LOAD_CARD,
    ACTIVATE_PRO_CARD_OPERATION_ID,
    ADD_PROMO_CARD_TO_LIST,
    ERROR_FROM_TOKEN_VALIDITY,
    ERROR_TICKET_NUMBER,
    ERROR_TOKEN_RANGE_VALIDITY,
    ERROR_TO_TOKEN_VALIDITY,
    FAIL_PROMO_CARD_LOAD,
    FAIL_PROMO_CARD_PAYMENT,
    FROM_TOKEN_TEXT_COLOR,
    LOADING_FROM_TOKEN_VALIDITY,
    LOADING_PROMO_CARD_LOAD,
    LOADING_PROMO_CARD_PAYMENT,
    LOADING_TICKET_NUMBER,
    LOADING_TOKEN_RANGE_VALIDITY,
    LOADING_TO_TOKEN_VALIDITY,
    RECEIVED_FROM_TOKEN_VALIDITY,
    RECEIVED_TICKET_NUMBER,
    RECEIVED_TOKEN_RANGE_VALIDITY,
    RECEIVED_TO_TOKEN_VALIDITY,
    REMOVE_ITEM_FROM_LIST,
    RESET_ERRORS,
    RESET_ERRORS_ACTIVATE_PROMMO_CARDS,
    SUCCESS_PROMO_CARD_LOAD,
    SUCCESS_PROMO_CARD_PAYMENT,
    TEXT_FROM_TOKEN,
    TEXT_TO_TOKEN,
} from "../actions/ActivatePromoCardsActions";
import { randomString } from "../utils/Helper";

const initialState = {
    loadingTicketNumber: false,
    receivedTicketNumber: null,
    errorTicketNumber: null,

    loading_from_TokenValidity: false,
    received_from_TokenValidity: false,
    error_from_TokenValidity: null,
    fromTokenTextColor: " text-gfDarkBlue",
    fromTokenObject: null,
    textFromToken: "",

    loading_to_TokenValidity: false,
    received_to_TokenValidity: false,
    error_to_TokenValidity: null,
    toTokenTextColor: " text-gfDarkBlue",
    textToToken: "",

    loadingTokenRange: false,
    receivedTokenRange: null,
    errorTokenRange: null,

    loadingPromoCard: false,
    successPromoCard: null,
    failPromoCard: null,

    loadingPromoCardPayment: false,
    successPromoCardPayment: null,
    failPromoCardPayment: null,

    promoCards: [],

    itemAlreadyExist: false,

    activateProCardLoadCardOpID: null,
    activateProCardLoadPaymentOpID: null,    
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TEXT_FROM_TOKEN:
            return {
                ...state,
                textFromToken: action.payload,
            };

        case TEXT_TO_TOKEN:
            return {
                ...state,
                textToToken: action.payload,
            };

        case LOADING_TICKET_NUMBER:
            return {
                ...state,
                loadingTicketNumber: true,
                receivedTicketNumber: null,
                errorTicketNumber: null,
            };

        case RECEIVED_TICKET_NUMBER:
            return {
                ...state,
                loadingTicketNumber: false,
                receivedTicketNumber: action.payload.ticket,
                errorTicketNumber: null,
            };

        case ERROR_TICKET_NUMBER:
            return {
                ...state,
                loadingTicketNumber: false,
                receivedTicketNumber: null,
                errorTicketNumber: action.payload,
            };

            case ACTIVATE_PRO_CARD_OPERATION_ID:
                return {
                    ...state,
                    activateProCardLoadCardOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
                    activateProCardLoadPaymentOpID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,

                }

        case LOADING_FROM_TOKEN_VALIDITY:
            return {
                ...state,
                loading_from_TokenValidity: true,
                received_from_TokenValidity: false,
                error_from_TokenValidity: null,
                fromTokenTextColor: " text-gfDarkBlue",
                itemAlreadyExist: false,
            };

        case RECEIVED_FROM_TOKEN_VALIDITY:
            const tempResult = action.payload.result;
            const tempFromValidity = tempResult.is_valid;
            const color = tempFromValidity ? "text-kadozGreen" : "text-kadozRed";

            if (tempFromValidity) {
                delete tempResult.is_valid;
            }

            let haveToken = checkDuplicated(state.promoCards, tempResult.token);

            return {
                ...state,
                itemAlreadyExist: haveToken,
                loading_from_TokenValidity: false,
                received_from_TokenValidity: tempFromValidity,
                error_from_TokenValidity: null,
                fromTokenTextColor: color,
                fromTokenObject: tempFromValidity && !haveToken ? tempResult : null,
            };

        case ERROR_FROM_TOKEN_VALIDITY:
            return {
                ...state,
                loading_from_TokenValidity: false,
                received_from_TokenValidity: false,
                error_from_TokenValidity: action.payload,
                fromTokenTextColor: " text-gfDarkBlue",
            };

        case LOADING_TO_TOKEN_VALIDITY:
            return {
                ...state,
                loading_to_TokenValidity: true,
                received_to_TokenValidity: false,
                error_to_TokenValidity: null,
                toTokenTextColor: " text-gfDarkBlue",
            };

        case RECEIVED_TO_TOKEN_VALIDITY:
            const tempToResult = action.payload;
            const tempToValidity = tempToResult.is_valid;
            const toColor = tempToValidity ? "text-kadozGreen" : "text-kadozRed";

            return {
                ...state,
                loading_to_TokenValidity: false,
                received_to_TokenValidity: tempToValidity,
                error_to_TokenValidity: null,
                toTokenTextColor: toColor,
            };

        case ERROR_TO_TOKEN_VALIDITY:
            return {
                ...state,
                loading_to_TokenValidity: false,
                received_to_TokenValidity: false,
                error_to_TokenValidity: action.payload,
                toTokenTextColor: " text-gfDarkBlue",
            };

        case FROM_TOKEN_TEXT_COLOR:
            return {
                ...state,
                fromTokenTextColor: " text-gfDarkBlue",
                itemAlreadyExist: false,
            };

        case LOADING_TOKEN_RANGE_VALIDITY:
            return {
                ...state,
                loadingTokenRange: true,
                receivedTokenRange: null,
                errorTokenRange: null,
            };

        case RECEIVED_TOKEN_RANGE_VALIDITY:
            const result = action.payload.data;

            let tokens = [];
            if (result.length !== 0) {
                tokens = result.filter((card) => card.is_valid === true).map((item) => ({ token: item.token, amount: item.amount }));
            }

            return {
                ...state,
                loadingTokenRange: false,
                receivedTokenRange: tokens,
                promoCards: [...state.promoCards, ...tokens],
                errorTokenRange: null,
                textToToken: "",
                textFromToken: "",
                fromTokenTextColor: " text-gfDarkBlue",
                toTokenTextColor: " text-gfDarkBlue",
                received_to_TokenValidity: false,
                received_from_TokenValidity: false,
            };

        case ERROR_TOKEN_RANGE_VALIDITY:
            return {
                ...state,
                loadingTokenRange: false,
                receivedTokenRange: null,
                errorTokenRange: action.payload,
            };

        case ADD_PROMO_CARD_TO_LIST:
            return {
                ...state,
                promoCards: state.fromTokenObject !== null ? [...state.promoCards, state.fromTokenObject] : [],
                fromTokenObject: null,
                fromTokenTextColor: " text-gfDarkBlue",
                toTokenTextColor: " text-gfDarkBlue",
                received_to_TokenValidity: false,
                received_from_TokenValidity: false,
                textToToken: "",
                textFromToken: "",
            };

        case REMOVE_ITEM_FROM_LIST:
            const filtered = state.promoCards.filter((card) => card.token !== action.payload);

            return {
                ...state,
                promoCards: filtered,
            };

        case LOADING_PROMO_CARD_LOAD:
            return {
                ...state,
                loadingPromoCard: true,
                successPromoCard: null,
                failPromoCard: null,
            };

        case SUCCESS_PROMO_CARD_LOAD:
            return {
                ...state,
                loadingPromoCard: false,
                successPromoCard: action.payload,
                failPromoCard: null,
            };

        case FAIL_PROMO_CARD_LOAD:
            return {
                ...state,
                loadingPromoCard: false,
                successPromoCard: null,
                failPromoCard: action.payload,
            };

        case LOADING_PROMO_CARD_PAYMENT:
            return {
                ...state,
                loadingPromoCardPayment: true,
                successPromoCardPayment: null,
                failPromoCardPayment: null,
            };

        case SUCCESS_PROMO_CARD_PAYMENT:
            return {
                ...state,
                loadingPromoCardPayment: false,
                successPromoCardPayment: action.payload,
                failPromoCardPayment: null,
            };

        case FAIL_PROMO_CARD_PAYMENT:
            return {
                ...state,
                loadingPromoCardPayment: false,
                successPromoCardPayment: null,
                failPromoCardPayment: action.payload,
            };

            case RESET_ERRORS_ACTIVATE_PROMMO_CARDS:
                return {
                    ...state,
                    failPromoCardPayment: null,
                    failPromoCard: null,
                    errorTicketNumber: null,
                    error_from_TokenValidity: null,
                    error_to_TokenValidity: null,
                    errorTokenRange: null,
                }

        default:
            return state;
    }

    function checkDuplicated(array, token) {
        let hasItem = array.find((obj) => obj.token === token);
        return hasItem === undefined ? false : true;
    }
}
