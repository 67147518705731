import { useEffect } from "react";
import store from "../store/store";
import { ADMIMN_PERMISSIONS } from "./Constants";

export const isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
};

export const randomString = (length) => {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
};

export function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export function paginate(array, index, size) {
    index = Math.abs(parseInt(index));
    index = index > 0 ? index - 1 : index;
    size = parseInt(size);
    size = size < 1 ? 1 : size;

    return [
        ...array.filter((value, n) => {
            return n >= index * size && n < (index + 1) * size;
        }),
    ];
}

export function filterArray(arr, s) {
    const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, "");
    const re = RegExp(p);

    return arr.filter(
        (v) => String(v.first_name).toLowerCase().match(re) || String(v.last_name).toLowerCase().match(re)
    );
}

export function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function searchMerchants(array, keyword) {
    const p = Array.from(keyword).reduce((a, v, i) => `${a}[^${keyword.substr(i)}]*?${v}`, "");
    const re = RegExp(p);

    return array.filter((v) => String(v.name).toLowerCase().match(re));
}

export function exportCSVFile(csvData, fileName) {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

// export function checkAdminPermissions(guardName, permissions) {
//     if (permissions !== null) {
//         for (let i = 0; i < permissions.length; i++) {
//             for (let k = 0; k < permissions[i].list.length; k++) {
//                 if (permissions[i].list[k].guard_name === guardName) {
//                     return true;
//                 }
//             }
//         }
//     }

//     return false;
// }

export function checkUserHasAdminView(permissions) {
    if (permissions !== null) {
        for (let i = 0; i < permissions.length; i++) {
            for (let k = 0; k < permissions[i].list.length; k++) {
                for (let j = 0; j < ADMIMN_PERMISSIONS.length; j++) {
                    if (permissions[i].list[k].guard_name === ADMIMN_PERMISSIONS[j]) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
}

export function checkLoggedInUserPermissions(guardName) {
    if (store.getState().auth.receivedLoggedInUserTagPermissions !== null) {
        for (let i = 0; i < store.getState().auth.receivedLoggedInUserTagPermissions.length; i++) {
            for (let k = 0; k < store.getState().auth.receivedLoggedInUserTagPermissions[i].list.length; k++) {
                if (store.getState().auth.receivedLoggedInUserTagPermissions[i].list[k].guard_name === guardName) {
                    return true;
                }
            }
        }
    }

    return false;
}

export function setCheckAndDisable(array) {
    return array.forEach((permission) => {
        permission.list.forEach((item) => {
            item.isCheck = false;
            item.disabled = true;
        });
    });
}

export function checkCardRangeDirty(array) {
    const validFound = array.filter((item) => !item.valid);

    if (validFound.length !== 0) {
        return true;
    }

    return false;
}

export function checkEmptyArrySalaesByMerchnat(array) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].sales.length !== 0) {
            return true;
        }
    }
    return false;
}
