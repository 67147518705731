import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { updateDeliveryAddress } from "../../../../actions/DeliveryAddressActions";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import CloseIcon from "../../../../assets/svg/CloseIcon";

ReactModal.setAppElement("#root");

export default function UpdateDeliveryAddress({ isOpen, closeHandler, addressData }) {
    const { register, handleSubmit, errors, reset, setValue } = useForm({ shouldValidate: true, shouldDirty: true });
    const { countries } = useSelector((state) => state.countries);
    const { updatingDeliveryAddresses } = useSelector((state) => state.deliveryAddress);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onSubmit = (data) => {
        dispatch(updateDeliveryAddress(addressData.id, data)).then((success) => closeHandler(!isOpen));
    };

    useEffect(() => {
        const fields = ["city", "contact_email", "contact_name", "contact_phone", "delivery_name", "street", "street_number", "zip_code", "pobox", "default", "country_code"];

        if (isOpen && addressData) {
            setTimeout(() => fields.forEach((field) => setValue(field, addressData[field])), 600);
        }
    }, [isOpen]);

    return (
        <div>
            <ReactModal
                isOpen={isOpen}
                className="bg-gray-700 z-50"
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(23, 35, 82, 0.6)",
                        zIndex: 9999,
                        backdropFilter: "blur(8px)",
                    },
                    content: {
                        position: "absolute",
                        top: "50px",
                        left: "100px",
                        right: "100px",
                        bottom: "100px",
    
                        backgroundColor: "rgba(23, 35, 82, 0.0)",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        outline: "none",
                        padding: "50px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                    },
                }}
            >
                <div className="bg-white text-left px-6 text-base text-gfDarkBlue font-MulishRegular border-gfPeriwinkle rounded mx-auto py-3 border max-w-xl">
                    {/* <!--Title--> */}
                    <div className="flex justify-between items-center mb-5 ">
                        <div className="font-MulishBold">{t("Update-Delivery-Address")}</div>
                        <button
                            className="focus:outline-none"
                            onClick={() => {
                                closeHandler(!isOpen);
                                reset();
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    {/* <!--Body--> */}

                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col text-sm my-5 space-y-5">
                            <div className="flex flex-col">
                                <input
                                    name="delivery_name"
                                    maxLength="40"
                                    ref={register({ required: true })}
                                    type="text"
                                    autoComplete="off"
                                    className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                    placeholder={t("Delivery-Name") + " * - " + t("maximum-characters", { count: 40 })}
                                />
                                {errors.delivery_name && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                            </div>
                            <div className="flex flex-col">
                                <label className="font-MulishBold">{t("Address")}</label>
                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-col">
                                        <input
                                            maxLength="40"
                                            name="street"
                                            ref={register({ required: true })}
                                            type="text"
                                            autoComplete="off"
                                            className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                            placeholder={t("Street-Name") + " * - " + t("maximum-characters", { count: 40 })}
                                        />
                                        {errors.street && <p className="text-xs text-gfCoral  p-1">{t("Required")}</p>}
                                    </div>
                                    <div className="flex flex-col">
                                        <input
                                            name="street_number"
                                            maxLength="8"
                                            ref={register({ required: true })}
                                            type="text"
                                            autoComplete="off"
                                            className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                            placeholder={t("Street-Number") + " * - " + t("maximum-characters", { count: 8 })}
                                        />
                                        {errors.street_number && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                                    </div>
                                    <input
                                        name="pobox"
                                        maxLength="8"
                                        ref={register}
                                        type="text"
                                        autoComplete="off"
                                        className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                        placeholder={t("PO-Box") + " - " + t("maximum-characters", { count: 8 })}
                                    />
                                    <div className="flex flex-col">
                                        <input
                                            name="zip_code"
                                            ref={register({ required: true })}
                                            type="text"
                                            autoComplete="off"
                                            className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                            placeholder={t("Zip-code") + " *"}
                                        />
                                        {errors.zip_code && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                                    </div>
                                    <div className="flex flex-col">
                                        <input
                                            name="city"
                                            maxLength="40"
                                            ref={register({ required: true })}
                                            type="text"
                                            autoComplete="off"
                                            className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                            placeholder={t("City") + " * - " + t("maximum-characters", { count: 40 })}
                                        />
                                        {errors.city && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                                    </div>

                                    <div className="flex flex-col">
                                        <div className="inline-block relative w-full">
                                            <select
                                                name={"country_code"}
                                                ref={register({ required: true })}
                                                defaultValue=""
                                                className="block appearance-none w-full bg-white border border-gfPeriwinkle  px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:border-gfPeriwinkle"
                                            >
                                                <option key={-1} value="" disabled>
                                                    {t("Country") + " *"}
                                                </option>
                                                {countries &&
                                                    countries.length !== 0 &&
                                                    countries.map((c) => (
                                                        <option key={c.id} value={c.code_alpha2}>
                                                            {`${c.name} ${c.code_alpha3}`}
                                                        </option>
                                                    ))}
                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        {errors.country_code && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <label className="font-MulishBold">{t("Contact-Information")}</label>
                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-col">
                                        <input
                                            name="contact_name"
                                            maxLength="40"
                                            ref={register({ required: true })}
                                            type="text"
                                            autoComplete="off"
                                            className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                            placeholder={t("Name") + " * - " + t("maximum-characters", { count: 40 })}
                                        />
                                        {errors.contact_name && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                                    </div>
                                    <div className="flex flex-col">
                                        <input
                                            name="contact_email"
                                            ref={register({
                                                required: true,
                                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            })}
                                            type="text"
                                            autoComplete="off"
                                            className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                            placeholder={t("Email-address") + " *"}
                                        />
                                        {errors.contact_email && errors.contact_email.type === "required" && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                                        {errors.contact_email && errors.contact_email.type === "pattern" && <p className="text-xs text-gfCoral p-1">{t("Please-enter-valid-email")}</p>}
                                    </div>
                                    <div className="flex flex-col">
                                        <input
                                            name="contact_phone"
                                            maxLength="40"
                                            ref={register({ required: true, maxLength: 40, minLength: 5, pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g })}
                                            type="text"
                                            autoComplete="off"
                                            className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                                            placeholder={t("Phone-number") + " * - " + t("maximum-characters", { count: 40 })}
                                        />
                                        {errors.contact_phone && errors.contact_phone.type === "required" && <p className="text-xs text-gfCoral p-1">{t("Required")}</p>}
                                        {errors.contact_phone && errors.contact_phone.type === "pattern" && <p className="text-xs text-gfCoral p-1">{t("Phone-number-is-not-a-correct-format")}</p>}
                                        {errors.contact_phone && (errors.contact_phone.type === "maxLength" || errors.contact_phone.type === "minLength") && (
                                            <p className="text-xs text-gfCoral p-1">{t("Phone-number-must-have-numbers-between-10-20")}</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <label className="font-MulishBold">
                                <input name="default" ref={register} type="checkbox" className="mr-2" />
                                {t("Set-as-default-address")}
                            </label>

                            <div className="flex flex-row justify-between space-x-10">
                                <button
                                    type="submit"
                                    className="w-full flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                                >
                                    {updatingDeliveryAddresses && <ButtonLoading color={"#4A5568"} />}
                                    {!updatingDeliveryAddresses && <span>{t("Update")}</span>}
                                </button>

                                <input
                                    disabled={updatingDeliveryAddresses}
                                    onClick={() => reset()}
                                    type="button"
                                    value={t("Reset")}
                                    className="w-full border rounded px-4 py-2  bg-gfPeriwinkle font-MulishBold focus:outline-none  hover:bg-opacity-75  cursor-pointer"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </ReactModal>
        </div>
    );
}
