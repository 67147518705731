/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useOutsideAlerter } from "../../utils/Helper";

export default function DropdownComponent({ menuName, subMenus }) {
    const router = useHistory();
    const { isAuthenticated, user, userPermissions, hasAdminPrivilege, receivedLoggedInUserTagPermissions } =
        useSelector((state) => state.auth);

    // console.log(menuName)
    // console.log(subMenus)

    const [isOn, setIsOn] = useState(false);
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, () => {
        setIsOn(false);
    });

    const onClick = (e) => {
        e.preventDefault();
        setIsOn(!isOn);
    };

    const navigate = (e, link) => {
        e.preventDefault();
        setIsOn(!isOn);

        const lan = localStorage.getItem("i18nextLng");
        localStorage.setItem("path", link)
        router.push({
            pathname: link,
            search: `?lan=${lan}`,
        });



        console.log(link);
    };

    const hasPermission = (user, menuPermission) => {
        // if (user.super_admin) {
        //     return true;
        // }
        /// const userPermission = user.permissions;
        let visible = false;
        if (!menuPermission.guard_name) {
            return visible;
        }

        // if (receivedLoggedInUserTagPermissions !== null) {
        //     receivedLoggedInUserTagPermissions.forEach((userPermission) => {

        //         userPermission.list.forEach((item) => {
        //             console.log(item.guard_name);
        //             if (menuPermission.guard_name === item.guard_name) {
        //                 visible = true;
        //             }
        //         });
        //     });
        // }

        if (receivedLoggedInUserTagPermissions !== null) {
            for (let i = 0; i < receivedLoggedInUserTagPermissions.length; i++) {
                for (let k = 0; k < receivedLoggedInUserTagPermissions[i].list.length; k++) {
                    if (menuPermission.guard_name === receivedLoggedInUserTagPermissions[i].list[k].guard_name) {
                        console.log(
                            receivedLoggedInUserTagPermissions[i].list[k].guard_name,
                            menuPermission.guard_name
                        );
                        visible = true;
                    }
                }
            }
        }

        // menuPermission.permissions.forEach((permission) => {
        //     console.log(userPermission.indexOf(permission) !== -1);
        //     if (userPermission.indexOf(permission) === -1) {
        //         visible = false;
        //     }
        // });

        return visible;
    };

    return (
        <div ref={wrapperRef} className="relative text-gfDarkBlue">
            <button
                onClick={onClick}
                type="button"
                className="group bg-white rounded font-medium hover:text-gfLightBlue  inline-flex items-center text-base font-small  focus:outline-none"
            >
                <span className="capitalize">{menuName}</span>

                <svg
                    className="ml-2 h-5 w-5 "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>

            {isOn && (
                <>
                    <div
                        className="z-10 triangle origin-top-right absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                    >
                        {subMenus &&
                            !!user &&
                            subMenus
                                .filter((menu) => hasPermission(user, menu))
                                .map((sub, index) => (
                                    <a
                                        href="#"
                                        key={`menu${index}`}
                                        onClick={(e) => {
                                            navigate(e, sub.path);
                                        }}
                                        className={`${sub.color} block px-4 py-2 text-sm hover:text-white hover:bg-gfCoral capitalize`}
                                        role="menuitem"
                                    >
                                        {sub.name}
                                    </a>
                                ))}
                    </div>
                </>
            )}
        </div>
    );
}
