/* eslint-disable import/no-anonymous-default-export */
import { ERROR_PRODUCT_REPORT, LOADING_PRODUCT_REPORT, POPULATED_LIST, RECEIVED_PRODUCT_REPORT, RESET_PRODUCT_REPORT } from "../../actions/ProductReportActions";
import { paginate } from "../../utils/Helper";

const PAGINATE_FIRST_PAGE = 1;

const initialState = {
    loadingProductReport: false,
    receivingProductReport: null,
    errorProductReport: null,
    populatedList: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_PRODUCT_REPORT:
            return {
                ...state,
                loadingProductReport: true,
                receivingProductReport: null,
                errorProductReport: null,
            };

        case RECEIVED_PRODUCT_REPORT:
            return {
                ...state,
                loadingProductReport: false,
                receivingProductReport: action.payload,
                errorProductReport: null,
                populatedList: paginate(action.payload.products, PAGINATE_FIRST_PAGE, process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS),
            };

        case ERROR_PRODUCT_REPORT:
            return {
                ...state,
                loadingProductReport: false,
                receivingProductReport: null,
                errorProductReport: action.payload,
            };

        case POPULATED_LIST:
            return {
                ...state,
                populatedList: paginate(state.receivingProductReport.products, action.payload, process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS),
            };

        case RESET_PRODUCT_REPORT:
            return {
                ...state,
                ...initialState,
            };

        default:
            return state;
    }
}
