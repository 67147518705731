import { issuingAPI } from "../services/Axios";
import { exportCSVFile } from "../utils/Helper";

export const PREFIX = "@salesSummary/";

export const LOADING_SALES_SUMMARY = `${PREFIX}LOADING_SALES_SUMMARY`;
export const RECEIVED_SALES_SUMMARY = `${PREFIX}RECEIVED_SALES_SUMMARY`;
export const ERROR_SALES_SUMMARY = `${PREFIX}ERROR_SALES_SUMMARY`;

export const RESET_SALES_SUMMARY = `${PREFIX}RESET_SALES_SUMMARY`;

export const getCardSales = (fromDate, toDate, groupBy) => (dispatch, getState) => {
    dispatch({ type: LOADING_SALES_SUMMARY });

    const { tagCardBrands } = getState().tag;

    const params = {
        from_date: fromDate,
        to_date: toDate,
        group_by: groupBy,
        
    };

    return issuingAPI
        .get(`card-brand/${tagCardBrands[0]}/card-sale`, { params })
        .then((response) => {
            dispatch({ type: RECEIVED_SALES_SUMMARY, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_SALES_SUMMARY, payload: error });
            return error;
        });
};

export const getCardUsage = (fromDate, toDate, groupBy) => (dispatch, getState) => {
    dispatch({ type: LOADING_SALES_SUMMARY });

    const { tagCardBrands } = getState().tag;

    const params = {
        from_date: fromDate,
        to_date: toDate,
        group_by: groupBy,
    };

    issuingAPI
        .get(`card-brand/${tagCardBrands[0]}/card-use`, { params })
        .then((response) => {
            dispatch({ type: RECEIVED_SALES_SUMMARY, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_SALES_SUMMARY, payload: error });
            return error;
        });
};

export const LOADING_CSV_REPORT = `${PREFIX}LOADING_CSV_REPORT`;
export const RECEIVED_CSV_REPORT = `${PREFIX}RECEIVED_CSV_REPORT`;
export const ERROR_CSV_REPORT = `${PREFIX}ERROR_CSV_REPORT`;

// https://portalapi.dev.giftify.me/card-brand/{cardBrandId}/card-sale/stream

export const getCSVReoprtSales = (fromDate, toDate, groupBy) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT });

    const { tagCardBrands, selectedTag } = getState().tag;

    const params = {
        from_date: fromDate,
        to_date: toDate,
        tag: selectedTag.name,
        group_by: groupBy
    };

    const headers = {
        Accept: "text/csv",
    };

    const reportName = "SalesSummary-Sale";

    const fileName = `${reportName}-${fromDate}-${toDate}`;

    issuingAPI
        .get(`card-brand/${tagCardBrands[0]}/card-sale/stream`, { headers, params })
        .then((response) => {
            dispatch({ type: RECEIVED_CSV_REPORT, payload: response.data });
            exportCSVFile( response.data, fileName);
        })
        .catch((error) => {
            dispatch({ type: ERROR_CSV_REPORT, payload: error });
            return error;
        });
};

export const getCSVReoprtUsage = (fromDate, toDate, groupBy) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT });

    const { tagCardBrands, selectedTag } = getState().tag;

    const params = {
        from_date: fromDate,
        to_date: toDate,
        tag: selectedTag.name,
        group_by: groupBy
    };

    const headers = {
        Accept: "text/csv",
    };

    const reportName = "SalesSummary-Use";

    const fileName = `${reportName}-${fromDate}-${toDate}`;

    issuingAPI
        .get(`card-brand/${tagCardBrands[0]}/card-use/stream`, { headers, params })
        .then((response) => {
            dispatch({ type: RECEIVED_CSV_REPORT, payload: response.data });
            exportCSVFile(response.data, fileName);
        })
        .catch((error) => {
            dispatch({ type: ERROR_CSV_REPORT, payload: error });
            return error;
        });
};

export const SELCTED_CENTER_LIST = `${PREFIX}SELCTED_CENTER_LIST`;
export const SET_UN_SELECTED_CENTER = `${PREFIX}SET_UN_SELECTED_CENTER`;
export const SET_SELECTED_CENTER = `${PREFIX}SET_SELECTED_CENTER`;
export const LOOK_FOR_CENTER = `${PREFIX}LOOK_FOR_CENTER`;
