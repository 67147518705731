import i18n from "i18next";

const routes = [
    {
        path: "/card/load-card",
        name: i18n.t("Load-Card"),
        guard_name: "load-card",
    },
    {
        path: "/gift-cards/card-information",
        name: i18n.t("card-information"),
        guard_name: "card-information",
    },
    {
        path: "/gift-cards/card-transfer",
        name: i18n.t("card-to-card-transfer"),
        guard_name: "card-to-card-transfer",
    },
    {
        path: "/gift-cards/card-transfer_list",
        name: i18n.t("list-of-card-transfer"),
        guard_name: "list-of-card-transfer",
    },
    {
        path: "/promo-cards/activate-promo-cards",
        name: i18n.t("activate-promo-cards"),
        guard_name: "activate-promo-cards",
    },
    {
        path: "/promo-cards/order-promo-cards",
        name: i18n.t("order-promo-cards"),
        guard_name: "order-promo-cards",
    },
    {
        path: "/promo-cards/order-history",
        name: i18n.t("order-history"),
        guard_name: "order-history",
    },
    {
        path: "/promo-cards/delivery-address",
        name: i18n.t("delivery-addresses"),
        guard_name: "delivery-addresses",
    },
    {
        path: "/shops/shop-list",
        name: i18n.t("list-of-shops"),
        guard_name: "list-of-shops",
    },
    {
        path: "/shops/manage-shops",
        name: i18n.t("manage-shops"),
        guard_name: "manage-shops",
    },
    {
        path: "/online-sales/manage-sales",
        name: i18n.t("manage-sales"),
        guard_name: "manage-sales",
    },
    {
        path: "/online-sales/fees-report",
        name: i18n.t("fees-report"),
        guard_name: "fees-report",
    },
    {
        path: "/reports/shopping-center-balance",
        name: i18n.t("shopping-center-balance"),
        guard_name: "shopping-center-balance",
    },
    {
        path: "/reports/daily-report",
        name: i18n.t("daily-report"),
        guard_name: "daily-report",
    },
    {
        path: "/reports/channel-report",
        name: i18n.t("report-by-channel"),
        guard_name: "report-by-channel",
    },
    {
        path: "/reports/sales-summary",
        name: i18n.t("sales-summary"),
        guard_name: "sales-summary",
    },
    {
        path: "/reports/sales-by-merchant",
        name: i18n.t("sales-by-merchant"),
        guard_name: "sales-by-merchant",
    },
    {
        path: "/reports/weekly-sales",
        name: i18n.t("weekly-breakdown"),
        guard_name: "weekly-breakdown",
    },
    {
        path: "/reports/expiry-report",
        name: i18n.t("expiry-report"),
        guard_name: "expiry-report",
    },
    {
        path: "/reports/tokenization-report",
        name: i18n.t("tokenization-report"),
        guard_name: "tokenization-report",
    },
    {
        path: "/products/product-report",
        name: i18n.t("Product-report"),
        guard_name: "product-report",
    },
    {
        path: "/mvc-management/mvc-list",
        name: i18n.t("mvc-list"),
        guard_name: "mvc-list",
    },
    {
        path: "/mvc-management/mcv-mcv-transfer",
        name: i18n.t("Mvc-to-mvc-transfer"),
        guard_name: "mvc-to-mvc-transfer",
    },
    {
        path: "/mvc-management/mvc-transfer-list",
        name: i18n.t("MVC-transfer-History"),
        guard_name: "mvc-transfer",
    },
];

export default routes;
