import React from "react";
import CurrencyFormat from "react-currency-format";
import { useSelector } from "react-redux";

export default function CardInfoWidget({ bottomLabel, value, labelColor, valueColor, isCurrency }) {
    const { tagCurrency } = useSelector((state) => state.tag);

    return (
        <div className="rounded border-b-4 px-4 pt-1 bg-kadozLightGray border border-gfPeriwinkle">
            <div className="flex justify-center font-MulishBlack">
                <div className={`text-2xl ${valueColor} `}>{isCurrency ? <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={value} displayType={"text"} thousandSeparator={true} /> : value}</div>
                {isCurrency && <span className={`pl-2 ${valueColor}`}>{tagCurrency.toUpperCase()}</span>}
            </div>
            <div className={`flex justify-center pb-2 ${labelColor} font-MulishRegular`}>{bottomLabel}</div>
        </div>
    );
}
