import React from "react";
import ButtonLoading from "../../assets/svg/ButtonLoading";
import { GF_DARK_BLUE } from "../../utils/Constants";

export default function Button({ label, loading, onClick, type, disabled }) {
    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick}
            className="
            flex 
            justify-center 
            items-center 
            bg-gfCoral 
            text-gfDarkBlue
            rounded 
            font-MulishBold
            hover:bg-opacity-75
            py-2
            focus:outline-none">
            {loading && <ButtonLoading color={GF_DARK_BLUE} />}
            {label}
        </button>
    );
}
