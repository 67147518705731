/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Userpilot } from "userpilot";
import { getMVCPaymentMethodsFullList } from "../../../actions/MVCManagementAction";
import { TAG_CHANGED } from "../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../actions/TerminalActions";
import ActiveIcon from "../../../assets/svg/ActiveIcon";
import DeclineIcon from "../../../assets/svg/DeclineIcon";
import { checkLoggedInUserPermissions } from "../../../utils/Helper";
import FailAlert from "../../Alert/FailAlert";
import MessageAlert from "../../Alert/MessageAlert";
import Container from "../../UIComponents/Container";
import Table from "../../UIComponents/Table";
import CreatePaymentMethod from "./CreatePaymentMethod";

export default function MVCManagement() {
    const dispatch = useDispatch();

    useEffect(() => {
        Userpilot.reload();
        dispatch(getMVCPaymentMethodsFullList());

        window.addEventListener(TAG_CHANGED, () => {
            dispatch(getMVCPaymentMethodsFullList());
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch(getMVCPaymentMethodsFullList());
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    const { loadingMVCPaymentMethods, listMVCPaymentMethods, metaMVCPaymentMethods, errorMVCPaymentMethods } =
        useSelector((state) => state.mvc);
    const { tagCurrency } = useSelector((state) => state.tag);
    const { isSuperAdmin } = useSelector((state) => state.auth);

    const [createPaymentMethodModalVisibility, setCreatePaymentMethodModalVisibility] = useState(false);

    const { t } = useTranslation();

    const createPaymentMethod = () => {
        setCreatePaymentMethodModalVisibility(true);
    };

    const paymentMethodModalClose = () => {
        setCreatePaymentMethodModalVisibility(false);
    };

    return (
        <Container loading={loadingMVCPaymentMethods} title={t("MVC-list")}>
            <div className="">
                {isSuperAdmin && (
                    <div className="mb-6 flex justify-start w-full">
                        <button
                            onClick={createPaymentMethod}
                            className="border focus:outline-none py-2 px-8  font-MulishBold bg-gfCoral text-gfDarkBlue hover:bg-opacity-75 rounded"
                        >
                            {t("Create-a-payment-method")}
                        </button>
                    </div>
                )}

                {errorMVCPaymentMethods && <FailAlert message={errorMVCPaymentMethods.message} />}
                {listMVCPaymentMethods && listMVCPaymentMethods.length === 0 && (
                    <MessageAlert message={t("There-are-no-MVC-to-display")} />
                )}

                <div>
                    {listMVCPaymentMethods && listMVCPaymentMethods.length !== 0 && (
                        <Table>
                            <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-xs">
                                <tr>
                                    <th scope="col" className="py-3  text-center   uppercase tracking-wider">
                                        {t("MVC-Token")}
                                    </th>
                                    <th scope="col" className="py-3  text-center   uppercase tracking-wider">
                                        {t("Payment-method")}
                                    </th>
                                    <th scope="col" className="py-3  text-center   uppercase tracking-wider">
                                        {t("Payment-on-terminal")}
                                    </th>
                                    <th scope="col" className="py-3  text-center   uppercase tracking-wider">
                                        {t("Loads-promo-cards")}
                                    </th>
                                    {isSuperAdmin && (
                                        <th scope="col" className="py-3  text-center   uppercase tracking-wider">
                                            {t("Is-Temporary")}
                                        </th>
                                    )}
                                    {isSuperAdmin && (
                                        <th scope="col" className="py-3  text-center   uppercase tracking-wider">
                                            {t("Is-Correction-MVC")}
                                        </th>
                                    )}
                                    <th scope="col" className="py-3  text-center   uppercase tracking-wider">
                                        {t("Balance")}
                                    </th>

                                    <th scope="col" className="py-3 text-center   uppercase tracking-wider">
                                        {t("Actions")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gfPeriwinkle">
                                {listMVCPaymentMethods.map((method) => (
                                    <tr key={method.id}>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                            <div className="flex justify-center items-center underline text-gfLightBlue">
                                                {checkLoggedInUserPermissions("card-information") ? (
                                                    <Link
                                                        className="underline"
                                                        to={`/gift-cards/card-information?token=${method.mvc_token}`}
                                                    >
                                                        {method.mvc_token}
                                                    </Link>
                                                ) : (
                                                    method.mvc_token
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 text-center  whitespace-nowrap">{method.name}</td>
                                        <td className="px-2 py-4 text-center  whitespace-nowrap">
                                            <div className="flex justify-center items-center font-MulishBold">
                                                {method.requires_transhandler ? "Y" : "N"}
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <div className="flex justify-center items-center">
                                                {method.is_promo_loads ? (
                                                    <ActiveIcon w={15} h={16} />
                                                ) : (
                                                    <DeclineIcon w={15} h={16} />
                                                )}
                                            </div>
                                        </td>
                                        {isSuperAdmin && (
                                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <div className="flex justify-center items-center">
                                                    {method.is_temp ? (
                                                        <ActiveIcon w={15} h={16} />
                                                    ) : (
                                                        <DeclineIcon w={15} h={16} />
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {isSuperAdmin && (
                                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <div className="flex justify-center items-center">
                                                    {method.is_correction_mvc ? (
                                                        <ActiveIcon w={15} h={16} />
                                                    ) : (
                                                        <DeclineIcon w={15} h={16} />
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        <td className="px-6 py-4 w-48 whitespace-nowrap">
                                            <div className="flex justify-center text-gfLightBlue font-MulishBold">
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={method.balance}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                                <span className="ml-2">{` ${tagCurrency}`}</span>
                                            </div>
                                        </td>
                                        <td className="px-2 py-4 text-sm ">
                                            <div className="flex flex-col md:flex-row justify-between">
                                                <div className="flex justify-center items-center underline text-gfLightBlue">
                                                    {checkLoggedInUserPermissions("card-information") ? (
                                                        <Link
                                                            className="underline"
                                                            to={`/gift-cards/card-information?token=${method.mvc_token}`}
                                                        >
                                                            {t("View-transactions")}
                                                        </Link>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>

            <div>
                <CreatePaymentMethod
                    modalVisibility={createPaymentMethodModalVisibility}
                    closeHandler={paymentMethodModalClose}
                />
            </div>
        </Container>
    );
}
