/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCardInformation,
    getCardTransactionInformation,
    RESET_CARD_INFO,
} from "../../../../actions/CardInformationActions";
import MessageAlert from "../../../Alert/MessageAlert";
import FailAlert from "../../../Alert/FailAlert";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import Container from "../../../UIComponents/Container";
import Button from "../../../UIComponents/Button";
import InputWithClearButton from "../../../UIComponents/InputWithClearButton";
import BlockView from "./BlockView";
import UnblockView from "./UnblockView";
import Table from "../../../UIComponents/Table";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

import Tooltip from "react-simple-tooltip";
import { GF_DARK_BLUE, GF_PERIWINKLE } from "../../../../utils/Constants";
import { checkLoggedInUserPermissions } from "../../../../utils/Helper";
import PrintPreview from "./PrintPreview";
import { Userpilot } from "userpilot";

export default function CardInformation(props) {
    const [visibility, setVisibility] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [requiredValidation, setRequiredValidation] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);

    const dispatch = useDispatch();
    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_CARD_INFO });

        const params = new URLSearchParams(props.location.search);
        const token = params.get("token");
        if (token) {
            setCardNumber(token);
            dispatch(getCardInformation(token));
        }
    }, []);

    const { t } = useTranslation();

    const {
        loadingCardInformation,
        cardInformation,
        errorCardInformation,
        cardTransactionInfo,
        errorTransactionInfo,
        loadingCardTransactions,
        cardTransactionMeta,
    } = useSelector((state) => state.cardInformation);
    const { tagCurrency } = useSelector((state) => state.tag);
    const { isSuperAdmin } = useSelector((state) => state.auth);

    const [printPreviewVisibility, setPrintPreviewVisibility] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setVisibility(!visibility);
        document.body.classList.toggle("modal-active");
    };

    const closeModalHandler = (e) => {
        setVisibility(false);
    };

    const loadCardHandler = () => {
        if (cardNumber.length !== 0) {
            props.history.push({
                pathname: "/gift-cards/card-information",
                search: `?token=${cardNumber}`,
            });
            setRequiredValidation(false);
            //dispatch({ type: RESET_CARD_INFO });
            //dispatch(getCardInformation(cardNumber));
        } else {
            setRequiredValidation(true);
        }
    };

    const onKeyUpHandler = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            loadCardHandler();
        }
    };

    const onChangeAmountInput = (e) => {
        setRequiredValidation(false);
        setCardNumber(e.target.value);
    };

    const clearShopSearchHandler = () => {
        setCardNumber("");
        dispatch({ type: RESET_CARD_INFO });
    };

    const paginationHandler = (event) => {
        setSelectedPage(event.selected + 1);
        dispatch(getCardTransactionInformation(cardNumber, cardInformation.is_mvc, event.selected + 1));
    };

    const printPreview = () => {
        setPrintPreviewVisibility(!printPreviewVisibility);
    };

    const printPreviewClose = () => {
        setPrintPreviewVisibility(!printPreviewVisibility);
    };

    return (
        <Container title={t("card-information")}>
            <div>
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:max-w-xl mx-auto">
                        <InputWithClearButton
                            value={cardNumber}
                            onKeyUp={(event) => onKeyUpHandler(event)}
                            onChange={onChangeAmountInput}
                            onClear={clearShopSearchHandler}
                            placeholder={t("TOKEN")}
                        />
                        <Button loading={loadingCardInformation} label={t("Get-card-info")} onClick={loadCardHandler} />
                    </div>

                    <div className="mt-10">
                        {errorCardInformation && <FailAlert message={errorCardInformation.message} />}
                        {requiredValidation && <FailAlert message="Please enter card number" />}
                    </div>
                    <div>
                        {cardInformation && (
                            <div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 border-gfPeriwinkle border rounded shadow-lg p-5 md:py-10 md:px-20">
                                    <div className="space-y-5">
                                        <div className="flex flex-row space-x-2">
                                            <div className="text-xl font-MulishBold">{t("Card-balance")}:</div>
                                            <div className="flex justify-start text-gfLightBlue font-MulishBold text-xl">
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={cardInformation.balance_available}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                                <span className="ml-2">{` ${tagCurrency}`}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-row space-x-2">
                                            <div className="text-xl font-MulishBold">{t("Is-card-loadable")}:</div>
                                            <div className="flex justify-start text-giftifyDarkBlue  text-xl">
                                                {cardInformation.is_loadable ? (
                                                    <span className=" text-kadozGreen font-MulishBold uppercase">{t("Yes")}</span>
                                                ) : (
                                                    <span className=" text-giftifyDarkPink font-MulishBold uppercase">{t("No")}</span>
                                                )}
                                            </div>
                                        </div>
                                        {cardInformation.is_mvc !== "1" &&<div className="flex flex-row space-x-2">
                                            <div className="text-xl font-MulishBold">{t("Expiry-date")}:</div>
                                            <div className="flex justify-start text-gfLightBlue font-MulishBold text-xl">
                                                <span className="">{cardInformation.expiration_date}</span>
                                            </div>
                                        </div>}
                                        <div className="flex flex-row space-x-2">
                                            <div className="text-xl font-MulishBold">
                                                {t("Status")}:{" "}
                                                <span className="bg-gfGrey font-MulishBold px-2">
                                                    {String(cardInformation.status).toLocaleUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex flex-row space-x-2">
                                            <div className="text-xl font-MulishBold">{t("Card-brand")}:</div>
                                            <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                                                <span className="">{`${cardInformation.card_brand.name}`}</span>
                                            </div>
                                        </div>
                                        {isSuperAdmin && cardInformation.payment_method.is_temp && (
                                            <div className="flex flex-row space-x-2">
                                                <div className="text-xl font-MulishBold">{t("Is-Temporary")}:</div>
                                                <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                                                    <span className="uppercase">{`${
                                                        cardInformation.payment_method.is_temp === true ? t("Yes") : t("No")
                                                    }`}</span>
                                                </div>
                                            </div>
                                        )}
                                        {isSuperAdmin && cardInformation.payment_method.is_correction_mvc && (
                                            <div className="flex flex-row space-x-2">
                                                <div className="text-xl font-MulishBold">{t("Is-Correction-MVC")}:</div>
                                                <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                                                    <span className="uppercase">{`${
                                                        cardInformation.payment_method.is_correction_mvc === true
                                                            ? t("Yes")
                                                            : t("No")
                                                    }`}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="">
                                            {cardInformation.status === "active" && (
                                                <button
                                                    onClick={openModal}
                                                    className="py-2 px-4 bg-gfCoral focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                                                >
                                                    {t("Block-card")}
                                                </button>
                                            )}
                                            {cardInformation.status === "blocked" && (
                                                <button
                                                    onClick={openModal}
                                                    className="py-2 px-4 bg-gfPeriwinkle focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                                                >
                                                    {t("Unblock-card")}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {cardInformation.fields && cardInformation.fields.length !== 0 && (
                                        <div className="flex flex-col space-y-5">
                                            <div className="font-MulishBold">{t("User-Information")}</div>
                                            {cardInformation.fields.map((field) => (
                                                <div className="flex flex-row">
                                                    <div>{`${field.field_name} :`}</div>
                                                    <div>{`${field.value}`}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {cardTransactionInfo && cardTransactionInfo.length === 0 && (
                        <MessageAlert message={t("There-are-no-transactions-to-be-displayed")} />
                    )}
                    {errorTransactionInfo && <FailAlert message={errorTransactionInfo.message} />}

                    <div className="w-full flex flex-row justify-end mt-8">
                        {cardTransactionMeta &&
                            cardTransactionMeta.total > process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                                <div>
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={
                                            cardTransactionMeta.total / process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                                        }
                                        marginPagesDisplayed={4}
                                        pageRangeDisplayed={5}
                                        onPageChange={paginationHandler}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                        {cardTransactionInfo && cardTransactionInfo.length !== 0 && (
                            <button
                                onClick={() => {
                                    printPreview();
                                }}
                                className="py-2 px-6 my-2 ml-8 bg-gfCoral focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                            >
                                {t("Print")}
                            </button>
                        )}
                    </div>

                    {cardTransactionInfo && cardTransactionInfo.length !== 0 && (
                        <div className="flex flex-col my-3 text-sm  ">
                            <Table>
                                <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack">
                                    <tr>
                                        <th scope="col" className="px-4 py-3  text-left   uppercase tracking-wider">
                                            {t("Date")}
                                        </th>
                                        {cardInformation && !cardInformation.is_mvc && (
                                            <th scope="col" className="py-3  text-left   uppercase tracking-wider">
                                                TID
                                            </th>
                                        )}
                                        <th scope="col" className="px-2 py-3  text-left   uppercase tracking-wider">
                                            {t("Merchant")}
                                        </th>
                                        {cardInformation && !cardInformation.is_mvc && (
                                            <th scope="col" className="px-2 py-3  text-left   uppercase tracking-wider">
                                                {t("Status")}
                                            </th>
                                        )}
                                        <th scope="col" className="px-4 py-3  text-right   uppercase tracking-wider">
                                            {t("Amount")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gfPeriwinkle text-gfDarkBlue">
                                    {cardTransactionInfo.map((trx) => (
                                        <tr key={trx.id}>
                                            <td className="px-4 py-4">
                                                <div
                                                    className={`${
                                                        trx.status === "declined" ? "text-gfCoral" : "text-gfDarkBlue"
                                                    } flex justify-start items-center`}
                                                >
                                                    {trx.date}
                                                </div>
                                            </td>
                                            {cardInformation && !cardInformation.is_mvc && (
                                                <td className="px-2 py-4 ">
                                                    <div
                                                        className={`${
                                                            trx.status === "declined"
                                                                ? "text-gfCoral"
                                                                : "text-gfDarkBlue"
                                                        } flex justify-start items-center`}
                                                    >
                                                        {trx.terminal_id}
                                                    </div>
                                                </td>
                                            )}
                                            <td className="px-2 py-4">
                                                {cardInformation && cardInformation.is_mvc ? (
                                                    <div
                                                        className={`${
                                                            trx.status === "declined"
                                                                ? "text-gfCoral"
                                                                : "text-gfDarkBlue"
                                                        } flex justify-start items-center`}
                                                    >
                                                        {trx.description}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`${
                                                            trx.status === "declined"
                                                                ? "text-gfCoral"
                                                                : "text-gfDarkBlue"
                                                        } flex justify-start items-center`}
                                                    >
                                                        {checkLoggedInUserPermissions("list-of-shops") ? (
                                                            <Link
                                                                className="underline"
                                                                to={`/shops/shop-list?keyword=${trx.merchant_name}`}
                                                            >
                                                                {trx.merchant_name}
                                                            </Link>
                                                        ) : (
                                                            trx.merchant_name
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            {cardInformation && !cardInformation.is_mvc && (
                                                <td className="px-2 py-4 ">
                                                    {trx.status === "declined" && trx.note !== "" ? (
                                                        <div
                                                            className={`${
                                                                trx.status === "declined"
                                                                    ? "text-gfCoral"
                                                                    : "text-gfLightBlue"
                                                            } z-50 flex justify-start font-MulishBold `}
                                                        >
                                                            <Tooltip
                                                                content={trx.note}
                                                                customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 9999;
                                                                `}
                                                                zIndex={9999}
                                                                background="#FFF"
                                                                border={GF_PERIWINKLE}
                                                                color={GF_DARK_BLUE}
                                                                fontFamily="inherit"
                                                                radius={4}
                                                                padding={10}
                                                                placement="top"
                                                            >
                                                                <div
                                                                    className={`${
                                                                        trx.status === "declined"
                                                                            ? "text-gfCoral"
                                                                            : "text-gfDarkBlue"
                                                                    } cursor-help flex justify-start items-center font-MulishBold uppercase`}
                                                                >
                                                                    {trx.status}
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`${
                                                                trx.status === "declined"
                                                                    ? "text-gfCoral"
                                                                    : "text-gfDarkBlue"
                                                            } flex justify-start items-center font-MulishBold uppercase`}
                                                        >
                                                            {trx.status}
                                                        </div>
                                                    )}
                                                </td>
                                            )}
                                            <td className="px-4 py-4">
                                                <div
                                                    className={`${
                                                        trx.status === "declined" ? "text-gfCoral" : "text-gfLightBlue"
                                                    } flex justify-end font-MulishBold`}
                                                >
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={trx.amount}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                    <span className="ml-2">{` ${tagCurrency}`}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
                <BlockView modalVisibility={visibility} closeHandler={closeModalHandler} mvcToken={cardNumber} />
                <UnblockView
                    modalVisibility={visibility && cardInformation && cardInformation.status === "blocked"}
                    closeHandler={closeModalHandler}
                    mvcToken={cardNumber}
                />
                <PrintPreview modalVisibility={printPreviewVisibility} closeHandler={printPreviewClose} />
            </div>
        </Container>
    );
}
