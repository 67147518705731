import { issuingAPI } from "../services/Axios";

export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_ERROR = "FETCH_COUNTRIES_ERROR";

export const fetchCountries = () => (dispatch) => {
    dispatch({ type: FETCH_COUNTRIES });

    return issuingAPI
        .get("country")
        .then((success) => {
            dispatch({ type: FETCH_COUNTRIES_SUCCESS, payload: success.data });
            return success;
        })
        .catch((error) => {
            dispatch({ type: FETCH_COUNTRIES_ERROR });
            return error;
        });
};
