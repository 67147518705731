/* eslint-disable import/no-anonymous-default-export */
import {
    RESET_REPORT_BY_CHANNELS,
    ERROR_CHANNEL_LIST,
    ERROR_REPORT_BY_CHANNEL,
    LOADING_CHANNEL_LIST,
    LOADING_REPORT_BY_CHANNEL,
    POPULATED_LIST,
    RECEIVED_CHANNEL_LIST,
    RECEIVED_REPORT_BY_CHANNEL,
    LOADING_USER_LIST,
    RECEIVED_USER_LIST,
    ERROR_USER_LIST,
} from "../../actions/ReportByChannelActions";
import { paginate } from "../../utils/Helper";

import i18n from "i18next";

const PAGINATE_FIRST_PAGE = 1;

const initialState = {
    loadingChannelList: false,
    receivedChannelList: null,
    errorChannelList: null,
    channelArray: null,

    loadingReportByChannel: false,
    receivedReportByChannel: null,
    errorReportByChannel: null,
    populatedList: null,
    channelSummary: null,

    loadingUserList: false,
    receivedUserList: null,
    errorUserList: null,
    userArray: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_CHANNEL_LIST:
            return {
                ...state,
                loadingChannelList: true,
                receivedChannelList: null,
                errorChannelList: null,
            };

        case RECEIVED_CHANNEL_LIST:
            const defaultValue = {
                card_brands: [],
                id: -1,
                name: i18n.t("All"),
                terminals: [],
            };
            const temp = [defaultValue, ...action.payload];
            const tempArray = action.payload.map((item) => item.id);
            return {
                ...state,
                loadingChannelList: false,
                receivedChannelList: temp,
                errorChannelList: null,
                channelArray: tempArray,
            };

        case ERROR_CHANNEL_LIST:
            return {
                ...state,
                loadingChannelList: true,
                receivedChannelList: null,
                errorChannelList: action.payload,
            };

        case LOADING_USER_LIST:
            return {
                ...state,
                loadingUserList: true,
                receivedUserList: null,
                errorUserList: null,
            };

        case RECEIVED_USER_LIST:
            let tempUserArray;
            let tempU;
            if (action.payload && action.payload.length !== 0) {
                tempUserArray = action.payload.map((item) => item.id);
                tempU = [{ id: -1, firstName: i18n.t("All"), lastName: "" }, ...action.payload];
            } else {
                tempU = action.payload;
            }

            return {
                ...state,
                loadingUserList: false,
                receivedUserList: tempU,
                errorUserList: null,
                userArray: tempUserArray,
            };

        case ERROR_USER_LIST:
            return {
                ...state,
                loadingUserList: false,
                receivedUserList: null,
                errorUserList: action.payload,
            };

        case LOADING_REPORT_BY_CHANNEL:
            return {
                ...state,
                loadingReportByChannel: true,
                receivedReportByChannel: null,
                errorReportByChannel: null,
                channelSummary: null,
            };

        case RECEIVED_REPORT_BY_CHANNEL:
            return {
                ...state,
                loadingReportByChannel: false,
                receivedReportByChannel: action.payload.data,
                errorReportByChannel: null,
                populatedList: paginate(
                    action.payload.data,
                    PAGINATE_FIRST_PAGE,
                    process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                ),
                channelSummary: action.payload.summary,
            };

        case ERROR_REPORT_BY_CHANNEL:
            return {
                ...state,
                loadingReportByChannel: false,
                receivedReportByChannel: null,
                errorReportByChannel: action.payload,
            };

        case POPULATED_LIST:
            return {
                ...state,
                populatedList: paginate(
                    state.receivedReportByChannel,
                    action.payload,
                    process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                ),
            };

        case RESET_REPORT_BY_CHANNELS:
            return {
                ...state,
                ...initialState,
            };

        default:
            return state;
    }
}
