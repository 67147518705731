import React from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import DropdownIndicator from "./Components/DropdownIndicator";
import LoadingIndicator from "./Components/LoadingIndicator";
import { GF_CORAL, GF_DARK_BLUE, GF_GREY, GF_PERIWINKLE } from "../../utils/Constants";

export default function AddressListDropdown({ options, onChangeHandler }) {
    const { selectedAddress } = useSelector((state) => state.deliveryAddress);
    const customStyles = (value) => ({
        placeholder: (provided) => ({
            ...provided,
            color: GF_GREY,
        }),
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "38px",
            paddingBottom: "2px",
            cursor: "pointer",
            minWidth: "230px",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
                borderColor: GF_PERIWINKLE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: GF_PERIWINKLE,
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            //backgroundColor: state.isSelected ? "#C0C0C1" : "#FFFFFF",
            backgroundColor: state.isSelected && state.data.id === value.id ? GF_DARK_BLUE : state.isFocused ? GF_CORAL : "white",
            color: state.isSelected && state.data.id === value.id ? "#FFF" : GF_DARK_BLUE,
            "&:hover": {
                background: GF_CORAL,
                color: "#FFF",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
        }),
    });

    return (
        <Select
            value={selectedAddress}
            onChange={onChangeHandler}
            styles={customStyles(selectedAddress)}
            options={options}
            isSearchable={true}
            getOptionLabel={(option) => `${option.delivery_name}`}
            components={{
                IndicatorSeparator: null,
                DropdownIndicator: DropdownIndicator,
                LoadingIndicator: LoadingIndicator,
            }}
        />
    );
}
