import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentMethod, RESET_CREATE_PM } from "../../../actions/MVCManagementAction";
import ButtonLoading from "../../../assets/svg/ButtonLoading";
import CloseIcon from "../../../assets/svg/CloseIcon";
import FailAlert from "../../Alert/FailAlert";
import SuccessAlert from "../../Alert/SuccessAlert";
import ValidationErrors from "../../Alert/ValidationErrors";

ReactModal.setAppElement("#root");

export default function CreatePaymentMethod({ modalVisibility, closeHandler }) {
    const dispatch = useDispatch();
    const { loadingCreatePaymentMethod, successCreatPaymentMethod, errorCreatePaymentMethod } = useSelector((state) => state.mvc);
    const { validationErrors } = useSelector((state) => state.commonReducer);

    const [paymentName, setPaymentName] = useState("");
    const [isPaymentOnTerminal, setPaymentOnTerminal] = useState(false);
    const [isLoadPromoCards, setLoadPromoCard] = useState(false);
    const [isNewMVC, setNewMVC] = useState(true);
    const [isTemp, setIsTemp] = useState(false);
    const [isCorrectionMVC, setIsCorrectionMVC] = useState(false);
    const [newMVCToken, setNewMVCToken] = useState("");
    const [nameRequired, setNameRequired] = useState("");
    const [tokenRequired, setTokenRequired] = useState("");
    const { t } = useTranslation();

    const createPaymentMethodHandler = () => {
        if (paymentName !== "") {
            if (!isNewMVC && newMVCToken === "") {
                setTokenRequired("Token is required");
            } else {
                setNameRequired("");
                setTokenRequired("");
                dispatch(createPaymentMethod(paymentName, isPaymentOnTerminal, isLoadPromoCards, isTemp, isCorrectionMVC, isNewMVC, !isNewMVC ? newMVCToken : ""));
            }
        } else {
            setNameRequired("Name is required");
        }
    };

    return (
        <ReactModal
            isOpen={modalVisibility}
            contentLabel="Minimal Modal Example"
            className="bg-gray-700 z-50"
            style={{
                overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(23, 35, 82, 0.6)",
                    zIndex: 9999,
                    backdropFilter: "blur(8px)",
                },
                content: {
                    position: "absolute",
                    top: "50px",
                    left: "100px",
                    right: "100px",
                    bottom: "100px",

                    backgroundColor: "rgba(23, 35, 82, 0.0)",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                },
            }}
        >
            <div className="flex flex-col  mx-auto py-3 px-10 border max-w-xl rounded shadow-md bg-white text-gfDarkBlue font-MulishRegular">
                <div className="modal-content py-4 text-left px-6 text-base text-gfDarkBlue">
                    {/* <!--Title--> */}
                    <div className="flex justify-between items-center ">
                        <div className="font-semibold">{t("Payment-method")}</div>
                        <button
                            onClick={() => {
                                closeHandler(!modalVisibility);
                                setPaymentOnTerminal(false);
                                setLoadPromoCard(false);
                                setIsTemp(false);
                                setNewMVC(true);
                                setIsCorrectionMVC(false);
                                setPaymentName("");
                                setNewMVCToken("");
                                dispatch({ type: RESET_CREATE_PM });
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    {/* <!--Body--> */}
                    {errorCreatePaymentMethod === null && successCreatPaymentMethod === null ? (
                        <div className="flex flex-col my-5 text-sm  ">
                            <div className="mb-1">{t("New-payment-method")}: </div>
                            <input
                                value={paymentName}
                                onChange={(event) => setPaymentName(event.target.value)}
                                placeholder={t("Name")}
                                autoComplete="false"
                                className="h-8 focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                            />
                            {nameRequired.length !== 0 && <div className="py-2 text-sm text-kadozRed">{nameRequired}</div>}
                            <div className="my-4 flex flex-col">
                                <div className="flex flex-row items-center">
                                    <input
                                        type="checkbox"
                                        className="h-8 focus:border-kadozBlack focus:outline-none border rounded mr-2"
                                        checked={isPaymentOnTerminal}
                                        onChange={() => setPaymentOnTerminal(!isPaymentOnTerminal)}
                                    />
                                    <span className="mr-5">{t("Payment-on-terminal")}</span>
                                </div>

                                <div className="flex flex-row items-center">
                                    <input
                                        type="checkbox"
                                        className="h-8 focus:border-kadozBlack focus:outline-none border rounded mr-2"
                                        checked={isLoadPromoCards}
                                        onChange={() => setLoadPromoCard(!isLoadPromoCards)}
                                    />
                                    <span className="mr-5">{t("Loads-promo-cards")}</span>
                                </div>

                                <div className="flex flex-row items-center">
                                    <input type="checkbox" className="h-8 focus:border-kadozBlack focus:outline-none border rounded mr-2" checked={isTemp} onChange={() => setIsTemp(!isTemp)} />
                                    <span className="mr-5">{t("Is-Temporary")}</span>
                                </div>

                                <div className="flex flex-row items-center">
                                    <input
                                        type="checkbox"
                                        className="h-8 focus:border-kadozBlack focus:outline-none border rounded mr-2"
                                        checked={isCorrectionMVC}
                                        onChange={() => setIsCorrectionMVC(!isCorrectionMVC)}
                                    />
                                    <span className="mr-5">{t("Correction-MVC")}</span>
                                </div>

                                <div className="flex flex-row items-center mt-1">
                                    <div className="flex flex-col">
                                        <label className="text-sm mb-2">
                                            <input className="mr-1" type="radio" checked={isNewMVC === true} value={true} onChange={() => setNewMVC(true)} />
                                            {t("New-Token")}
                                        </label>
                                        <div className="flex flex-row items-center text-sm">
                                            <input className="mr-1" type="radio" checked={isNewMVC === false} value={false} onChange={() => setNewMVC(false)} />
                                            {t("Existing-Token")}
                                            {!isNewMVC && (
                                                <input
                                                    value={newMVCToken}
                                                    onChange={(event) => setNewMVCToken(event.target.value)}
                                                    placeholder={t("MVC-Token")}
                                                    autoComplete="false"
                                                    className="h-8 ml-2 focus:border-kadozBlack text-sm pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {tokenRequired.length !== 0 && <div className="py-2 text-sm text-kadozRed">{tokenRequired}</div>}
                            </div>

                            <div className="flex flex-row justify-center items-center">
                                <button
                                    onClick={createPaymentMethodHandler}
                                    className="border rounded  py-2 px-8 text-gfDarkBlue w-32 bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium"
                                >
                                    {t("Create")}
                                    {loadingCreatePaymentMethod && <ButtonLoading color={"#4A5568"} />}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col my-5 text-sm justify-center">
                            {successCreatPaymentMethod && <SuccessAlert title="Success" message={t("Payment-method-created") + "!"} />}
                            {errorCreatePaymentMethod && <FailAlert message={errorCreatePaymentMethod.message} />}
                            {validationErrors && (
                                <div className="py-2">
                                    <ValidationErrors {...validationErrors} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </ReactModal>
    );
}
