import { authServerAPI, issuingAPI } from "../../../services/Axios";
import { getLoggedInUserMVC } from "../../MVCManagementAction";

export const PREFIX = "@userManagement/";

export const LOADING_USER_LIST = `${PREFIX}LOADING_USER_LIST`;
export const RECEIVED_USER_LIST = `${PREFIX}RECEIVED_USER_LIST`;
export const ERROR_USER_LIST = `${PREFIX}ERROR_USER_LIST`;

export const LOADING_USER_PERMISSIONS = `${PREFIX}LOADING_USER_PERMISSIONS`;
export const RECEIVED_USER_PERMISSIONS = `${PREFIX}RECEIVED_USER_PERMISSIONS`;
export const ERROR_USER_PERMISSIONS = `${PREFIX}ERROR_USER_PERMISSIONS`;

export const ADDING_USER = `${PREFIX}ADDING_USER`;
export const SUCCESS_ADDING_USER = `${PREFIX}SUCCESS_ADDING_USER`;
export const FAIL_ADDING_USER = `${PREFIX}FAIL_ADDING_USER`;
export const RESET_ADDING_USER = `${PREFIX}RESET_ADDING_USER`;

export const DELETING_USER = `${PREFIX}DELETING_USER`;
export const SUCCESS_DELETING_USER = `${PREFIX}SUCCESS_DELETING_USER`;
export const FAIL_DELETING_USER = `${PREFIX}FAIL_DELETING_USER`;
export const RESET_DELETING_USER = `${PREFIX}RESET_DELETING_USER`;

export const ACTIVATE_DEACTIVATE_USER = `${PREFIX}ACTIVATE_DEACTIVATE_USER`;
export const SUCCESS_ACTIVATE_DEACTIVATE_USER = `${PREFIX}SUCCESS_ACTIVATE_DEACTIVATE_USER`;
export const FAIL_ACTIVATE_DEACTIVATE_USER = `${PREFIX}FAIL_ACTIVATE_DEACTIVATE_USER`;
export const RESET_ACTIVATE_DEACTIVATE_USER = `${PREFIX}RESET_ACTIVATE_DEACTIVATE_USER`;

export const EDITING_USER = `${PREFIX}EDITING_USER`;
export const SUCCESS_EDITING_USER = `${PREFIX}SUCCESS_EDITING_USER`;
export const FAIL_EDITING_USER = `${PREFIX}FAIL_EDITING_USER`;
export const RESET_EDITING_USER = `${PREFIX}RESET_EDITING_USER`;

export const LOADING_USER = `${PREFIX}LOADING_USER`;
export const RECEIVED_USER = `${PREFIX}RECEIVED_USER`;
export const ERROR_USER = `${PREFIX}ERROR_USER`;

export const OPEN_USER = `${PREFIX}OPEN_USER`;

export const LOADING_USER_BY_EMAIL = `${PREFIX}LOADING_USER_BY_EMAIL`;
export const RECEIVED_USER_BY_EMAIL = `${PREFIX}RECEIVED_USER_BY_EMAIL`;
export const ERROR_USER_BY_EMAIL = `${PREFIX}ERROR_USER_BY_EMAIL`;
export const RESET_USER_BY_EMAIL = `${PREFIX}RESET_USER_BY_EMAIL`;

export const getUserList = () => (dispatch, getState) => {
    dispatch({ type: LOADING_USER_LIST });

    const { tagID } = getState().tag;

    const params = {
        tag_id: tagID,
        deactivated: true,
    };

    return authServerAPI
        .get("/api/user", { params })
        .then((response) => {
            dispatch({ type: RECEIVED_USER_LIST, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER_LIST, payload: error });
            //history.push("/");
        });
};

export const getUser = (userID) => (dispatch, getState) => {
    dispatch({ type: LOADING_USER });

    return authServerAPI
        .get(`/api/user/${userID}`)
        .then((response) => {
            dispatch({ type: RECEIVED_USER, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER, payload: error });
            //history.push("/");
        });
};

export const getUserByEmail = (email) => (dispatch, getState) => {
    dispatch({ type: LOADING_USER_BY_EMAIL });

    return authServerAPI
        .get(`/api/user/email/${email}`)
        .then((response) => {
            dispatch({ type: RECEIVED_USER_BY_EMAIL, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER_BY_EMAIL, payload: error });
            //history.push("/");
        });
};

export const getUserListKeyword = (keyword) => (dispatch, getState) => {
    //dispatch({ type: LOADING_USER_LIST });

    const { tagID } = getState().tag;

    const params = {
        tag_id: tagID,
        deactivated: true,
        query: keyword,
    };

    return authServerAPI
        .get("/api/user", { params })
        .then((response) => {
            dispatch({ type: RECEIVED_USER_LIST, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER_LIST, payload: error });
            //history.push("/");
        });
};

export const getUserListAfterChanges = () => (dispatch, getState) => {
    //dispatch({ type: LOADING_USER_LIST });

    const { tagID } = getState().tag;

    const params = {
        tag_id: tagID,
        deactivated: true,
    };

    return authServerAPI
        .get("/api/user", { params })
        .then((response) => {
            dispatch({ type: RECEIVED_USER_LIST, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER_LIST, payload: error });
            //history.push("/");
        });
};

export const getUserPermission = (userID) => (dispatch, getState) => {
    dispatch({ type: LOADING_USER_PERMISSIONS });

    return authServerAPI
        .get(`/api/user/${userID}/permission`)
        .then((response) => {
            dispatch({ type: RECEIVED_USER_PERMISSIONS, payload: response.data });
            dispatch(getUserList());
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER_PERMISSIONS, payload: error });
            //history.push("/");
        });
};

export const addCompleteUser = (subMitData, ids) => (dispatch, getState) => {
    dispatch({ type: ADDING_USER });

    const { selectedTag } = getState().tag;

    const data = {
        first_name: subMitData.first_name,
        last_name: subMitData.last_name,
        email: subMitData.email,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,

        entities: [
            {
                type: "tag",
                id: selectedTag.id,
                parent_id: selectedTag.parent_tag_id,
                permission_ids: ids,
            },
        ],
    };

    return authServerAPI
        .post("/api/user/profile", { ...data })
        .then((response) => {
            dispatch({ type: SUCCESS_ADDING_USER, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: FAIL_ADDING_USER, payload: error });
            //history.push("/");
        });
};

export const deleteUserByID = (userID) => (dispatch, getState) => {
    dispatch({ type: DELETING_USER });

    const { tagID } = getState().tag;

    const headers = { "client-id": `${process.env.REACT_APP_AUTH_CLIENT_ID}` };

    return authServerAPI
        .delete(`/api/user/${userID}/entity/${tagID}/type/tag`, { headers })
        .then((response) => {
            dispatch({ type: SUCCESS_DELETING_USER, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: FAIL_DELETING_USER, payload: error.response.data });
            //history.push("/");
        });
};

export const activateDeactivateUserByID = (user) => (dispatch) => {
    dispatch({ type: ACTIVATE_DEACTIVATE_USER });

    const data = {
        active: user.active ? false : true,
    };

    return authServerAPI
        .patch(`/api/user/${user.id}`, { ...data })
        .then((response) => {
            dispatch({ type: SUCCESS_ACTIVATE_DEACTIVATE_USER, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: FAIL_ACTIVATE_DEACTIVATE_USER, payload: error });
            //history.push("/");
        });
};

export const editFullUser = (user, ids) => (dispatch, getState) => {
    dispatch({ type: EDITING_USER });

    const { selectedTag } = getState().tag;

    const data = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,

        entities: [
            {
                type: "tag",
                id: selectedTag.id,
                parent_id: selectedTag.parent_tag_id,
                permission_ids: ids,
            },
        ],
    };

    return authServerAPI
        .put(`/api/user/profile/${user.id}`, { ...data })
        .then((response) => {
            dispatch({ type: SUCCESS_EDITING_USER, payload: response.data });
            dispatch(addUserOrganizationPermissions(user.id, ids));
        })
        .catch((error) => {
            dispatch({ type: FAIL_EDITING_USER, payload: error });
        });
};

export const LOADING_USER_ORGANIZATION_PERMISSION = `${PREFIX}LOADING_USER_ORGANIZATION_PERMISSION`;
export const RECEIVED_USER_ORGANIZATION_PERMISSION = `${PREFIX}RECEIVED_USER_ORGANIZATION_PERMISSION`;
export const ERROR_USER_ORGANIZATION_PERMISSION = `${PREFIX}ERROR_USER_ORGANIZATION_PERMISSION`;

export const getUserOrganizationPermissions = (userID) => (dispatch, getState) => {
    dispatch({ type: LOADING_USER_ORGANIZATION_PERMISSION });

    const { tagID } = getState().tag;
    return authServerAPI
        .get(`/api/user/${userID}/entity/${tagID}/type/tag/client/${process.env.REACT_APP_AUTH_CLIENT_ID}/permission`)
        .then((response) => {
            dispatch({ type: RECEIVED_USER_ORGANIZATION_PERMISSION, payload: response.data.permissions });
        })
        .catch((error) => {
            dispatch({ type: ERROR_USER_ORGANIZATION_PERMISSION, payload: error });
        });
};

export const LOADING_LOGIN_USER_ORGANIZATION_PERMISSION = `${PREFIX}LOADING_LOGIN_USER_ORGANIZATION_PERMISSION`;
export const RECEIVED_LOGIN_USER_ORGANIZATION_PERMISSION = `${PREFIX}RECEIVED_LOGIN_USER_ORGANIZATION_PERMISSION`;
export const ERROR_LOGIN_USER_ORGANIZATION_PERMISSION = `${PREFIX}ERROR_LOGIN_USER_ORGANIZATION_PERMISSION`;

export const getLoggedInUserOrganizationPermissions = (userID) => (dispatch, getState) => {
    dispatch({ type: LOADING_LOGIN_USER_ORGANIZATION_PERMISSION });

    const { tagID } = getState().tag;

    return authServerAPI
        .get(`/api/user/${userID}/entity/${tagID}/type/tag/client/${process.env.REACT_APP_AUTH_CLIENT_ID}/permission`)
        .then((response) => {
            dispatch({ type: RECEIVED_LOGIN_USER_ORGANIZATION_PERMISSION, payload: response.data.permissions });
            dispatch(getUserList());
            dispatch(getLoggedInUserMVC(userID));
        })
        .catch((error) => {
            dispatch({ type: ERROR_LOGIN_USER_ORGANIZATION_PERMISSION, payload: error });
        });
};

export const ADDING_USER_ORGANIZATION_PERMISSION = `${PREFIX}ADDING_USER_ORGANIZATION_PERMISSION`;
export const SUCCESS_USER_ORGANIZATION_PERMISSION = `${PREFIX}SUCCESS_USER_ORGANIZATION_PERMISSION`;
export const FAIL_USER_ORGANIZATION_PERMISSION = `${PREFIX}FAIL_USER_ORGANIZATION_PERMISSION`;

export const addUserOrganizationPermissions = (userID, permissions) => (dispatch, getState) => {
    dispatch({ type: ADDING_USER_ORGANIZATION_PERMISSION });

    const { selectedTag } = getState().tag;

    const data = {
        parent_id: selectedTag.parent_tag_id,
        permission_ids: permissions,
    };

    // https://auth.dev.loyaltek.com/api/user/{id}/organisation/{entityId}/type/{entityType}/client/{clientId}/permission

    return authServerAPI
        .post(
            `/api/user/${userID}/entity/${selectedTag.id}/type/tag/client/${process.env.REACT_APP_AUTH_CLIENT_ID}/permission`,
            { ...data }
        )
        .then((response) => {
            dispatch({ type: SUCCESS_USER_ORGANIZATION_PERMISSION, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: FAIL_USER_ORGANIZATION_PERMISSION, payload: error });
        });
};
