import React from "react";
import { GF_DARK_BLUE } from "../../utils/Constants";

export default function ChevronUp() {
    return (
        <div>
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" stroke={GF_DARK_BLUE} strokeWidth="2" d="M5 11l7-7 7 7M5 19l7-7 7 7"></path>
            </svg>
        </div>
    );
}
