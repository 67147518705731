import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RESET_CARD_INFO, unblockCardByToken } from "../../../../actions/CardInformationActions";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import { GF_DARK_BLUE } from "../../../../utils/Constants";
import FailAlert from "../../../Alert/FailAlert";
import SuccessAlert from "../../../Alert/SuccessAlert";

export default function UnblockView({ modalVisibility, closeHandler, mvcToken }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loadingBlockUnblock, successBlockUnblock, failBlockUnblock } = useSelector(
        (state) => state.cardInformation
    );
    const blockCard = () => {
        dispatch(unblockCardByToken(mvcToken, "description"));
    };

    return (
        <div>
            {/* <!--Create Payment Method--> */}
            <div
                className={`modal ${
                    modalVisibility ? " opacity-100" : " opacity-0 pointer-events-none"
                } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
            >
                <div className="modal-overlay absolute w-full h-full bg-gfDarkBlue opacity-100"></div>
                <div className="modal-container bg-white w-11/12 md:max-w-lg mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    {/* <!-- Add margin if you want to see some of the overlay behind the modal--> */}
                    <div className="modal-content py-4 text-left px-6 text-base text-gfDarkBlue">
                        {/* <!--Title--> */}
                        <div className="flex justify-between items-center mb-4">
                            <div className="font-semibold">
                                {t("Confirm-unblocking-of-the-card") + " - " + mvcToken}
                            </div>
                            <button
                                onClick={() => {
                                    closeHandler(!modalVisibility);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        {/* <!--Body--> */}
                        {!successBlockUnblock && (
                            <div className="modal-content py-4 text-left">
                                {/* <!--Title--> */}

                                {/* <!--Body--> */}
                                <p className="text-sm py-2  font-normal">
                                    {t("You-can-put-a-remark-stating-the-reason-of-the-unb")}
                                </p>
                                <textarea
                                    disabled={loadingBlockUnblock}
                                    placeholder={t("Your-remark-here")}
                                    className="border placeholder-gfGrey text-base rounded p-4 border-gfLightBlue w-full focus:outline-none focus:shadow-outline"
                                ></textarea>

                                {/* <!--Footer--> */}
                                <div className="flex justify-center w-full pt-2">
                                    <button
                                        disabled={loadingBlockUnblock}
                                        onClick={blockCard}
                                        className="modal-close flex justify-center items-center  w-full bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue py-2 font-MulishBold px-4 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        {t("Confirm")}
                                        {loadingBlockUnblock && <ButtonLoading color={GF_DARK_BLUE} />}
                                    </button>
                                </div>
                            </div>
                        )}
                        {successBlockUnblock && <SuccessAlert message={t("The-card-was-successfully-unblocked")} />}
                        {failBlockUnblock && <FailAlert message={failBlockUnblock.message} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
