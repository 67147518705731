import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
    editFullUser,
    getUserListAfterChanges,
    OPEN_USER,
    RESET_EDITING_USER,
} from "../../../actions/Admin/UserManagement/UserManagementActions";
import ButtonLoading from "../../../assets/svg/ButtonLoading";
import CloseIcon from "../../../assets/svg/CloseIcon";
import FailAlert from "../../Alert/FailAlert";
import CheckBox from "./CheckBox";
import _ from "lodash";
import { addMVCtoUser, RESET_ADD_MVC } from "../../../actions/MVCManagementAction";
import ValidationErrors from "../../Alert/ValidationErrors";
import { RESET_VALIDATION_ERRORS } from "../../../actions/CommonActions";
import MVCCheckBox from "./MVCCheckBox";

ReactModal.setAppElement("#root");

export default function UserMVCMgt({ isOpen, closeHandler, userData, }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue } = useForm();

    const { receivedLoggedInUserMVC, successAdding, failAdding, addingMVC, receivedUserMVC } = useSelector((state) => state.mvc);
    const { validationErrors } = useSelector((state) => state.commonReducer);

    const [loggedUserMVC, setLoggedUserMVC] = useState([]);   

    const onSubmit = (data) => {
        let ids = [];
        let availableIds = [];

        loggedUserMVC.forEach((item) => {
            if (item.isChecked) {
                ids.push(item.mvc_token);
            }
        });

        receivedLoggedInUserMVC.forEach((mvc) => {
            availableIds.push(mvc.mvc_token);
        });

        dispatch(addMVCtoUser(ids, userData.id, availableIds));
    };

    const handleCheckChieldElement = (event, item) => {
        let fruites = loggedUserMVC.slice();

        fruites.forEach((per) => {
            if (per.mvc_token === item.mvc_token) {
                per.isChecked = !per.isChecked;
            }
        });

        setLoggedUserMVC(fruites);
    };

    const handleAllChecked = (event) => {
        let fruites = loggedUserMVC.slice();

        fruites.forEach((per) => {
            per.isChecked = event.target.checked;
        });

        const newUsers = fruites.slice();
        setLoggedUserMVC(newUsers);
    };

    const okHandler = () => {
        dispatch(getUserListAfterChanges());
        closeHandler(!isOpen);
        dispatch({ type: RESET_ADD_MVC });
        dispatch({ type: RESET_VALIDATION_ERRORS });
    };

    const afterOpenModal = () => {        
        loggedUserMVC.forEach((item) => {
            item.isChecked = false;
        });       

        if (receivedUserMVC !== null && receivedUserMVC.length !== 0 && receivedLoggedInUserMVC !== null && receivedLoggedInUserMVC.length !== 0) {
            loggedUserMVC.forEach((li) => {                
                receivedUserMVC.forEach((user) => {                   
                    if (user.mvc_token === li.mvc_token) {                       
                        li.isChecked = true;
                    }
                });
            });
        }



        const newUsers = loggedUserMVC.slice();
        setLoggedUserMVC(newUsers);
    };

    useEffect(() => {
        setLoggedUserMVC(receivedLoggedInUserMVC);
        const fields = ["name", "email", "first_name", "last_name"];

        if (isOpen && userData) {
            setTimeout(() => fields.forEach((field) => setValue(field, userData[field])), 600);
        }
    }, [isOpen]);

    return (
        <ReactModal
            onAfterOpen={afterOpenModal}
            isOpen={isOpen}
            contentLabel="Minimal Modal Example"
            className="bg-gray-700 z-50"
            style={{
                overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(23, 35, 82, 0.6)",
                    zIndex: 9999,
                    backdropFilter: "blur(8px)",
                },
                content: {
                    position: "absolute",
                    top: "50px",
                    left: "100px",
                    right: "100px",
                    bottom: "100px",

                    backgroundColor: "rgba(23, 35, 82, 0.0)",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                },
            }}
        >
            <div className="flex flex-col  mx-auto py-3 px-10 border max-w-xl rounded shadow-md bg-white text-gfDarkBlue font-MulishRegular">
                <div>
                    <div className="flex justify-between items-center mb-5">
                        <div className="font-MulishBold">{t("Set-MVC-User")}</div>
                        <button
                            onClick={() => {
                                closeHandler(!isOpen);
                                dispatch({ type: RESET_ADD_MVC });
                                dispatch({ type: RESET_VALIDATION_ERRORS });
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>

                    {successAdding === null ? (
                        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-row">
                                <div className="flex justify-start font-MulishBold">User Name : </div>
                                <div className="ml-2">{`${userData.first_name} ${userData.last_name}`}</div>
                            </div>

                            <div className="mt-10 font-MulishBlack">MVC Card Token</div>

                            <div className="flex justify-start items-center py-3 font-MulishBold">
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    onClick={(event) => handleAllChecked(event)}
                                    value="checkedall"
                                />
                                {t("Select-All-Payment-Methods")}
                            </div>

                            <div className="">
                                {loggedUserMVC !== null &&
                                    loggedUserMVC.length !== 0 &&
                                    loggedUserMVC.map((mvc) => (
                                        <div key={mvc.id} className="flex flex-col justify-start items-start">
                                            <ul className="mb-1">
                                                <div key={mvc.id}>
                                                    <MVCCheckBox
                                                        handleCheckChieldElement={(event) =>
                                                            handleCheckChieldElement(event, mvc)
                                                        }
                                                        {...mvc}
                                                    />
                                                </div>
                                            </ul>
                                        </div>
                                    ))}
                            </div>

                            <div className="flex flex-row justify-between space-x-10">
                                <button
                                    type="submit"
                                    className="w-full flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                                >
                                    {addingMVC && <ButtonLoading color={"#4A5568"} />}
                                    {!addingMVC && <span>{t("Update")}</span>}
                                </button>
                            </div>

                            {failAdding && <FailAlert message={failAdding.message} />}

                            {validationErrors && (
                                <div className="py-2">
                                    <ValidationErrors {...validationErrors} />
                                </div>
                            )}
                        </form>
                    ) : (
                        <div className="flex flex-col justify-center items-center p-5">
                            <p className="flex justify-start p-2">User has been successfully updated.</p>
                            <button
                                className="w-full flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                                onClick={okHandler}
                            >
                                OK
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </ReactModal>
    );
}
