import React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { RESET_VALIDATION_ERRORS } from "../../../../actions/CommonActions";
import FailAlert from "../../../Alert/FailAlert";
import SuccessAlert from "../../../Alert/SuccessAlert";
import ValidationErrors from "../../../Alert/ValidationErrors";
import { getTicketNumber, RESET_ERRORS_ACTIVATE_PROMMO_CARDS } from "../../../../actions/ActivatePromoCardsActions";

ReactModal.setAppElement("#root");

export default function ActivateCardsInfor({ modalVisibility, closeHandler, transferDetails }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loadingPromoCard, failPromoCard, loadingPromoCardPayment, successPromoCardPayment, failPromoCardPayment } =
        useSelector((state) => state.activatePromoCards);
    const { validationErrors } = useSelector((state) => state.commonReducer);

    const tryAginLoadCardHandler = () => {
        closeHandler();
        dispatch({ type: RESET_VALIDATION_ERRORS });
        dispatch({ type: RESET_ERRORS_ACTIVATE_PROMMO_CARDS });
        dispatch(getTicketNumber()).then(
            function (success) {
                document.body.style.overflow = "unset";
            },
            function (error) {
                console.log(error);
            }
        );
    };

    const cancelLoadCardHandler = () => {
        closeHandler();
        window.location.reload();
        document.body.style.overflow = "unset";
    };

    return (
        <ReactModal
            isOpen={modalVisibility}
            contentLabel="Minimal Modal Example"
            className="bg-gray-700 z-50"
            style={{
                overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(23, 35, 82, 0.6)",
                    zIndex: 9999,
                    backdropFilter: "blur(12px)",
                },
                content: {
                    position: "absolute",
                    top: "50px",
                    left: "100px",
                    right: "100px",
                    bottom: "100px",

                    backgroundColor: "rgba(23, 35, 82, 0.0)",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                },
            }}
        >
            <div className="flex flex-col  mx-auto py-3 px-5 border max-w-xl rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
                <div className="flex justify-between items-center ">
                    <div className="font-MulishBold">{t("activate-promo-cards")} </div>
                </div>
                <div className="flex flex-col gap-5 mx-auto justify-center items-center my-10">
                    {loadingPromoCard && <span>{`${t("Cards-are-loading")}...`}</span>}
                    {loadingPromoCardPayment && <span>{`${t("Payment-is-being-processed")}...`}</span>}
                    {failPromoCard && <FailAlert message={failPromoCard.message} />}
                    {failPromoCardPayment && <FailAlert message={failPromoCardPayment.message} />}
                    {successPromoCardPayment && <SuccessAlert message={successPromoCardPayment.message} />}
                    {validationErrors && (
                        <div className="py-2">
                            <ValidationErrors {...validationErrors} />
                        </div>
                    )}
                </div>

                {failPromoCard && (
                    <div className="flex flex-row justify-between items-center mt-5 gap-5">
                        <button
                            onClick={tryAginLoadCardHandler}
                            className="bg-gfCoral w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                        >
                            {t("Try-Again")}
                        </button>
                        <button
                            onClick={cancelLoadCardHandler}
                            className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                        >
                            {t("Cancel")}
                        </button>
                    </div>
                )}

                {failPromoCardPayment && (
                    <div className="flex flex-row justify-between items-center mt-5 gap-5">
                        <button
                            onClick={tryAginLoadCardHandler}
                            className="bg-gfCoral w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                        >
                            {t("Try-Again")}
                        </button>
                        <button
                            onClick={cancelLoadCardHandler}
                            className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                        >
                            {t("Cancel")}
                        </button>
                    </div>
                )}

                {successPromoCardPayment && (
                    <div className="flex flex-row justify-center items-center mt-5">
                        <button
                            onClick={cancelLoadCardHandler}
                            className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                        >
                            {t("OK")}
                        </button>
                    </div>
                )}
            </div>
        </ReactModal>
    );
}
