import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_UN_SELECTED_MERCHANT } from "../../../../actions/SalesByMerchantActions";

export default function MerchantList() {
    const { selectedMerchants } = useSelector((state) => state.salesByMerchant);
    const dispatch = useDispatch();

    const toggle = (item) => {
        dispatch({ type: SET_UN_SELECTED_MERCHANT, payload: item });
    };

    return (
        <div className="flex flex-col space-y-1">
            {selectedMerchants.map((item, index) => (
                <div key={index} className={`flex flex-row p-2 items-center rounded bg-opacity-50`} style={{ backgroundColor: item.color, opacity: 0.75 }}>
                    <input readOnly type="checkbox" checked={item.checked || false} onClick={() => toggle(item)} />
                    <label className="ml-4 font-medium text-xs text-white">{item.name}</label>
                </div>
            ))}
        </div>
    );
}
