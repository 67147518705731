import React from "react";
import Select from "react-select";
import { GF_CORAL, GF_DARK_BLUE, GF_PERIWINKLE } from "../../utils/Constants";
import DropdownIndicator from "./Components/DropdownIndicator";

export default function CardTransferDeskDropDown({ options, onChangeHandler, value }) {
    const customStyles = (value) => ({
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "40px",
            paddingBottom: "2px",
            color: GF_DARK_BLUE,
            cursor: "pointer",
            fontWeight: "600",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: GF_PERIWINKLE,
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && state.data.id === value.id ? GF_DARK_BLUE : state.isFocused ? GF_CORAL : "white",
            color: state.isSelected && state.data.id === value.id ? "#FFF" : GF_DARK_BLUE,
            "&:hover": {
                background: GF_CORAL,
                color: "#FFF",
            },
        }),

        menuList: (provided) => ({
            ...provided,
            maxHeight: "100%",
            color: GF_DARK_BLUE,
        }),

        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
        }),
    });

    return (
        <div>
            <Select
                onChange={onChangeHandler}
                value={value}
                styles={customStyles(value)}
                options={options}
                getOptionLabel={(option) => `${option.name}`}
                isSearchable={false}
                components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: DropdownIndicator,
                }}
            />
        </div>
    );
}
