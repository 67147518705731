import React from "react";
import Select from "react-select";
import DropdownIndicator from "./Components/DropdownIndicator";
import LoadingIndicator from "./Components/LoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { SELECTED_TERMINAL, TERMINAL_CHANGED } from "../../actions/TerminalActions";
import { GF_CORAL, GF_DARK_BLUE, GF_GREY, GF_PERIWINKLE } from "../../utils/Constants";

export default function TerminalsDropdown({ options, placeholder }) {
    const { selectedTerminal, loadingTerminals } = useSelector((state) => state.terminal);
    const dispatch = useDispatch();

    const terminalChangedHandler = (event) => {
        dispatch({ type: SELECTED_TERMINAL, payload: event });
        document.body.dispatchEvent(new Event(TERMINAL_CHANGED, { bubbles: true, cancelable: false }));
    };

    const customStyles = (value) => ({
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "40px",
            paddingBottom: "2px",
            color: GF_DARK_BLUE,
            cursor: "pointer",
            fontWeight: "600",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: GF_PERIWINKLE,
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && state.data.id === value.id ? GF_DARK_BLUE : state.isFocused ? GF_CORAL : "white",
            color: state.isSelected && state.data.id === value.id ? "#FFF" : GF_DARK_BLUE,
            "&:hover": {
                background: GF_CORAL,
                color: "#FFF",
            },
        }),

        menuList: (provided) => ({
            ...provided,
            maxHeight: "100%",
            color: GF_DARK_BLUE,
        }),

        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
            fontSize: "16px",
        }),

        placeholder: (provided) => ({
            ...provided,
            color: GF_GREY,
        }),
    });

    return (
        <Select
            value={selectedTerminal}
            placeholder={loadingTerminals ? "Loading..." : placeholder}
            isDisabled={options === null}
            onChange={terminalChangedHandler}
            styles={customStyles(selectedTerminal)}
            options={options}
            isSearchable={true}
            getOptionLabel={(option) => `${option.id}`}
            isLoading={loadingTerminals}
            components={{
                IndicatorSeparator: null,
                DropdownIndicator: DropdownIndicator,
                LoadingIndicator: LoadingIndicator,
            }}
        />
    );
}
