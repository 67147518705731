/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import {
    getFromToken,
    getToToken,
    OPEN_CARD_TRANSFER_TICKET_PRINT,
    RESET_FROM_TOKEN,
    RESET_TO_TOKEN,
    RESET_TRANSFER,
    transferAndTerminateCard,
    TRANSFER_AND_TERMINATE_OPERATION_ID,
} from "../../../../actions/CardTransferActions";
import FailAlert from "../../../Alert/FailAlert";
import { isNumeric } from "../../../../utils/Helper";
import { useForm } from "react-hook-form";
import SuccessAlert from "../../../Alert/SuccessAlert";
import { useTranslation } from "react-i18next";
import Container from "../../../UIComponents/Container";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import { Userpilot } from "userpilot";
import PrintPreview from "./PrintPreview";
import ValidationErrors from "../../../Alert/ValidationErrors";
import { GF_DARK_BLUE } from "../../../../utils/Constants";

export default function CardTransfer() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [fees, setFees] = useState(true);
    const [fromToken, setFromToken] = useState("");
    const [toToken, setToToken] = useState("");
    const [printPreviewVisibility, setPrintPreviewVisibility] = useState(false);
    const [printDetails, setPrintDetails] = useState({});

    const { register, handleSubmit, errors, reset } = useForm();
    const onSubmit = (data) => {
        if (receivedToToken && receivedToToken.is_loadable) {
            const { credit, debit, fee } = data;
            dispatch(transferAndTerminateCard(debit, credit, fee));
        }
    };

    useEffect(() => {
        Userpilot.reload();
        dispatch({ type: RESET_TRANSFER });
        dispatch({type: TRANSFER_AND_TERMINATE_OPERATION_ID})


        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: RESET_TRANSFER });
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: RESET_TRANSFER });
        });

        window.addEventListener(
            OPEN_CARD_TRANSFER_TICKET_PRINT,
            function (evt) {
                printTicket(evt.detail);
            },
            false
        );

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
            window.removeEventListener(OPEN_CARD_TRANSFER_TICKET_PRINT, () => {});
        };
    }, []);

    const {
        loadingFromToken,
        receivedFromToken,
        errorFromToken,

        loadingToToken,
        receivedToToken,
        errorToToken,
        sendingTransferAndTerminate,
        successTransferAndTerminate,
        errorTransferAndTerminate,
    } = useSelector((state) => state.cardTransfer);
    const { tagCurrency } = useSelector((state) => state.tag);
    const { validationErrors } = useSelector((state) => state.commonReducer);

    function fromTokenChangeHandler(event) {
        dispatch({ type: RESET_FROM_TOKEN });
        if (event.currentTarget.value !== "") {
            let newT = [...fromToken];
            newT = event.currentTarget.value;
            setFromToken(newT);
            if (isNumeric(event.currentTarget.value) && event.currentTarget.value.length === 9) {
                setFromToken(event.currentTarget.value);
                dispatch(getFromToken(event.currentTarget.value));
            }
        } else {
            setFromToken("");
        }
    }

    const toTokenChangeHandler = (event) => {
        dispatch({ type: RESET_TO_TOKEN });
        if (event.currentTarget.value !== "") {
            let newT = [...toToken];
            newT = event.currentTarget.value;
            setToToken(newT);
            if (isNumeric(event.currentTarget.value) && event.currentTarget.value.length === 9) {
                setToToken(event.currentTarget.value);
                dispatch(getToToken(event.currentTarget.value));
            }
        } else {
            setToToken("");
        }
    };

    const printTicket = (data) => {
        setTimeout(function () {
            setPrintDetails((prev) => Object.assign(prev, data));
            setPrintPreviewVisibility(!printPreviewVisibility);
        }, 2000);
    };

    const printPreviewClose = () => {
        setPrintPreviewVisibility(!printPreviewVisibility);
        dispatch({ type: RESET_TRANSFER });
        setToToken("");
        setFromToken("");
        reset();
    };

    return (
        <Container title={t("card-to-card-transfer")}>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 items-start">
                        <div className="flex flex-col md:mb-0 md:mr-4 w-full  mb-10">
                            <input
                                autoFocus
                                name="debit"
                                ref={register({
                                    required: t("Required"),
                                    pattern: { value: /^[0-9]{9}$/, message: "Token should have 9 characters" },
                                })}
                                onChange={fromTokenChangeHandler}
                                maxLength="9"
                                type="text"
                                placeholder={t("Card-to-debit")}
                                autoComplete="false"
                                className={`border 
                                text-center 
                                focus:outline-none 
                                text-gfDarkBlue
                                text-xl w-full
                                border-gfPeriwinkle
                                rounded 
                                placeholder-gfGrey 
                                px-4 py-1`}
                            />
                            {errors.debit && <p className="text-xs text-kadozRed">{errors.debit.message}</p>}
                            {(loadingFromToken || receivedFromToken) && (
                                <div className="flex flex-col py-2 px-4 border rounded shadow mt-5">
                                    {receivedFromToken && (
                                        <div>
                                            <div className="">
                                                {t("Available-Balance")}:{" "}
                                                <span className="font-MulishBlack text-gfLightBlue">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={receivedFromToken.balance_available}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />{" "}
                                                    {`${String(tagCurrency).toUpperCase()}`}
                                                </span>
                                            </div>
                                            <div>
                                                {t("Expiry-date")}:{" "}
                                                <span className="">{receivedFromToken.expiration_date}</span>
                                            </div>
                                            {receivedFromToken.status === "terminate" && (
                                                <div>
                                                    {t("Status")}:{" "}
                                                    <span className=" text-kadozRed">
                                                        {String(receivedFromToken.status).toLocaleUpperCase()}
                                                    </span>
                                                </div>
                                            )}
                                            {receivedFromToken.status === "active" && (
                                                <div>
                                                    {t("Status")}:{" "}
                                                    <span className=" text-kadozGreen">
                                                        {String(receivedFromToken.status).toLocaleUpperCase()}
                                                    </span>
                                                </div>
                                            )}
                                            {receivedFromToken.status === "inactive" && (
                                                <div>
                                                    {t("Status")}:{" "}
                                                    <span className=" text-kadozGoldYellow">
                                                        {String(receivedFromToken.status).toLocaleUpperCase()}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {loadingFromToken && (
                                        <div className="flex flex-row justify-center items-center mx-auto">
                                            <ButtonLoading color={"#4A5568"} />
                                            {loadingFromToken && <span>{t("Getting-card-information")}...</span>}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="flex flex-col md:mr-4">
                            <input
                                name="credit"
                                ref={register({
                                    required: t("Required"),
                                    pattern: { value: /^[0-9]{9}$/, message: "Token should have 9 characters" },
                                })}
                                onChange={toTokenChangeHandler}
                                maxLength="9"
                                type="text"
                                placeholder={t("Card-to-credit")}
                                autoComplete="false"
                                className={`border 
                                text-center 
                                focus:outline-none 
                                text-gfDarkBlue
                                text-xl 
                                border-gfPeriwinkle
                                rounded 
                                placeholder-gfGrey 
                                px-4 py-1`}
                            />
                            {errors.credit && <p className="text-xs text-kadozRed">{errors.credit.message}</p>}
                            {(loadingToToken || receivedToToken) && (
                                <div className="flex flex-col py-2 px-4 border rounded shadow mt-5">
                                    {receivedToToken && (
                                        <div>
                                            <div className="">
                                                {t("Available-Balance")}:{" "}
                                                <span className="font-MulishBlack text-gfLightBlue">
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={receivedToToken.balance_available}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />{" "}
                                                    {`${String(tagCurrency).toUpperCase()}`}
                                                </span>
                                            </div>
                                            <div>
                                                {t("Expiry-date")}:{" "}
                                                <span className="">{receivedToToken.expiration_date}</span>
                                            </div>
                                            <div>
                                                {t("Is-card-loadable")}:{" "}
                                                <span className="font-MulishBlack">
                                                    {receivedToToken.is_loadable ? t("Yes") : t("No")}
                                                </span>
                                            </div>
                                            {receivedToToken.status === "terminate" && (
                                                <div>
                                                    {t("Status")}:{" "}
                                                    <span className=" text-kadozRed">
                                                        {String(receivedToToken.status).toLocaleUpperCase()}
                                                    </span>
                                                </div>
                                            )}
                                            {receivedToToken.status === "active" && (
                                                <div>
                                                    {t("Status")}:{" "}
                                                    <span className=" text-kadozGreen">
                                                        {String(receivedToToken.status).toLocaleUpperCase()}
                                                    </span>
                                                </div>
                                            )}
                                            {receivedToToken.status === "inactive" && (
                                                <div>
                                                    {t("Status")}:{" "}
                                                    <span className=" text-kadozGoldYellow">
                                                        {String(receivedToToken.status).toLocaleUpperCase()}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {loadingToToken && (
                                        <div className="flex flex-row justify-center items-center mx-auto">
                                            <ButtonLoading color={"#4A5568"} />
                                            <span>{t("Getting-card-information")}...</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <label className="text-gfDarkBlue my-4 md:my-0 md:mr-10">
                            <input
                                name="fee"
                                ref={register}
                                type="checkbox"
                                checked={fees}
                                className="mr-2 h-4 w-4 border rounded border-kadozNormalGray text-sm"
                                onChange={() => setFees(!fees)}
                            />
                            {t("Fees-apply")}
                        </label>
                        <button
                            disabled={receivedToToken && receivedToToken.is_loadable ? false : true}
                            type="submit"
                            className={`${
                                receivedToToken && receivedToToken.is_loadable ? "bg-opacity-100" : "bg-opacity-50"
                            } bg-gfCoral rounded text-gfDarkBlue font-MulishBold py-2 px-3 focus:outline-none w-full flex flex-row justify-center items-center`}
                        >
                            {t("Transfer")}
                            {sendingTransferAndTerminate && <ButtonLoading color={GF_DARK_BLUE}/>}
                        </button>
                    </div>
                </form>

                <div className="p-5">
                    <div className="flex flex-row space-x-8">
                        {errorFromToken && <FailAlert message={t("Card-to-debit") + " - " + errorFromToken.message} />}
                        {errorToToken && <FailAlert message={t("Card-to-credit") + " - " + errorToToken.message} />}
                    </div>
                    {errorTransferAndTerminate && <FailAlert message={errorTransferAndTerminate.message} />}
                    {successTransferAndTerminate && (
                        <SuccessAlert title={t("Done")} message={t("Successfully-Transferred")} />
                    )}
                    {validationErrors && (
                        <div className="py-2">
                            <ValidationErrors {...validationErrors} />
                        </div>
                    )}
                </div>
            </div>

            <PrintPreview
                modalVisibility={printPreviewVisibility}
                closeHandler={printPreviewClose}
                transferDetails={printDetails}
            />
        </Container>
    );
}
