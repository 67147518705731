import React, { useRef } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { getOrderViewCSVExport } from "../../../../actions/CardOrderActions";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import Table from "../../../UIComponents/Table";

export default function OrderDetails({ modalVisibility, closeHandler, data }) {
    const { t } = useTranslation();
    const { recivedOrderDetails } = useSelector((state) => state.cardOrders);
    const { tagCurrency } = useSelector((state) => state.tag);
    const popupRef = useRef();
    const dispatch = useDispatch();

    const outsideCloseHandler = (event) => {
        if (popupRef.current && popupRef.current.contains(event.target)) {
            return;
        } else {
            closeHandler(!modalVisibility);
        }
    };

    const getReport = () => {
        dispatch(getOrderViewCSVExport(data));
    };

    return (
        <div onClick={(event) => outsideCloseHandler(event)}>
            <ReactModal
                isOpen={modalVisibility}
                contentLabel="Minimal Modal Example"
                className="bg-gray-700 z-50"
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(23, 35, 82, 0.6)",
                        zIndex: 9999,
                        backdropFilter: "blur(8px)",
                    },
                    content: {
                        position: "absolute",
                        top: "50px",
                        left: "100px",
                        right: "100px",
                        bottom: "100px",

                        backgroundColor: "rgba(23, 35, 82, 0.0)",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        outline: "none",
                        padding: "50px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                    },
                }}
            >
                <div
                    ref={popupRef}
                    className="flex flex-col  mx-auto py-3 px-10 border max-w-xl rounded shadow-md bg-white text-gfDarkBlue font-MulishRegular"
                >
                    <div className="modal-content py-4 text-left px-6 text-base text-gfDarkBlue">
                        {/* <!--Title--> */}
                        <div className="flex justify-between items-center ">
                            <div className="font-semibold"> {t("Order-information").toUpperCase() + " - " + data}</div>
                            <button
                                onClick={() => {
                                    closeHandler(!modalVisibility);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div className="flex flex-col my-5 text-sm">
                            {recivedOrderDetails && (
                                <div className="flex flex-row my-1">
                                    <span className="font-semibold">{`${t("Tracking-Number")}:`}</span>
                                    <span className="ml-1">
                                        {recivedOrderDetails.tracking_number !== ""
                                            ? recivedOrderDetails.tracking_number
                                            : "--"}
                                    </span>
                                </div>
                            )}
                            {recivedOrderDetails && (
                                <div className="flex flex-row my-1">
                                    <span className="font-semibold">{`${t("Total-Cards-Ordered")}:`}</span>
                                    <span className="ml-1">{recivedOrderDetails.total_quantity}</span>
                                </div>
                            )}
                            {recivedOrderDetails && (
                                <div className="flex flex-row my-1">
                                    <span className="font-semibold">{`${t("Total-Amount-Ordered")}:`}</span>
                                    <div style={{ marginLeft: "4px" }}>
                                        <CurrencyFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={recivedOrderDetails.total_amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                        <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                    </div>
                                </div>
                            )}
                            {recivedOrderDetails &&
                            recivedOrderDetails.tokens &&
                            recivedOrderDetails.tokens.length !== 0 ? (
                                <div className="mt-5">
                                    {/* <button
                                        className="bg-gfCoral font-MulishBold hover:bg-opacity-75 rounded py-2 px-4 my-3"
                                        onClick={getReport}
                                    >
                                        {t("export-csv")}
                                    </button> */}
                                    <Table>
                                        <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-xs">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-1  text-center   uppercase tracking-wider"
                                                >
                                                    {t("Token")}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="py-1  text-center   uppercase tracking-wider"
                                                >
                                                    {t("Amount")}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="py-1  text-center   uppercase tracking-wider"
                                                >
                                                    {t("Status")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gfPeriwinkle">
                                            {recivedOrderDetails.tokens.map((token) => (
                                                <tr key={token.id}>
                                                    <td className="px-2 py-1 text-center text-sm  whitespace-nowrap">
                                                        {token.token}
                                                    </td>
                                                    <td className="px-2 py-1 text-center text-sm whitespace-nowrap">
                                                        <div style={{ marginLeft: "4px" }}>
                                                            <CurrencyFormat
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                value={token.amount_per_card}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                            />
                                                            <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-1 text-sm text-center  whitespace-nowrap">
                                                        {String(token.status).toUpperCase()}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            ) : (
                                <div className="py-2 px-4 text-center my-5 rounded bg-gfCoral text-white font-bold">
                                    No cards are assigned yet
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
}
