/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import ButtonLoading from "../../assets/svg/ButtonLoading";
import PageTitle from "../PageTitle";
import Select from "react-select";
import { TAG_CHANGED } from "../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../actions/TerminalActions";
import { useDispatch, useSelector } from "react-redux";
import {
    ADD_PAYMENT_METHOD,
    getAdditionalProducts,
    getTicketNumber,
    loadCards,
    OPEN_TICKET_PRINT,
    RECEIVED_TICKET_INFO,
    REMOVE_PAYMENT_METHOD,
} from "../../actions/LoadCardActions";
import { CLEAR_CARDS, INITIAL_LOAD_CARD, RESET_LOAD_CARD } from "../../actions/LoadCardActions";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import CurrencyFormat from "react-currency-format";
import AddCard from "./AddCard";
import CloseIcon from "../../assets/svg/CloseIcon";
import { GF_CORAL, GF_DARK_BLUE, GF_GREY, GF_PERIWINKLE } from "../../utils/Constants";
import ReactModal from "react-modal";
import FailAlert from "../Alert/FailAlert";
import SuccessAlert from "../Alert/SuccessAlert";
import { useReactToPrint } from "react-to-print";
import { randomString } from "../../utils/Helper";
import ValidationErrors from "../Alert/ValidationErrors";
import { RESET_VALIDATION_ERRORS } from "../../actions/CommonActions";
import { Userpilot } from "userpilot";

ReactModal.setAppElement("#root");

export default function LoadCard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let componentKey = 1;
    const [visibility, setVisibility] = useState(false);
    let submitted = false;
    const componentRef = useRef();
    const printTicket = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        Userpilot.reload();
        const prevTitle = document.title;
        document.title = `${t("Load-card")}  - myPortal`;

        dispatch({ type: INITIAL_LOAD_CARD });
        dispatch(getTicketNumber());
        dispatch(getAdditionalProducts());

        window.addEventListener(TAG_CHANGED, () => {
            dispatch({ type: INITIAL_LOAD_CARD });
            dispatch(getTicketNumber());
            dispatch(getAdditionalProducts());
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch({ type: INITIAL_LOAD_CARD });
            dispatch(getTicketNumber());
            dispatch(getAdditionalProducts());
        });

        window.addEventListener(OPEN_TICKET_PRINT, () => {
            printTicket();
        });

        return () => {
            document.title = prevTitle;
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
            window.removeEventListener(OPEN_TICKET_PRINT, () => {});
        };
    }, []);

    const {
        loadingTicketNumber,
        loadingLoadCardPayments,
        loadingLoadCards,
        errorLoadCardsPayments,
        additionalProducts,
        channels,
        fields,
        loadingPaymentMethods,
        loadingAdditionalProducts,
        loadingChannels,
        loadingFields,
        loadingDesks,
        deafultPaymentmethod,
        desks,
        cards,
        errorLoadCards,
        loadCardPayments,
        totalAmount,
        filteredPaymentMethods,
        isChannelMandatory,
        deafultChannel,
        paymentMethods,
        loadingTicketInfo,
        receivedTicketInfo,
        errorTicketInfo,
        isCardRangeDirty,
    } = useSelector((state) => state.loadCard);
    const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
    const { validationErrors } = useSelector((state) => state.commonReducer);

    const customStyles = (value) => ({
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: GF_PERIWINKLE,
            borderRadius: "4px",
            minHeight: "40px",
            paddingBottom: "2px",
            color: GF_DARK_BLUE,
            cursor: "pointer",
            fontWeight: "600",

            "&:hover": {
                background: GF_PERIWINKLE,
                color: GF_DARK_BLUE,
            },

            "&:hover div": {
                color: GF_DARK_BLUE,
            },

            "&:focus": {
                borderColor: GF_PERIWINKLE,
                background: GF_PERIWINKLE,
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor:
                state.isSelected && state.data.id === value.id ? GF_DARK_BLUE : state.isFocused ? GF_CORAL : "white",
            color: state.isSelected && state.data.id === value.id ? "#FFF" : GF_DARK_BLUE,
            "&:hover": {
                background: GF_CORAL,
                color: "#FFF",
            },
        }),

        menuList: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
        }),

        singleValue: (provided) => ({
            ...provided,
            color: GF_DARK_BLUE,
            fontSize: "18px",
        }),

        placeholder: (provided) => ({
            ...provided,
            color: GF_GREY,
        }),
    });

    const requiredField = ({ value, fieldName }) =>
        value ? undefined : `${fieldName ? fieldName : t("This-field")} ${t("is-required")}`;
    const requiredChannelField = ({ value, mandatory }) => {
        console.log(isChannelMandatory);
        return isChannelMandatory && value === undefined ? t("This-field-is-required") : undefined;
    };
    const requiredIf = ({ value, condition }) => {
        return condition && value === undefined ? t("This-field-is-required") : undefined;
    };

    const requiredEmail = ({ value, condition }) => {
        if (condition && value === undefined) {
            return t("This-field-is-required");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return t("Invalid-email-address");
        } else {
            return undefined;
        }
    };

    const requiredPhone = ({ value, condition }) => {
        if (condition && value === undefined) {
            return t("This-field-is-required");
        } else if (isNaN(value)) {
            return t("Must-be-a-number");
        } else {
            return undefined;
        }
    };

    const onSubmit = (value) => {
        submitted = true;
        if (parseFloat(getPaymentsTotal(value), 10) === parseFloat(getSubTotal(value), 10)) {
            //if (
            //    parseFloat(getAddedProductsTotal(value), 10).toFixed(2) <=
            //    cards.reduce((a, card) => (a += parseFloat(card.amount)), 0) * 2
            //) {
            activateCardHandler(value);
            //}
        }
    };

    const activateCardHandler = (values) => {
        const paymentMethods = [
            {
                amount: parseFloat(values.defaultPayment.amount).toFixed(2),
                method_id: values.defaultPayment.payment_method.id,
            },
        ];
        if (values.payments) {
            values.payments.map((payment) => {
                paymentMethods.push({
                    amount: parseFloat(payment.amount).toFixed(2),
                    method_id: payment.payment_method.id,
                });
            });
        }

        const setDesk = desks && desks.length > 1 ? values.desk.id : desks[0].id;

        let setChannels;
        
        if (isChannelMandatory) {
            setChannels = channels && channels.length > 1 ? values.channel.id : channels[0].id;
        } else {
            if (values.channel) {
                setChannels = values.channel.id;
            } else {
                setChannels = 0;
            }
        }

        let setFields = [];

        if (values.fields) {
            values.fields.map((item, index) => {
                setFields.push({
                    field_id: parseInt(index),
                    value: item.id ? item.id : item,
                });
            });
        }

        let setAdditionalProducts = [];

        if (values.additional_products) {
            values.additional_products.map((item, index) => {
                setAdditionalProducts.push({
                    product_id: parseInt(item.product_id.id),
                    quantity: parseInt(item.quantity),
                });
            });
        }

        setTimeout(function () {
            dispatch(loadCards(cards, paymentMethods, setFields, setAdditionalProducts, setChannels, setDesk));
            document.body.style.overflow = "hidden";
            setVisibility(!visibility);
            //document.body.classList.toggle("modal-active");
        }, 1000);
    };

    const closeModalHandler = (e) => {
        document.body.style.overflow = "unset";
        e.preventDefault();
        setVisibility(false);
    };

    const loadAnotherCards = () => {
        setVisibility(!visibility);
        dispatch({ type: INITIAL_LOAD_CARD });
        setTimeout(function () {
            dispatch(getTicketNumber());
        }, 200);
    };

    const getPaymentsTotal = (value) => {
        console.log(value);
        if (!value || !value.defaultPayment) {
            return 0;
        }

        if (value.defaultPayment && (!value.payments || value.payments.length === 0)) {
            value.defaultPayment.amount = parseFloat(getSubTotal(value), 10);
        }

        let total = parseFloat(value.defaultPayment.amount, 10);
        if (value.payments) {
            value.payments.map((payment) => {
                total += parseFloat(payment.amount, 10);
            });
        }
        return total;
    };

    const getSubTotal = (value) => {
        if (value === 0) {
            return 0;
        } else {
            const productsTotal = getAddedProductsTotal(value);
            return parseFloat(productsTotal, 10) + parseFloat(+totalAmount, 10);
        }
    };

    const getAddedProductsTotal = (value) => {
        let total = 0;

        if (value.additional_products) {
            value.additional_products
                .filter((product) => product.product_id && product.quantity)
                .map((product) => {
                    total += parseInt(product.quantity) * parseFloat(product.product_id.total_price);
                });
        }
        return total;
    };

    const isNumericValue = (value) => {
        return isNaN(value) ? t("Please-enter-a-numeric-value") : undefined;
    };

    const fieldsList = (fields, values) => {
        return fields.map((name, index) => {
            if (name.type === "text") {
                return (
                    <Field
                        key={name.id}
                        name={`fields.${name.id}`}
                        validate={(value) => {
                            return requiredIf({
                                value,
                                condition: name.amount_for_required === 0,
                            });
                        }}
                    >
                        {({ input, meta }) => (
                            <div>
                                <label className=" text-sm text-gray-600 pb-1 block">
                                    {name.label}
                                    {name.amount_for_required === 0 && <span className="text-gfCoral">*</span>}
                                    {name.amount_for_required > 0 && <span className="text-grey-900">*</span>}
                                </label>
                                <input
                                    className={`border rounded-md px-3 py-2 mt-1 mb-1 text-sm w-full ${
                                        meta.error && meta.touched ? "border-red-500a" : ""
                                    }`}
                                    {...input}
                                    type="text"
                                />
                                {meta.error && meta.touched && (
                                    <span className="text-sm text-gfCoral pr-1">{meta.error}</span>
                                )}
                                {name.amount_for_required > 0 && (
                                    <span className="text-grey-900 text-sm">
                                        (Required if the total is more than {name.amount_for_required.toFixed(2)})
                                    </span>
                                )}
                            </div>
                        )}
                    </Field>
                );
            } else if (name.type === "select") {
                return (
                    <Field
                        key={name.id}
                        name={`fields.${name.id}`}
                        validate={(value) => {
                            return requiredIf({
                                value,
                                condition: name.amount_for_required === 0,
                            });
                        }}
                    >
                        {({ input, meta }) => (
                            <div>
                                <label className=" text-sm text-gray-600 pb-1 block">
                                    {name.label}
                                    <span className="text-gfCoral">{name.amount_for_required === 0 ? "*" : ""}</span>
                                    {name.amount_for_required > 0 && <span className="text-grey-900">*</span>}
                                </label>
                                <Select
                                    styles={customStyles((option) => option)}
                                    className={`mt-1 mb-1 text-sm w-full ${
                                        meta.error && meta.touched ? "border-red-500a" : ""
                                    }`}
                                    {...input}
                                    isClearable="true"
                                    placeholder={name.label}
                                    options={name.options}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.id}
                                    components={{
                                        IndicatorSeparator: null,
                                    }}
                                />
                                {meta.error && meta.touched && (
                                    <span className="text-sm text-gfCoral pr-1">{meta.error}</span>
                                )}
                                {name.amount_for_required > 0 && (
                                    <span className="text-grey-900 text-sm">
                                        (Required if the total is more than {name.amount_for_required.toFixed(2)})
                                    </span>
                                )}
                            </div>
                        )}
                    </Field>
                );
            } else if (name.type === "tel") {
                return (
                    <Field
                        key={name.id}
                        name={`fields.${name.id}`}
                        validate={(value) => {
                            return requiredPhone({
                                value,
                                condition: name.amount_for_required === 0,
                            });
                        }}
                    >
                        {({ input, meta }) => (
                            <div>
                                <label className=" text-sm text-gray-600 pb-1 block">
                                    {name.label}
                                    {name.amount_for_required === 0 && <span className="text-gfCoral">*</span>}
                                    {name.amount_for_required > 0 && <span className="text-grey-900">*</span>}
                                </label>
                                <input
                                    className={`border rounded-md px-3 py-2 mt-1 mb-1 text-sm w-full ${
                                        meta.error && meta.touched ? "border-red-500a" : ""
                                    }`}
                                    {...input}
                                    type="text"
                                />
                                {meta.error && meta.touched && (
                                    <span className="text-sm text-gfCoral pr-1">{meta.error}</span>
                                )}
                                {name.amount_for_required > 0 && (
                                    <span className="text-grey-900 text-sm">
                                        (Required if the total is more than {name.amount_for_required.toFixed(2)})
                                    </span>
                                )}
                            </div>
                        )}
                    </Field>
                );
            } else if (name.type === "email") {
                return (
                    <Field
                        key={name.id}
                        name={`fields.${name.id}`}
                        validate={(value) => {
                            return requiredEmail({
                                value,
                                condition: name.amount_for_required === 0,
                            });
                        }}
                    >
                        {({ input, meta }) => (
                            <div>
                                <label className=" text-sm text-gray-600 pb-1 block">
                                    {name.label}
                                    {name.amount_for_required === 0 && <span className="text-gfCoral">*</span>}
                                    {name.amount_for_required > 0 && <span className="text-grey-900">*</span>}
                                </label>
                                <input
                                    className={`border rounded-md px-3 py-2 mt-1 mb-1 text-sm w-full ${
                                        meta.error && meta.touched ? "border-red-500a" : ""
                                    }`}
                                    {...input}
                                    type="text"
                                />
                                {meta.error && meta.touched && (
                                    <span className="text-sm text-gfCoral pr-1">{meta.error}</span>
                                )}
                                {name.amount_for_required > 0 && (
                                    <span className="text-grey-900 text-sm">
                                        (Required if the total is more than {name.amount_for_required.toFixed(2)})
                                    </span>
                                )}
                            </div>
                        )}
                    </Field>
                );
            }
        });
    };

    const invalidChars = ["-", "+", "e"];

    const paymentMethodsList = (fields, values) => (
        <>
            <tr>
                <td className="px-2 py-1  whitespace-nowrap">
                    <Field
                        name="defaultPayment.payment_method"
                        validate={(value) => requiredField({ value, fieldName: t("Payment-method") })}
                        defaultValue={deafultPaymentmethod}
                    >
                        {({ input, meta }) => (
                            <div className={`w-full`}>
                                <Select
                                    styles={customStyles((option) => option)}
                                    placeholder={t("Payment-method")}
                                    className="text-sm w-full"
                                    {...input}
                                    options={filteredPaymentMethods}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isSearchable={true}
                                    components={{
                                        IndicatorSeparator: null,
                                    }}
                                    onChange={(e) => {
                                        input.onChange(e);
                                        dispatch({ type: REMOVE_PAYMENT_METHOD, payload: { item: e, index: -1 } });
                                    }}
                                />
                                {meta.error && meta.touched && (
                                    <div className="text-sm h-2 text-gfCoral">{meta.error}</div>
                                )}
                            </div>
                        )}
                    </Field>
                </td>
                {fields.length !== 0 && (
                    <td className="px-2 py-1  whitespace-nowrap">
                        <Field
                            name="defaultPayment.amount"
                            defaultValue={deafultPaymentmethod}
                            validate={(value) => {
                                return requiredField({ value, fieldName: t("Amount") }) || isNumericValue(value);
                            }}
                        >
                            {({ input, meta }) => (
                                <div className={`py-2 border-gfDarkSand ${fields.length === 0 ? "hidden" : ""} `}>
                                    <input
                                        placeholder={t("Amount")}
                                        className={`border rounded placeholder-gfGrey px-3 py-2 text-sm w-full ${
                                            meta.error && meta.touched ? "border-red-500a" : "border-gfPeriwinkle"
                                        }`}
                                        {...input}
                                        type="number"
                                        onKeyDown={(e) => {
                                            if (invalidChars.includes(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {meta.error && meta.touched && (
                                        <div className="text-sm text-gfCoral h-2">{meta.error}</div>
                                    )}
                                </div>
                            )}
                        </Field>
                    </td>
                )}
                <td className="px-2 py-1  whitespace-nowrap"></td>
            </tr>
            {fields.map((name, index) => (
                <tr key={`payment-${index}`}>
                    <td className="px-2 py-1  whitespace-nowrap">
                        <Field
                            name={`${name}.payment_method`}
                            validate={(value) => requiredField({ value, fieldName: t("Payment-method") })}
                            defaultValue={deafultPaymentmethod}
                        >
                            {({ input, meta }) => (
                                <div className={`${false} ? "hidden" : "" py-2`}>
                                    <Select
                                        styles={customStyles((option) => option)}
                                        placeholder={t("Payment-method")}
                                        className="text-sm w-full"
                                        {...input}
                                        options={filteredPaymentMethods}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isSearchable={true}
                                        components={{
                                            IndicatorSeparator: null,
                                        }}
                                        onChange={(e) => {
                                            input.onChange(e);
                                            dispatch({
                                                type: REMOVE_PAYMENT_METHOD,
                                                payload: { item: e, index: index },
                                            });
                                        }}
                                    />
                                    {meta.error && meta.touched && (
                                        <div className="text-sm text-gfCoral">{meta.error}</div>
                                    )}
                                </div>
                            )}
                        </Field>
                    </td>
                    <td className="px-2 py-1  whitespace-nowrap">
                        <Field
                            name={`${name}.amount`}
                            validate={(value) => {
                                return requiredField({ value, fieldName: t("Amount") }) || isNumericValue(value);
                            }}
                        >
                            {({ input, meta }) => (
                                <div className="py-2">
                                    <input
                                        placeholder="Amount"
                                        className={`border placeholder-gfGrey rounded px-3 py-2 mt-1 mb-1 text-sm w-full ${
                                            meta.error && meta.touched ? "border-red-500a" : "border-gfPeriwinkle"
                                        }`}
                                        {...input}
                                        type="number"
                                        onKeyDown={(e) => {
                                            if (invalidChars.includes(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {meta.error && meta.touched && (
                                        <div className="text-sm text-gfCoral">{meta.error}</div>
                                    )}
                                </div>
                            )}
                        </Field>
                    </td>
                    <td className="px-2 py-1  whitespace-nowrap">
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    fields.remove(index);
                                    dispatch({ type: ADD_PAYMENT_METHOD, payload: index });
                                }}
                                className="mt-3 rounded-md text-gray-300 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-white"
                            >
                                <span className="sr-only">Close panel</span>
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </td>
                </tr>
            ))}
        </>
    );

    const additionalProductsList = (fields, values) => {
        return fields.map((name, index) => (
            <tr key={index}>
                <td className="px-2 whitespace-nowrap flex flex-col justify-start items-start">
                    <Field
                        name={`${name}.product_id`}
                        validate={(value) => requiredField({ value, fieldName: t("Product") })}
                    >
                        {({ input, meta }) => (
                            <div className="w-full">
                                <Select
                                    styles={customStyles((option) => option)}
                                    placeholder={`${t("Product")} *`}
                                    className={`mt-1 mb-1 text-sm ${
                                        meta.error && meta.touched ? "border-red-500a" : ""
                                    }`}
                                    {...input}
                                    options={additionalProducts}
                                    getOptionLabel={(option) =>
                                        `${option.name} - ${option.price + option.tax} ${option.currency}`
                                    }
                                    getOptionValue={(option) => option.id}
                                    isSearchable={true}
                                    components={{
                                        IndicatorSeparator: null,
                                    }}
                                />
                                {meta.error && meta.touched && (
                                    <div className="text-sm h-3 text-gfCoral">{meta.error}</div>
                                )}
                            </div>
                        )}
                    </Field>
                </td>
                <td className="px-2 whitespace-nowrap">
                    <Field
                        name={`${name}.quantity`}
                        validate={(value) => requiredField({ value, fieldName: t("Quantity") })}
                    >
                        {({ input, meta }) => (
                            <div>
                                <input
                                    placeholder={`${t("Quantity")} *`}
                                    maxLength="4"
                                    className={`border h-10 placeholder-gfGrey focus:outline-none rounded-md px-3 py-2 mt-1 mb-1 text-sm w-full ${
                                        meta.error && meta.touched ? "border-red-500a" : "border-gfPeriwinkle"
                                    }`}
                                    {...input}
                                    type="type"
                                />
                                {meta.error && meta.touched && <div className="text-sm text-gfCoral">{meta.error}</div>}
                            </div>
                        )}
                    </Field>
                </td>

                <td className="px-2 text-center">
                    <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={getProductValues(
                            values["additional_products"][index],
                            "total_price",
                            values["additional_products"][index]["quantity"]
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                    />
                </td>
                <td className="text-center">
                    <div>
                        <button
                            type="button"
                            onClick={() => fields.remove(index)}
                            className="mt-3 rounded-md text-gfPeriwinkle hover:text-gfDarkBlue focus:outline-none focus:ring-2 focus:ring-white"
                        >
                            <span className="sr-only">Close panel</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>
        ));
    };

    const getProductValues = (values, field, quantity) => {
        if (values["product_id"]) {
            return values["product_id"][field] * (typeof quantity === "undefined" ? 0 : quantity);
        }
        return 0;
    };

    const cancelLoadCardHandler = () => {
        window.location.reload();
        document.body.style.overflow = "unset";
        setVisibility(false);
    };

    const tryAginLoadCardHandler = () => {
        dispatch({ type: RESET_VALIDATION_ERRORS });
        dispatch(getTicketNumber()).then(
            function (success) {
                document.body.style.overflow = "unset";
                setVisibility(false);
            },
            function (error) {
                console.log(error);
            }
        );
    };

    const AdditionalProducts = (product) => {
        let rows = [];

        for (let i = 0; i < product.quantity; i++) {
            rows.push(
                <div className="mb-2">
                    <div className="flex flex-row justify-start item-center">
                        <span className="font-MulishBold">{`${t("Name")}: `}</span>
                        <span className="mx-2">{product.product.name}</span>
                    </div>
                    <div className="flex flex-row justify-start item-center">
                        <span className="font-MulishBold">{`${t("price")}: `}</span>
                        <div className="flex justify-start ml-2">
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={product.product.price}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                            <span className="ml-2">{tagCurrency}</span>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start item-center">
                        <span className="font-MulishBold">{`${t("Included-Tax")}: `}</span>
                        <div className="flex justify-start ml-2">
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={product.product.tax}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                            <span className="ml-2">{tagCurrency}</span>
                        </div>
                    </div>
                </div>
            );
        }
        return rows;
    };

    const filedsList = (field) => {
        return (
            parseInt(field.show_in_print) === 1 && (
                <div key={field.id} className="flex flex-row">
                    <span style={{ fontWeight: "bold" }}>{`${field.form_field.label}: `}</span>
                    <span style={{ marginLeft: "4px" }}>{field.value}</span>
                </div>
            )
        );
    };

    const checkFileldSet = (fields) => {
        let showInPrint = false;
        fields.forEach((element) => {
            if (parseInt(element.show_in_print) === 1) {
                showInPrint = true;
            } else {
                showInPrint = false;
            }
        });

        return showInPrint;
    };

    return (
        <div
            key={componentKey}
            className="shadow-md px-4 sm:px-6 lg:px-8 sm:m-2 m-2 md:my-10 lg:my-10 md:mx-10 lg:mx-10 2xl:mx-20 bg-white text-gfDarkBlue font-MulishRegular rounded"
        >
            <div className="flex flex-row">
                <PageTitle title={t("Load-card")} />
            </div>
            <div className="border-t border-gfPeriwinkle px-4 py-5 sm:px-6">
                <div className="flex flex-col mx-auto justify-center items-center">
                    <div className="flex w-full flex-col" key={componentKey}>
                        <Form
                            onSubmit={onSubmit}
                            mutators={{
                                ...arrayMutators,
                            }}
                            render={({ handleSubmit, reset, form, submitting, pristine, values, payments }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex flex-col space-y-8">
                                        <div className="border rounded p-2 md:p-5">
                                            <div className="pb-5">
                                                {t(
                                                    "Either-type-the-token-manually-or-swipe-the-card-in-a-magstripe-reader."
                                                )}
                                            </div>
                                            <AddCard />
                                        </div>
                                        {additionalProducts && additionalProducts.length !== 0 && (
                                            <div className="border rounded p-2 md:p-5">
                                                <FieldArray name="additional_products">
                                                    {({ fields }) => (
                                                        <div>
                                                            <div className="flex flex-row justify-between items-center">
                                                                <h3 className="font-MulishBold">
                                                                    {t("Additional-products")}
                                                                </h3>
                                                                <button
                                                                    className="bg-gfPeriwinkle border border-gfPeriwinkle text-gfDarkBlue font-MulishBold rounded text-sm  py-2 px-3 hover:bg-white focus:outline-none"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        fields.push({
                                                                            product_id: null,
                                                                            quantity: null,
                                                                        })
                                                                    }
                                                                >
                                                                    {t("Add-a-product")}
                                                                </button>
                                                            </div>
                                                            {fields.length !== 0 && (
                                                                <div className="py-2 align-middle inline-block min-w-full">
                                                                    <div className="border-b border-gray-300">
                                                                        <table className="min-w-full divide-y divide-gray-300">
                                                                            <thead className="bg-gfPeriwinkle bg-opacity-25 text-gfDarkBlue font-MulishBold">
                                                                                <tr>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="w-2/6 px-2 py-1 text-left text-xs   uppercase tracking-wider"
                                                                                    >
                                                                                        {t("Product-name")}
                                                                                        <span className="text-gfCoral">
                                                                                            {" "}
                                                                                            *
                                                                                        </span>
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="w-2/6 px-2 py-1 text-left text-xs   uppercase tracking-wider"
                                                                                    >
                                                                                        {t("Quantity")}
                                                                                        <span className="text-gfCoral">
                                                                                            {" "}
                                                                                            *
                                                                                        </span>
                                                                                    </th>

                                                                                    <th
                                                                                        scope="col"
                                                                                        className="w-1/6 px-2 py-1 text-center text-xs   uppercase tracking-wider"
                                                                                    >
                                                                                        {t("Sub-total")}
                                                                                    </th>
                                                                                    <th className="w-1/6"></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="bg-white divide-y">
                                                                                {additionalProductsList(fields, values)}

                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td className="text-centers flex flex-col items-center justify-center text-gfLightBlue font-MulishBold">{`${parseFloat(
                                                                                        getAddedProductsTotal(values),
                                                                                        10
                                                                                    ).toFixed(2)}`}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </FieldArray>

                                                {/* <div>
                                                    {submitted &&
                                                    parseFloat(getAddedProductsTotal(values), 10).toFixed(2) >
                                                        cards.reduce((a, card) => (a += parseFloat(card.amount)), 0) *
                                                            2 ? (
                                                        <div className="border border-gfCoral rounded bg-gfCoral bg-opacity-25 text-gfCoral font-MulishBold py-2 px-4">
                                                            {t(
                                                                "Additional-product-total-should-not-exceed-twice-time-of-loaded-card-total"
                                                            )}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div> */}
                                            </div>
                                        )}
                                        {channels && channels.length > 1 && (
                                            <div className="border rounded p-2 md:p-5">
                                                {isChannelMandatory ? (
                                                    <Field
                                                        name="channel"
                                                        validate={(value) =>
                                                            requiredField({ value, fieldName: t("Channel") })
                                                        }
                                                        defaultValue={deafultChannel}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div className="w-full md:w-1/2 xl:w-2/6 2xl:w-2/6">
                                                                <label className="font-MulishBold text-gfDarkBlue pb-4 block">
                                                                    {t("Channel")}
                                                                    {isChannelMandatory && (
                                                                        <span className="text-gfCoral px-1">*</span>
                                                                    )}
                                                                </label>
                                                                <Select
                                                                    {...input}
                                                                    styles={customStyles((option) => option)}
                                                                    placeholder={t("Channel")}
                                                                    options={channels}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.id}
                                                                    isSearchable={true}
                                                                    components={{
                                                                        IndicatorSeparator: null,
                                                                    }}
                                                                />
                                                                {meta.error && meta.touched && (
                                                                    <span className="text-sm text-red-500">
                                                                        {meta.error}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Field>
                                                ) : (
                                                    <Field name="channel" defaultValue={deafultChannel}>
                                                        {({ input, meta }) => (
                                                            <div className="w-full md:w-1/2 xl:w-2/6 2xl:w-2/6">
                                                                <label className="font-MulishBold text-gfDarkBlue pb-4 block">
                                                                    {t("Channel")}
                                                                </label>
                                                                <Select
                                                                    {...input}
                                                                    styles={customStyles((option) => option)}
                                                                    placeholder={t("Channel")}
                                                                    options={channels}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.id}
                                                                    isSearchable={true}
                                                                    components={{
                                                                        IndicatorSeparator: null,
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                )}
                                                {/* <Field name="channel" validate={(value) => requiredChannelField({ value })}>
                                                {({ input, meta }) => (
                                                    <div className="w-full md:w-1/2 xl:w-2/6 2xl:w-2/6">
                                                        <label className="font-MulishBold text-gfDarkBlue pb-4 block">
                                                            {t("Channel")}
                                                            {isChannelMandatory && <span className="text-gfCoral px-1">*</span>}
                                                        </label>
                                                        <Select
                                                            {...input}
                                                            styles={customStyles((option) => option)}
                                                            placeholder={t("Channel")}
                                                            options={channels}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            isSearchable={true}
                                                            components={{
                                                                IndicatorSeparator: null,
                                                            }}
                                                        />
                                                        {meta.error && meta.touched && <span className="text-sm text-red-500">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field> */}
                                            </div>
                                        )}
                                        {desks && desks.length > 1 && (
                                            <div className="border rounded p-2 md:p-5">
                                                <Field
                                                    name="desk"
                                                    validate={(value) => requiredField({ value, fieldName: t("Desk") })}
                                                >
                                                    {({ input, meta }) => (
                                                        <div className="w-full md:w-1/2 xl:w-2/6 2xl:w-2/6">
                                                            <label className=" font-MulishBold text-gfDarkBlue pb-4 block">
                                                                {t("Desk")}
                                                            </label>
                                                            <Select
                                                                {...input}
                                                                styles={customStyles((option) => option)}
                                                                placeholder={t("Desk *")}
                                                                options={desks}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id}
                                                                isSearchable={true}
                                                                components={{
                                                                    IndicatorSeparator: null,
                                                                }}
                                                            />
                                                            {meta.error && meta.touched && (
                                                                <span className="text-sm text-gfCoral">
                                                                    {meta.error}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        )}
                                        {fields && fields.length !== 0 && (
                                            <div className="border rounded p-2 md:p-5">
                                                <div className="text-gfDarkBlue font-MulishBold pb-4">
                                                    {t("Client-Information")}
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                                    <FieldArray name="fieldsList">
                                                        {() => fieldsList(fields, values)}
                                                    </FieldArray>
                                                </div>
                                            </div>
                                        )}
                                        {paymentMethods && paymentMethods.length !== 0 && (
                                            <div className="border rounded p-2 md:p-5">
                                                <FieldArray name="payments">
                                                    {({ fields, values }) => (
                                                        <div>
                                                            <div className="flex flex-row justify-between items-center mb-5">
                                                                <div className=" text-gfDarkBlue font-MulishBold">
                                                                    {t("Payment-methods")}
                                                                </div>
                                                                {filteredPaymentMethods &&
                                                                    filteredPaymentMethods.length !== 0 && (
                                                                        <button
                                                                            className="bg-gfPeriwinkle border border-gfPeriwinkle font-MulishBold text-gfDarkBlue rounded text-sm  py-2 px-3 hover:bg-white focus:outline-none"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                fields.push({
                                                                                    payment_method: null,
                                                                                    amount: null,
                                                                                })
                                                                            }
                                                                        >
                                                                            {t("Add-payment")}
                                                                        </button>
                                                                    )}
                                                            </div>
                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                <thead className="bg-gfPeriwinkle bg-opacity-25 font-MulishBold text-gfDarkBlue">
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="w-full md:w-2/6 px-2 py-1 text-left text-xs   uppercase tracking-wider"
                                                                        >
                                                                            {t("Payment-method")}
                                                                            <span className="text-gfCoral"> *</span>
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="w-full md:w-2/12 px-2 py-1 text-left text-xs   uppercase tracking-wider"
                                                                        >
                                                                            {t("Amount")}
                                                                            <span className="text-gfCoral"> *</span>
                                                                        </th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="bg-white divide-y divide-gray-400">
                                                                    {paymentMethodsList(fields, values)}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        )}
                                        <div>
                                            {submitted &&
                                            parseFloat(getPaymentsTotal(values), 10) !==
                                                parseFloat(getSubTotal(values), 10) ? (
                                                <div className="border border-gfCoral rounded bg-opacity-10 bg-gfCoral text-gfCoral font-MulishBold py-2 px-4">
                                                    {t(
                                                        "The-input-amount-is-does-not-correspond-to-the-total-of-the-purchase",
                                                        {
                                                            amount: parseFloat(getSubTotal(values), 10),
                                                            currency: tagCurrency,
                                                        }
                                                    )}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="border rounded p-2 md:p-5">
                                            <div className="flex flex-col">
                                                <div className="font-bold text-xl flex flex-row justify-end text-gfDarkBlue space-x-3 mb-5">
                                                    <span>{t("Total")}:</span>
                                                    <div className="flex justify-start text-gfLightBlue font-MulishBlack ">
                                                        <CurrencyFormat
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            value={getSubTotal(values)}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        />
                                                        <span className="ml-1">{`${tagCurrency}`}</span>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col md:flex-row justify-end">
                                                    <button
                                                        disabled={cards.length === 0 || isCardRangeDirty}
                                                        type="submit"
                                                        className={`${
                                                            cards.length === 0 || isCardRangeDirty
                                                                ? "bg-opacity-50"
                                                                : "bg-opacity-100"
                                                        }  focus:outline-none py-2 px-8 text-base font-MulishBold text-gfDarkBlue bg-gfCoral rounded`}
                                                    >
                                                        {t("Activate-and-load-the-card")}
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            dispatch({ type: RESET_LOAD_CARD });
                                                            window.location.reload();
                                                        }}
                                                        type="reset"
                                                        className={`md:ml-8 ml-0 md:mt-0 mt-4 focus:outline-none py-2 px-8 font-MulishBold text-gfDarkBlue bg-gfPeriwinkle rounded`}
                                                    >
                                                        {t("Reset")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>

            {
                <ReactModal
                    isOpen={visibility}
                    contentLabel="LoadCard"
                    className="bg-gray-700 z-50"
                    style={{
                        overlay: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(23, 35, 82, 0.6)",
                            zIndex: 9999,
                            backdropFilter: "blur(12px)",
                        },
                        content: {
                            position: "absolute",
                            top: "50px",
                            left: "100px",
                            right: "100px",
                            bottom: "100px",

                            backgroundColor: "rgba(23, 35, 82, 0.0)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "50px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                        },
                    }}
                >
                    <div className="flex flex-col  mx-auto py-3 px-5 border max-w-xl rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
                        <div className="flex justify-between items-center ">
                            <div className="font-MulishBold">{t("Load-Card")}</div>
                            <button
                                className="focus:outline-none"
                                onClick={(event) => {
                                    closeModalHandler(event);
                                    cancelLoadCardHandler();
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        {(loadingLoadCards || loadingLoadCardPayments || loadingTicketNumber) && (
                            <div className="mx-auto py-7">
                                {(loadingLoadCards || loadingLoadCardPayments) && (
                                    <div className="py-7 flex justify-center items-center">
                                        <ButtonLoading color={GF_DARK_BLUE} />
                                    </div>
                                )}
                                {loadingLoadCards && <div>{`${t("Cards-are-loading")}...`}</div>}
                                {loadingLoadCardPayments && <div>{`${t("Payment-is-being-processed")}...`}</div>}
                                {loadingTicketNumber && <div>{`${t("Getting-New-Ticket")}...`}</div>}
                            </div>
                        )}
                        {errorLoadCards && (
                            <div className="py-4 space-y-5">
                                {errorLoadCards && <FailAlert message={errorLoadCards.message} />}

                                {validationErrors && (
                                    <div className="py-2">
                                        <ValidationErrors {...validationErrors} />
                                    </div>
                                )}

                                <div className="flex flex-row justify-between space-x-5">
                                    <button
                                        onClick={cancelLoadCardHandler}
                                        className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                                    >
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        onClick={tryAginLoadCardHandler}
                                        className="bg-gfCoral w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                                    >
                                        {loadingTicketNumber && <ButtonLoading color={GF_DARK_BLUE} />}
                                        {t("Try-Again")}
                                    </button>
                                </div>
                            </div>
                        )}

                        {errorLoadCardsPayments && (
                            <div className="py-4 space-y-5">
                                {errorLoadCardsPayments && <FailAlert message={errorLoadCardsPayments.message} />}

                                {validationErrors && (
                                    <div className="py-2">
                                        <ValidationErrors {...validationErrors} />
                                    </div>
                                )}

                                <div className="flex flex-row justify-between space-x-5">
                                    <button
                                        onClick={cancelLoadCardHandler}
                                        className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                                    >
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        onClick={tryAginLoadCardHandler}
                                        className="bg-gfCoral w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                                    >
                                        {loadingTicketNumber && <ButtonLoading color={GF_DARK_BLUE} />}
                                        {t("Try-Again")}
                                    </button>
                                </div>
                            </div>
                        )}
                        {loadCardPayments && (
                            <div className="py-4 space-y-5">
                                {loadCardPayments && !receivedTicketInfo && (
                                    <SuccessAlert message={loadCardPayments.message} />
                                )}

                                <div className="flex flex-row justify-between space-x-5">
                                    <button
                                        onClick={cancelLoadCardHandler}
                                        className="bg-gfCoral w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                                    >
                                        {loadingTicketNumber && <ButtonLoading color={GF_DARK_BLUE} />}
                                        {t("Load-another-card")}
                                    </button>
                                    <button
                                        onClick={printTicket}
                                        className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
                                    >
                                        {t("Print")}
                                    </button>
                                </div>

                                {receivedTicketInfo && (
                                    <div
                                        ref={componentRef}
                                        style={{
                                            maxWidth: "165px",
                                            margin: "auto",
                                            backgroundColor: "white",
                                            color: "black",
                                            fontSize: "10px",
                                            paddingRight: "20px",
                                            fontFamily:
                                                "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                                        }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px" }}>
                                            <img
                                                style={{ maxWidth: "100px" }}
                                                src={`${process.env.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
                                                alt="logo"
                                            />
                                        </div>

                                        <div
                                            style={{
                                                textAlign: "left",
                                                fontSize: "16px",
                                                paddingTop: "30px",
                                                paddingBottom: "30px",
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            {selectedTag.name}
                                        </div>

                                        {receivedTicketInfo.user && receivedTicketInfo.user !== null && (
                                            <div style={{ display: "flex", paddingTop: "3px", paddingBottom: "3px" }}>
                                                <span style={{ fontWeight: "bold" }}>{`${t("User")}: `}</span>
                                                <span style={{ marginLeft: "4px" }}>{`${String(
                                                    receivedTicketInfo.user.first_name.charAt(0)
                                                ).toUpperCase()} ${String(
                                                    receivedTicketInfo.user.last_name.charAt(0)
                                                ).toUpperCase()}`}</span>
                                            </div>
                                        )}

                                        <div style={{ display: "flex", paddingTop: "3px", paddingBottom: "3px" }}>
                                            <span style={{ fontWeight: "bold" }}>{`${t("Transaction")}: `}</span>
                                            <span style={{ marginLeft: "4px" }}>
                                                {receivedTicketInfo.ticket_number}
                                            </span>
                                        </div>

                                        {receivedTicketInfo.payments && receivedTicketInfo.payments.length !== 0 && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    paddingTop: "3px",
                                                    paddingBottom: "3px",
                                                }}
                                            >
                                                <div className="flex flex-row">
                                                    <span style={{ fontWeight: "bold" }}>{`${t(
                                                        "Payment-methods"
                                                    )}: `}</span>
                                                </div>
                                                {receivedTicketInfo.payments.map((payment) => (
                                                    <div className="flex flex-row" key={payment.id}>
                                                        <span className="capitalize">{payment.name}</span>
                                                        <span> (</span>
                                                        <div className="flex justify-start">
                                                            <CurrencyFormat
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                value={payment.total_amount}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                            />
                                                            <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                        </div>
                                                        <span>)</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {receivedTicketInfo.channel && (
                                            <div style={{ textAlign: "left", paddingTop: "3px", paddingBottom: "3px" }}>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        whiteSpace: "nowrap",
                                                        display: "inline-block",
                                                        marginRight: "4px",
                                                    }}
                                                >{`${t("Channel")}: `}</span>
                                                <span style={{ textTransform: "uppercase", display: "inline-block" }}>
                                                    {receivedTicketInfo.channel.name}
                                                </span>
                                            </div>
                                        )}

                                        {receivedTicketInfo.desk && (
                                            <div style={{ textAlign: "left", paddingTop: "3px", paddingBottom: "3px" }}>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        whiteSpace: "nowrap",
                                                        display: "inline-block",
                                                        marginRight: "4px",
                                                    }}
                                                >{`${t("Desk")}: `}</span>
                                                <span style={{ textTransform: "uppercase", display: "inline-block" }}>
                                                    {receivedTicketInfo.desk.name}
                                                </span>
                                            </div>
                                        )}

                                        <div style={{ textAlign: "left", paddingTop: "3px", paddingBottom: "3px" }}>
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    whiteSpace: "nowrap",
                                                    display: "inline-block",
                                                    marginRight: "4px",
                                                }}
                                            >{`${t("Date-of-Purchase")}: `}</span>
                                            <span style={{ display: "inline-block" }}>
                                                {receivedTicketInfo.transaction_date_time}
                                            </span>
                                        </div>

                                        {receivedTicketInfo.entries && receivedTicketInfo.entries.length !== 0 && (
                                            <div>
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        paddingTop: "20px",
                                                        paddingBottom: "10px",
                                                        fontWeight: "bolder",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    {`${t("Gift-Cards")}`}
                                                </div>

                                                {receivedTicketInfo.entries.map((entry) => (
                                                    <div
                                                        style={{ paddingTop: "3px", paddingBottom: "3px" }}
                                                        key={entry.token}
                                                    >
                                                        <div className="flex ">
                                                            <span style={{ fontWeight: "bold" }}>{`${t(
                                                                "Token"
                                                            )}: `}</span>
                                                            <span style={{ marginLeft: "4px" }}>{entry.token}</span>
                                                        </div>
                                                        <div className="flex ">
                                                            <span style={{ fontWeight: "bold" }}>{`${t(
                                                                "Amount"
                                                            )}: `}</span>
                                                            <div style={{ marginLeft: "4px" }}>
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={entry.amount}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex ">
                                                            <span style={{ fontWeight: "bold" }}>{`${t(
                                                                "Expiry-date"
                                                            )}: `}</span>
                                                            <span style={{ marginLeft: "4px" }}>
                                                                {entry.expiry_date}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div style={{ paddingLeft: "12px", marginTop: "10px" }}>
                                                    <div className="flex ">
                                                        <span style={{ fontWeight: "bold" }}>{`${t("Cards")}: `}</span>
                                                        <span style={{ marginLeft: "4px" }}>
                                                            {receivedTicketInfo.no_of_entries}
                                                        </span>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <span style={{ fontWeight: "bold" }}>{`${t("Amount")}: `}</span>
                                                        <div style={{ marginLeft: "4px" }}>
                                                            <CurrencyFormat
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                value={receivedTicketInfo.entries.reduce(
                                                                    (sum, item) => {
                                                                        sum += item.amount;
                                                                        return sum;
                                                                    },
                                                                    0
                                                                )}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                            />
                                                            <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {receivedTicketInfo.additional_products &&
                                            receivedTicketInfo.additional_products.length !== 0 && (
                                                <div className="" key={randomString(2)}>
                                                    <div
                                                        style={{
                                                            textAlign: "left",
                                                            paddingTop: "20px",
                                                            paddingBottom: "10px",
                                                            fontWeight: "bolder",
                                                            fontSize: "13px",
                                                        }}
                                                    >{`${t("Additional-products")}`}</div>
                                                    {receivedTicketInfo.additional_products.map((product) =>
                                                        AdditionalProducts(product)
                                                    )}

                                                    <div style={{ paddingLeft: "12px" }}>
                                                        <div className="flex flex-row items-center">
                                                            <span style={{ fontWeight: "bold" }}>{`${t(
                                                                "Products"
                                                            )}: `}</span>
                                                            <span style={{ marginLeft: "4px" }}>
                                                                {receivedTicketInfo.total_product_count}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-row items-center">
                                                            <span style={{ fontWeight: "bold" }}>{`${t(
                                                                "Amount"
                                                            )}: `}</span>
                                                            <div className="ml-1">
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={receivedTicketInfo.total_product_price}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-center ">
                                                            <span style={{ fontWeight: "bold" }}>{`${t(
                                                                "Included-Tax"
                                                            )}: `}</span>
                                                            <div style={{ marginLeft: "4px" }}>
                                                                <CurrencyFormat
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={receivedTicketInfo.total_product_tax}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                />
                                                                <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        <div className="">
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    paddingTop: "20px",
                                                    paddingBottom: "10px",
                                                    fontWeight: "bolder",
                                                    fontSize: "13px",
                                                }}
                                            >{`${t("Order-Recap")}`}</div>
                                            <div>
                                                <div className="flex flex-row items-center">
                                                    <span style={{ fontWeight: "bold" }}>{`${t("Cards")}: `}</span>
                                                    <span style={{ marginLeft: "4px" }}>
                                                        {receivedTicketInfo.no_of_entries}
                                                    </span>
                                                </div>
                                                {receivedTicketInfo.additional_products &&
                                                    receivedTicketInfo.additional_products.length !== 0 && (
                                                        <div className="flex flex-row items-center">
                                                            <span style={{ fontWeight: "bold" }}>{`${t(
                                                                "Products"
                                                            )}: `}</span>
                                                            <span style={{ marginLeft: "4px" }}>
                                                                {receivedTicketInfo.total_product_count}
                                                            </span>
                                                        </div>
                                                    )}
                                                <div className="flex flex-row items-center">
                                                    <span style={{ fontWeight: "bold" }}>{`${t(
                                                        "Total-amount"
                                                    )}: `}</span>
                                                    <div className="flex flex-row ml-1">
                                                        <CurrencyFormat
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            value={receivedTicketInfo.total_amount}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        />
                                                        <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-center">
                                                    <span style={{ fontWeight: "bold" }}>{`${t(
                                                        "Included-Tax"
                                                    )}:`}</span>
                                                    {receivedTicketInfo.additional_products && (
                                                        <div className="flex flex-row ml-1">
                                                            <CurrencyFormat
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                value={receivedTicketInfo.total_product_tax}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                            />
                                                            <span style={{ marginLeft: "4px" }}>{tagCurrency}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {receivedTicketInfo.fields &&
                                            receivedTicketInfo.fields.length !== 0 &&
                                            checkFileldSet(receivedTicketInfo.fields) && (
                                                <div>
                                                    <div
                                                        style={{
                                                            textAlign: "left",
                                                            paddingTop: "20px",
                                                            paddingBottom: "10px",
                                                            fontWeight: "bolder",
                                                            fontSize: "13px",
                                                        }}
                                                    >{`${t("User-Information")}`}</div>
                                                    <div>
                                                        {receivedTicketInfo.fields.map((field) => filedsList(field))}
                                                    </div>
                                                </div>
                                            )}

                                        <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                            <div>{selectedTag.name}</div>
                                            <div className="text-center">{`${selectedTag.address}, ${selectedTag.zip}, ${selectedTag.city}, ${selectedTag.country_code}`}</div>
                                            <div>{selectedTag.support_email}</div>
                                            <div>{selectedTag.support_phone}</div>
                                            <div>{selectedTag.website}</div>
                                        </div>

                                        <div
                                            style={{
                                                textAlign: "center",
                                                paddingBottom: "30px",
                                            }}
                                        >
                                            {selectedTag.ticket_footer}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </ReactModal>
            }
        </div>
    );
}
