import React from "react";
import { GF_GREY } from "../../utils/Constants";

export default function PendingIcon({ w, h }) {
    return (
        <svg width={w} height={h} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0.8125C4.64844 0.8125 0.3125 5.14844 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3516 20.1875 19.6875 15.8516 19.6875 10.5C19.6875 5.14844 15.3516 0.8125 10 0.8125ZM4.84375 12.0625C4.57031 12.0625 4.375 11.8672 4.375 11.5938V9.40625C4.375 9.17188 4.57031 8.9375 4.84375 8.9375H15.1562C15.3906 8.9375 15.625 9.17188 15.625 9.40625V11.5938C15.625 11.8672 15.3906 12.0625 15.1562 12.0625H4.84375Z"
                fill={GF_GREY}
            />
        </svg>
    );
}
