/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CardInfoWidget from "../../../CardInfoWidget";
import { useDispatch, useSelector } from "react-redux";
import { getShoppingCardBalance } from "../../../../actions/ShoppingCenterBalanceActions";
import PageTitle from "../../../PageTitle";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";
import FailAlert from "../../../Alert/FailAlert";
import { TAG_CHANGED } from "../../../../actions/TagActions";
import { TERMINAL_CHANGED } from "../../../../actions/TerminalActions";
import { useTranslation } from "react-i18next";
import Container from "../../../UIComponents/Container";
import { Userpilot } from "userpilot";

export default function ShoppingCenterBalance() {
    const dispatch = useDispatch();
    const { loadingShoppingCenterBalance, shoppingCenterBalance, errorShoppingCenterBalance } = useSelector((state) => state.shoppingCenterBalance);
    const { t } = useTranslation();

    useEffect(() => {
        Userpilot.reload();
        dispatch(getShoppingCardBalance());

        window.addEventListener(TAG_CHANGED, () => {
            dispatch(getShoppingCardBalance());
        });

        window.addEventListener(TERMINAL_CHANGED, () => {
            dispatch(getShoppingCardBalance());
        });

        return () => {
            window.removeEventListener(TAG_CHANGED, () => {});
            window.removeEventListener(TERMINAL_CHANGED, () => {});
        };
    }, []);

    return (
        <Container title={t("shopping-center-balance")} loading={loadingShoppingCenterBalance}>
            {shoppingCenterBalance && (
                <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
                    <CardInfoWidget bottomLabel={t("Original-balance")} value={shoppingCenterBalance.original_balance} isCurrency valueColor={"text-gfLightBlue"} labelColor={"text-gfLightBlue"} />
                    <CardInfoWidget bottomLabel={t("Total-card-loads")} value={shoppingCenterBalance.card_load_amount} isCurrency valueColor={"text-gfDarkBlue"} labelColor={"text-gfDarkBlue"} />
                    <CardInfoWidget bottomLabel={t("Balance-left")} value={shoppingCenterBalance.balance_left} isCurrency valueColor={"text-gfLightBlue"} labelColor={"text-gfLightBlue"} />
                </div>
            )}
            {errorShoppingCenterBalance && <FailAlert message={errorShoppingCenterBalance.message} />}
        </Container>
    );
}
