/* eslint-disable import/no-anonymous-default-export */
import { ERROR_TERMINAL, LOADING_TERMINALS, RECEIVED_TERMINALS, SELECTED_TERMINAL, SET_TERMINAL } from "../actions/TerminalActions";

const initialState = {
    loadingTerminals: false,
    terminals: null,
    errorTerminal: null,

    selectedTerminal: null,
    setTerminal: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_TERMINALS:
            return {
                ...state,
                loadingTerminals: true,
                terminals: null,
                selectedTerminal: null,
                errorTerminal: null,
            };

        case RECEIVED_TERMINALS:
            const newTerminals = action.payload.terminals;
            let selectedTerminal = null;
            const currentReferenceID = action.payload.reference;
            const localReferenceID = parseInt(localStorage.getItem("reference"));
            const terminalId = localStorage.getItem("tl-id");

            if (localReferenceID === null) {
                if (newTerminals.length !== 0) {
                    if (terminalId !== null) {
                        selectedTerminal = newTerminals.find((terminal) => parseInt(terminal.id) === parseInt(terminalId));
                    } else {
                        selectedTerminal = newTerminals[0];
                        localStorage.setItem("tl-id", selectedTerminal.id);
                    }
                } else {
                    localStorage.removeItem("tl-id");
                    selectedTerminal = null;
                }

                localStorage.setItem("reference", currentReferenceID);
            } else {
                if (localReferenceID === currentReferenceID) {
                    if (newTerminals.length !== 0) {
                        if (terminalId !== null) {
                            const checked = newTerminals.find((terminal) => parseInt(terminal.id) === parseInt(terminalId));
                            selectedTerminal = checked === undefined ? newTerminals[0] : checked;
                        } else {
                            selectedTerminal = newTerminals[0];
                            localStorage.setItem("tl-id", selectedTerminal.id);
                        }
                    }
                } else {
                    if (newTerminals.length !== 0) {
                        if (terminalId !== null) {
                            const checked = newTerminals.find((terminal) => parseInt(terminal.id) === parseInt(terminalId));
                            selectedTerminal = checked === undefined ? newTerminals[0] : checked;
                        } else {
                            selectedTerminal = newTerminals[0];
                            localStorage.setItem("tl-id", selectedTerminal.id);
                        }
                    }

                    localStorage.setItem("reference", currentReferenceID);
                }
            }

            return {
                ...state,
                loadingTerminals: false,
                terminals: newTerminals,
                selectedTerminal: selectedTerminal,
                errorTerminal: null,
            };

        case ERROR_TERMINAL:
            return {
                ...state,
                loadingTerminals: false,
                terminals: null,
                selectedTerminal: null,
                errorTerminal: action.payload,
            };

        case SELECTED_TERMINAL:
            localStorage.setItem("tl-id", action.payload.id);
            return {
                ...state,
                selectedTerminal: action.payload,
            };

        default:
            return state;
    }
}
